import * as Sentry from '@sentry/react';
import {Link, useRouteError} from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './components/Header/Header';
import {useEffect} from 'react';

export default function ErrorPage({goBackUrl}) {
  const error = useRouteError();

  console.log(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div id="error-page">
      {!goBackUrl && <Header />}
      <div className="container mt-4">
        <h1>Oops!</h1>
        <p>Ocurrió un error inesperado.</p>
        <p>{error && <i>{error.statusText || error.message}</i>}</p>
        {goBackUrl ? (
          <Link to={goBackUrl}>Volver al inicio</Link>
        ) : (
          <button className="btn btn-warning" onClick={() => history.back()}>
            Volver
          </button>
        )}
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  goBackUrl: PropTypes.string,
};
