import * as Sentry from '@sentry/react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './globals.scss';
import './thirdparty/dayjsExtendLocale.js';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from './error-page.jsx';
import Root from './routes/root.jsx';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import CandidateRoot from './routes/candidateRoot.jsx';
import LogoutPage from './pages/LogoutPage/LogoutPage.jsx';
import CandidatePage from './pages/CandidatePage/CandidatePage.jsx';
import LoginPage from './pages/LoginPage/LoginPage.jsx';
import IndexPage from './pages/IndexPage/IndexPage.jsx';
import CandidateQuestionnaireModulePage from './pages/CandidateQuestionnaireModulePage/CandidateQuestionnaireModulePage.jsx';
import {SWRConfig} from 'swr';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {esES} from '@mui/x-date-pickers/locales';
import localizationConst from './constants/localizationConst.js';
import routesConst from './constants/routesConst.js';
import AnalystRoot from './routes/analystRoot.jsx';
import AnalystHome from './pages/AnalystHome/AnalystHome.jsx';
import AnalystStudyPage from './pages/AnalystStudyPage/AnalystStudyPage.jsx';
import {getUserSession, signOut} from './helpers/userHelper';
import userConst from './constants/userConst';
import AdminRoot from './routes/adminRoot';
import AdminHome from './pages/AdminHome/AdminHome';
import AdminModifyCandidatePage from './pages/AdminModifyCandidatePage/AdminModifyCandidatePage';
import AdminNewCandidatePage from './pages/AdminNewCandidatePage/AdminNewCandidatePage';
import {redirectToLogin} from './helpers/navigationHelper';
import AdminLogbooks from './pages/AdminLogbooks/AdminLogbooks';
import AdminLogbook from './pages/AdminLogbook/AdminLogbook';

Sentry.init({
  dsn: 'https://b101384a8e37ddd0d40f15567559d97b@o4507349374795776.ingest.us.sentry.io/4507983498117120',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const userSessionLoader = async () => {
  try {
    const userSession = await getUserSession();
    return {
      userSession,
      ...(userSession?.idToken?.payload?.[userConst.role] && {
        userRole: userSession?.idToken?.payload?.[userConst.role],
      }),
    };
  } catch (error) {
    return {userSession: null};
  }
};

const logoutLoader = async () => {
  await signOut();
  return redirectToLogin();
};

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: userSessionLoader,
    id: 'root',
    children: [
      {index: true, element: <IndexPage />},
      {
        path: routesConst.login,
        element: <LoginPage />,
      },
      {
        path: routesConst.signOut,
        element: <LogoutPage />,
        loader: logoutLoader,
      },
      {
        path: routesConst.adminHome,
        element: <AdminRoot />,
        errorElement: <ErrorPage />,
        children: [
          {index: true, element: <AdminHome />},
          {
            path: `candidato/:candidateId`,
            element: <AdminModifyCandidatePage />,
          },
          {path: routesConst.adminNewCandidate, element: <AdminNewCandidatePage />},
          {path: `${routesConst.adminLogbooks}/:assessmentId`, element: <AdminLogbook />},
          {path: routesConst.adminLogbooks, element: <AdminLogbooks />},
        ],
      },
      {
        path: routesConst.analystHome,
        element: <AnalystRoot />,
        errorElement: <ErrorPage />,
        children: [
          {index: true, element: <AnalystHome />},
          {path: routesConst.analystAdmin, element: <AnalystStudyPage />},
        ],
      },
      {
        path: routesConst.candidateHome,
        element: <CandidateRoot />,
        errorElement: <ErrorPage />,
        children: [
          {index: true, element: <CandidatePage />},
          {
            path: ':questionnaireModuleId',
            element: <CandidateQuestionnaireModulePage />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <SWRConfig value={{provider: () => new Map()}}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localizationConst.es}
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <RouterProvider router={router} />
      </LocalizationProvider>
    </SWRConfig>
  </React.StrictMode>
);
