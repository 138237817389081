import useSWR from 'swr';
import fetcher from '../../../helpers/fetcher';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/assessments';

function useAssessmentModule({assessmentId, moduleId}) {
  const {data, error, isLoading} = useSWR(
    () => (assessmentId && moduleId ? `${API_ROUTE}/${assessmentId}/modules/${moduleId}` : null),
    fetcher
  );

  return {
    assessmentModule: data,
    isLoading,
    isError: error,
  };
}

export default useAssessmentModule;
