/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import useAnswers from '../../hooks/useAnswers';
import {useOutletContext, useParams} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';
import Field from '../Field/Field';
import useMedia from '../../hooks/useMedia';
import classNames from '../../helpers/classNames';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0';

export default function ListGroup({
  title,
  groupTitle,
  buttonLabel,
  fields,
  name,
  register,
  unregister,
  unregisterMultiple,
  formValues,
  dependsOn,
}) {
  const {user} = useOutletContext();
  const {answers} = useAnswers(user);
  const [groupSections, setGroupSections] = useState([]);
  const [hasInitialGroupsLoaded, setHasInitialGroupsLoaded] = useState(false);
  const [hasMediaCleaned, setHasMediaCleaned] = useState(false);
  let {questionnaireModuleId} = useParams();
  const {media} = useMedia(user.id, user.questionnaireId, questionnaireModuleId);

  useEffect(() => {
    if (!hasInitialGroupsLoaded) {
      if (answers && answers[name]) {
        const initialGroupSections = answers[name].map((answer) => {
          return {values: {...answer, id: answer.id ?? Math.floor(Math.random() * 0x7fffffff) + 1}};
        });

        setGroupSections(initialGroupSections);
      } else {
        handleAddGroupSection();
      }
      setHasInitialGroupsLoaded(true);
    }
  }, [answers, name, hasInitialGroupsLoaded]);

  const deleteMedia = async (mediaId) => {
    fetch(`${API_ROUTE}/documents/${mediaId}`, {
      method: 'DELETE',
    });
  };

  const removeUnsavedImages = () => {
    if (answers[name]) {
      const mediaToDelete = media.reduce((deletePile, mediaItem) => {
        const mediaItemGroupId = mediaItem.groupId;
        const mediaItemGroupName = mediaItem.name.split('--')[1];

        if (mediaItemGroupName !== name) {
          return deletePile;
        }

        const wasMediaSaved = answers[name].reduce((groupIdFound, answer) => {
          if (answer.id === mediaItemGroupId) {
            return true;
          }
          return groupIdFound;
        }, false);

        if (!wasMediaSaved) {
          return [...deletePile, mediaItem.id];
        }

        return deletePile;
      }, []);

      mediaToDelete.forEach((mediaId) => deleteMedia(mediaId));
    } else {
      media.forEach((mediaItem) => {
        const mediaItemGroupName = mediaItem.name.split('--')[1];

        if (mediaItemGroupName !== name) {
          return;
        }

        const mediaToDelete = media.map((media) => media.id);
        mediaToDelete.forEach((mediaId) => deleteMedia(mediaId));
      });
    }
  };

  useEffect(() => {
    if (!hasMediaCleaned && answers && media?.length && hasInitialGroupsLoaded) {
      removeUnsavedImages();
      setHasMediaCleaned(true);
    }
  }, [answers, media, hasInitialGroupsLoaded, hasMediaCleaned]);

  const handleAddGroupSection = () => {
    setGroupSections([
      ...groupSections,
      {values: {id: Math.floor(Math.random() * 0x7fffffff) + 1}},
    ]);
  };

  const handleDelete = (groupSectionId) => {
    const modifiedGroupSections = groupSections.filter((groupSection) => {
      return groupSection.values.id !== groupSectionId;
    });

    fields.forEach((field) => {
      unregister({...field, listGroupName: name, listGroupSectionId: groupSectionId});
    });

    setGroupSections(modifiedGroupSections);
  };

  const isDependencyMet = ({fieldName, value} = {}) => {
    if (formValues[fieldName] === null) {
      return false;
    }

    if (
      typeof formValues[fieldName] !== 'undefined' &&
      typeof formValues[fieldName] !== 'object' &&
      formValues[fieldName] != value
    ) {
      return false;
    }

    if (typeof formValues[fieldName] === 'object' && !formValues[fieldName].includes(value)) {
      return false;
    }

    return true;
  };

  const {isDisabledByDependency} =
    (dependsOn &&
      dependsOn.reduce((dependencies, fieldDependency) => {
        if (fieldDependency.status === 'disabled') {
          if (!isDependencyMet({fieldName: fieldDependency.field, value: fieldDependency.value})) {
            return {...dependencies, isDisabledByDependency: true};
          } else {
            return {...dependencies, isDisabledByDependency: false};
          }
        }

        return dependencies;
      }, {})) ||
    {};

  useEffect(() => {
    if (isDisabledByDependency && !formValues[name]?.length && groupSections.length) {
      setGroupSections([]);
    }

    if (isDisabledByDependency && formValues[name]?.length) {
      const fieldsToRemove = groupSections.reduce((removePile, section) => {
        return [
          ...removePile,
          ...fields.map((field) => ({
            ...field,
            listGroupName: name,
            listGroupSectionId: section.values.id,
          })),
        ];
      }, []);

      unregisterMultiple(fieldsToRemove);
      setGroupSections([]);
    }

    if (!isDisabledByDependency && !formValues[name]?.length && hasInitialGroupsLoaded) {
      const mediaGroups = media
        ?.filter((mediaItem) => {
          const mediaItemGroupName = mediaItem.name.split('--')[1];

          return mediaItemGroupName === name;
        })
        .map((mediaItem) => ({values: {id: mediaItem.groupId}}));

      setGroupSections(
        mediaGroups?.length
          ? mediaGroups
          : [{values: {id: Math.floor(Math.random() * 0x7fffffff) + 1}}]
      );
    }
  }, [isDisabledByDependency, formValues[name]?.length]);

  return (
    <div className="row d-flex justify-content-center modal-group">
      {hasInitialGroupsLoaded && !isDisabledByDependency && (
        <div {...classNames('mb-1', isDisabledByDependency && 'd-none')}>
          {groupTitle && <h2 className="group-title">{groupTitle}</h2>}
          {groupSections.map((groupSection, groupSectionIndex) => (
            <Accordion
              key={`sectionRow-${groupSection.values.id}`}
              className="mb-3"
              defaultActiveKey={[`sectionRow-${groupSection.values.id}`]}
              alwaysOpen
            >
              <Accordion.Item eventKey={`sectionRow-${groupSection.values.id}`}>
                <Accordion.Header>
                  <h4 className="mb-0 w-100">
                    {title} {groupSectionIndex + 1}
                  </h4>
                  <i className="me-3" onClick={() => handleDelete(groupSection.values.id)}>
                    <i className="bi bi-trash3"></i>
                  </i>
                </Accordion.Header>
                <Accordion.Body className="row">
                  {fields.map((field, fieldIndex) => (
                    <Field
                      field={field}
                      defaultValue={
                        field.name === 'id'
                          ? groupSection.values.id
                          : field.value ?? groupSection.values?.[field.name]
                      }
                      listGroupName={name}
                      listGroupSectionId={groupSection.values.id}
                      register={register}
                      groupIndex={groupSection.values.id}
                      groupSectionIndex={groupSectionIndex}
                      nextField={fields[fieldIndex + 1] || null}
                      fieldIndex={fieldIndex}
                      formValues={formValues}
                      isGroupDisabled={
                        typeof isDisabledByDependency !== 'undefined' && isDisabledByDependency
                      }
                      key={`field-sectionIndex-${groupSectionIndex}-groupId-${groupSection.values.id}-${fieldIndex}`}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      )}
      <button
        className="btn btn-warning mb-4 add-list-element rounded-pill"
        dangerouslySetInnerHTML={{__html: buttonLabel}}
        type="button"
        onClick={handleAddGroupSection}
        disabled={isDisabledByDependency}
      />
    </div>
  );
}

ListGroup.propTypes = {
  title: PropTypes.string,
  groupTitle: PropTypes.string,
  fields: PropTypes.array,
  buttonLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  unregister: PropTypes.func,
  unregisterMultiple: PropTypes.func,
  formValues: PropTypes.object,
  dependsOn: PropTypes.array,
};
