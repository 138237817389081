import useLogbooks from '@/hooks/useLogbooks';
import LogbookReview from '../../components/LogbookReview/LogbookReview';
import {useWindowScroll} from '@uidotdev/usehooks';
import {useEffect, useState} from 'react';
import './AdminLogbooks.scss';

function AdminLogbooks() {
  const {data, isLoading, size, setSize, isValidating, mutate} = useLogbooks();
  const [{y: yPos}] = useWindowScroll();
  const [fetchNext, setFetchNext] = useState(false);

  const isLoadingMore = isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isRefreshing = isValidating && data && data.length === size;

  useEffect(() => {
    if (
      yPos + window.innerHeight >= document.body.offsetHeight - 2000 &&
      yPos + window.innerHeight > 1500
    ) {
      setFetchNext(true);
    }
  }, [yPos]);

  useEffect(() => {
    if (
      fetchNext &&
      size * 2 <= data?.[0].totalCount &&
      !isLoadingMore &&
      data?.length &&
      !isRefreshing
    ) {
      setSize(size + 1);
      setFetchNext(false);
    }
  }, [fetchNext, size, data, isLoadingMore, isRefreshing, setSize]);

  const mutateLogbook = (index) => {
    mutate(data, {
      revalidate: (pageData, [, , page]) => page === index,
    });
  };

  return (
    <main className="container mb-5">
      <div className="dashboard admin-logbooks">
        <div className="row">
          <div className="col-12">
            <div className="name mb-3">Cuadros de Avance</div>
            {data?.map((logbooksData, logbooksDataIndex) =>
              logbooksData.logbooks?.map?.((logbook, logbookIndex) => (
                <LogbookReview
                  key={`logbooksData.${logbooksDataIndex}.${logbookIndex}`}
                  logbook={logbook}
                  mutate={() => mutateLogbook(logbooksDataIndex)}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdminLogbooks;
