export default {
  adminHome: '/sistema/administrador',
  adminLogbooks: '/sistema/administrador/avances',
  adminNewCandidate: '/sistema/administrador/nuevo-candidato',
  applicantAdjustments: '/sistema/candidato/ajuste',
  candidateHome: '/sistema/candidato',
  analystAdmin: '/sistema/analista/estudio',
  analystHome: '/sistema/analista',
  login: '/sistema',
  signOut: '/signout',
};
