import CandidateForm from '@/components/CandidateForm/CandidateForm';
import '../../components/Dashboard/Dashboard.scss';
import './AdminModifyCandidatePage.scss';
import {useParams} from 'react-router-dom';

export default function AdminModifyCandidatePage() {
  let {candidateId} = useParams();

  return (
    <main className="container admin-modify-candidate-page">
      <div className="dashboard candidates-page">
        <div className="name">Actualizar Candidato</div>
        <div className="subtitle">Código No. {candidateId}</div>
        <div>
          Campos con <span className="text-danger">*</span> son requeridos.
        </div>
        <CandidateForm candidateId={candidateId} />
      </div>
    </main>
  );
}
