import {useParams} from 'react-router-dom';
import LogbookReview from '../../components/LogbookReview/LogbookReview';
import useLogbook from '@/hooks/useLogbook';
import './AdminLogbook.scss';

function AdminLogbook() {
  const {assessmentId} = useParams();
  const {logbook, mutate} = useLogbook({assessmentId});

  return (
    <main className="container">
      <div className="dashboard admin-logbook">
        <div className="row">
          <div className="col-12">
            <div className="name mb-3">Cuadro de Avance</div>
            <LogbookReview logbook={logbook} mutate={mutate} />
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdminLogbook;
