import Header from '../../components/Header/Header';
import './IndexPage.scss';

export default function IndexPage() {
  return (
    <>
      <Header />
      <main className="index-main">
        <section className="index-summary">
          <h1>Socioeconomics & Background Check</h1>
          <p>
            Sabemos que el Proceso de Reclutamiento y Selección de su Personal es una de las
            actividades de mayor importancia en su Organización, ya que, a través de su correcta
            aplicación, se identifican e incorporan a los mejores candidatos que fortalecerán con
            éxito su proyecto empresarial
          </p>
        </section>
        <section className="index-main-section--row">
          <article className="index-main-article--two_columns">
            <h2>¿Qué hacemos?</h2>
            <p>
              Nuestro Estudio Socioeconomics & Background Check le ofrece entre otros servicios, una
              magnífica herramienta para la identificación y análisis de sus candidatos mediante la
              aplicación de lo que llamamos Investigación de Antecedentes Personales, Familiares,
              Vecinales, Educativos, Domiciliares, Laborales, Psicológicos, Penales y de Salud
            </p>
            <p>
              Las investigaciones validadas por Ethics Data Analytics, se realizan tanto a los
              trabajadores de nuevo ingreso, como a las personas que ya se encuentran laborando,
              (para la actualización de datos) mediante cuestionarios que están dirigidos a
              investigar los antecedentes del personal que usted requiera
            </p>
          </article>
          <article className="index-main-article--two-columns">
            <h2>Contáctanos</h2>
            <p>Telefono del contacto</p>
            <p>Correo para el contacto</p>
          </article>
        </section>
        <section>
          <h1>Objetivo</h1>
          <p>
            Desarrollar e implementar Socioeconomics & Background Check profundos, previniendo a las
            empresas de situaciones no deseadas con candidatos sujetos a contratación y/o
            actualización de datos del personal actual
          </p>
        </section>
        <section>
          <h1>Aspectos que se Evalúan</h1>
          <section className="index-main-section--row">
            <article className="index-main-article-three-columns">
              <ul>
                <li>Información Personal</li>
                <li>Señas Particulares</li>
                <li>Comprobación de Identidad</li>
                <li>Domicilio Particular</li>
              </ul>
            </article>
            <article className="index-main-article-three-columns">
              <ul>
                <li>Salud</li>
                <li>Información Laboral</li>
                <li>Estructura Familiar</li>
                <li>Hábitos Personales</li>
              </ul>
            </article>
            <article className="index-main-article-three-columns">
              <ul>
                <li>Antecedentes Legales</li>
                <li>Información Económica</li>
                <li>Información Académica</li>
                <li>Referencias Vecinales</li>
                <li>Referencias Personales</li>
              </ul>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}
