const patterns = {
  digits: /^\d+$/,
  letters: /^[a-zA-ZáÁéÉíÍóÓúÚüÜ]*$/,
  currency: /^\$?(0|[1-9][0-9]*)(,\d{3})*(\.\d{1,9})?$/,
};

export default {
  minLength: (constraint, value) => (value ? value?.toString().length >= constraint : true),
  maxLength: (constraint, value) => (value ? value?.toString().length <= constraint : true),
  min: (constraint, value) => value >= constraint,
  max: (constraint, value) => value <= constraint,
  pattern: (constraint, value) => (value?.length ? patterns[constraint].test(value) : true),
};
