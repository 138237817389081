import useSWRMutation from 'swr/mutation';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0';

async function sendImageUploadRequest(url) {
  return fetch(url, {
    method: 'DELETE',
  });
}

function useMediaDelete(mediaId) {
  const {trigger, error, isMutating} = useSWRMutation(
    `${API_ROUTE}/documents/${mediaId}`,
    sendImageUploadRequest
  );

  return {
    trigger,
    isMutating,
    isError: error,
  };
}

export default useMediaDelete;
