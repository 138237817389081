import {Link, useOutletContext} from 'react-router-dom';
import useQuestionnaire from '../../hooks/useQuestionnaire';
import classNames from '../../helpers/classNames';
import studiesConst from '../../constants/studiesConst';
import './Questionnaire.scss';

export default function Questionnaire() {
  const {user} = useOutletContext();
  const {questionnaire, isLoading} = useQuestionnaire(user);

  const isModuleCompleted = (moduleStatus) => {
    return (
      moduleStatus === studiesConst.moduleStatus.inReview ||
      moduleStatus === studiesConst.moduleStatus.completed ||
      moduleStatus === studiesConst.moduleStatus.accepted ||
      moduleStatus === studiesConst.moduleStatus.partiallyAccepted ||
      moduleStatus === studiesConst.moduleStatus.rejected
    );
  };

  const moduleStatusColor = (moduleStatus) => {
    switch (moduleStatus) {
      case studiesConst.moduleStatus.inProgress:
        return 'gray';
      case studiesConst.moduleStatus.inReview:
      case studiesConst.moduleStatus.completed:
        return 'blue';
      case studiesConst.moduleStatus.accepted:
      case studiesConst.moduleStatus.partiallyAccepted:
      case studiesConst.moduleStatus.rejected:
        return 'mint';
      default:
        return 'white';
    }
  };

  const completedModuleText = (moduleStatus) => {
    switch (moduleStatus) {
      case studiesConst.moduleStatus.inReview:
      case studiesConst.moduleStatus.completed:
        return 'Completo';
      case studiesConst.moduleStatus.accepted:
      case studiesConst.moduleStatus.partiallyAccepted:
      case studiesConst.moduleStatus.rejected:
        return 'Revisado';
      default:
        return '';
    }
  };

  return (
    <div className="d-grid questionnaire-gap questionnaire">
      {questionnaire &&
        !isLoading &&
        questionnaire.map((questionnaireModule) => (
          <div key={questionnaireModule.id} className="questionnaire-module-pill">
            <Link
              to={`${
                isModuleCompleted(questionnaireModule.moduleStatus) ? '' : questionnaireModule.id
              }`}
              {...classNames(
                'module-select d-flex align-items-center rounded-pill',
                isModuleCompleted(questionnaireModule.moduleStatus) && 'flip'
              )}
            >
              <div className="d-flex fs-1 module-icon-outer">
                <div
                  {...classNames(
                    'd-flex module-icon-inner',
                    moduleStatusColor(questionnaireModule.moduleStatus)
                  )}
                  dangerouslySetInnerHTML={{
                    __html: questionnaireModule?.header || '<i class="bi bi-card-checklist"></i>',
                  }}
                ></div>
              </div>
              {isModuleCompleted(questionnaireModule.moduleStatus) ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <div className="hidden-completed text-center">
                    {completedModuleText(questionnaireModule.moduleStatus)}
                  </div>
                  <div className="d-flex justify-content-center w-100 text-center module-name">
                    {questionnaireModule.name}
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center w-100 text-center mx-3">
                  {questionnaireModule.name}
                </div>
              )}
            </Link>
          </div>
        ))}
    </div>
  );
}
