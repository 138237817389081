import {useState} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';
import PropTypes from 'prop-types';

function Saving({isMutating, isSaveError, errorMessage, savingMessage, successMessage}) {
  const [prevIsMutating, setPrevIsMutating] = useState(() => isMutating);

  const [isSavingToastShowing, setIsSavingToastShowing] = useState(false);
  const [isSaveSuccessToastShowing, setIsSaveSuccessToastShowing] = useState(false);

  if (prevIsMutating !== isMutating) {
    setPrevIsMutating(isMutating);

    if (isMutating) {
      setIsSavingToastShowing(true);
    }

    if (!isMutating && !isSaveError) {
      setIsSavingToastShowing(false);
      setIsSaveSuccessToastShowing(true);
    }
  }
  const handleAfterSave = () => {
    setIsSavingToastShowing(false);
    if (!isSaveError) {
      setIsSaveSuccessToastShowing(true);
    }
  };

  const handleAfterSaveSuccess = () => {
    setIsSaveSuccessToastShowing(false);
  };

  return (
    <>
      <ToastContainer className="m-4" position="top-center">
        <Toast
          className={`text-bg-${isSaveError ? 'danger' : 'warning'}`}
          show={isSavingToastShowing}
          onClose={handleAfterSave}
          autohide
          delay={5000}
        >
          <Toast.Body>
            {!isSaveError && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {isSaveError ? errorMessage : ` ${savingMessage}`}
          </Toast.Body>
        </Toast>
        <Toast
          className={`text-bg-success`}
          show={isSaveSuccessToastShowing}
          onClose={handleAfterSaveSuccess}
          autohide
          delay={5000}
        >
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      {isSavingToastShowing && !isSaveError && <div className="modal-backdrop show"></div>}
    </>
  );
}

Saving.propTypes = {
  isMutating: PropTypes.bool,
  isSaveError: PropTypes.bool,
  errorMessage: PropTypes.string,
  savingMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

export default Saving;
