/* eslint-disable react-hooks/exhaustive-deps */
import useQuestionnaire from '../../hooks/useQuestionnaire';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import {Toast, ToastContainer} from 'react-bootstrap';
import {useOutletContext} from 'react-router-dom';
import '../../components/Dashboard/Dashboard.scss';
import './CandidatePage.scss';

export default function CandidatePage() {
  const {hasModuleSaved, setHasModuleSaved, user} = useOutletContext();
  const {
    questionnaire,
    message,
    isLoading: isQuestionnaireLoading,
    isError: isQuestionnaireError,
  } = useQuestionnaire(user);

  const handleToastClose = () => {
    setHasModuleSaved(false);
  };

  return (
    <main className="container">
      <div className="dashboard candidate-page">
        {!isQuestionnaireLoading && !isQuestionnaireError && questionnaire && (
          <>
            <Toast className="alert mb-3 message-alert d-flex" show>
              <Toast.Body>
                <div dangerouslySetInnerHTML={{__html: message}} />
              </Toast.Body>
            </Toast>
            <div className="legend d-flex align-items-center pb-3 flex-wrap">
              <div className="d-flex legend-pill">
                <span className="box white rounded-pill" />
                <span className="fs-6 ps-2">Sin Responder</span>
              </div>
              <div className="d-flex legend-pill">
                <span className="box gray rounded-pill" />
                <span className="fs-6 ps-2">En Proceso</span>
              </div>
              <div className="d-flex legend-pill">
                <span className="box blue rounded-pill" />
                <span className="fs-6 ps-2">Completo</span>
              </div>
              <div className="d-flex legend-pill">
                <span className="box mint rounded-pill" />
                <span className="fs-6 ps-2">Revisado</span>
              </div>
            </div>
            <div className="dashboard-reduced-size">
              {user && <div className="name">{user.name}</div>}
              <Questionnaire />
              <ToastContainer className="module-save m-4" position="top-center">
                <Toast
                  className="text-bg-success d-flex"
                  show={hasModuleSaved}
                  onClose={handleToastClose}
                  delay={3000}
                  autohide
                >
                  <Toast.Body>El modulo se ha guardado con éxito.</Toast.Body>
                  <button
                    type="button"
                    className="btn-close btn-close-white me-2 m-auto"
                    onClick={handleToastClose}
                    aria-label="Close"
                  ></button>
                </Toast>
              </ToastContainer>
            </div>
          </>
        )}
      </div>
    </main>
  );
}
