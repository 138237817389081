/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';
import AttachmentInput from '../AttachmentInput/AttachmentInput';
import './Field.scss';
import useDynamicFormField from '../../hooks/useDynamicFormField';
import RadioInput from '../RadioInput/RadioInput';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import daysjs from 'dayjs';
import classNames from '../../helpers/classNames';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import SelectInput from '../SelectInput/SelectInput';
import Checkbox from '../Checkbox/Checkbox';
import CalculatedField from '../CalculatedField/CalculatedField';

export default function Field({
  field,
  isStudy,
  isReadOnly,
  customSize,
  register,
  groupIndex,
  groupSectionIndex,
  nextField,
  fieldIndex,
  defaultValue,
  className,
  formatOptions,
  listGroupName,
  listGroupSectionId,
  formValues,
  isGroupDisabled,
}) {
  const {
    name,
    label,
    type,
    size,
    format,
    items,
    messages,
    buttonLabel,
    defaultValue: fieldDefaultValue,
    required,
    placeholder,
    contentTypes,
    hasPreview,
    previewLabel,
    requiredMessage,
    validations,
    dependsOn,
    fetchItems,
    isAnalysisField,
    params,
  } = field;

  const preferredDefaultValue =
    typeof fieldDefaultValue !== 'undefined'
      ? fieldDefaultValue
      : typeof defaultValue !== 'undefined'
      ? defaultValue
      : null;

  const [isDirty, setIsDirty] = useState(false);
  const fieldRef = useRef(null);

  const {captureChange, isRequiredValid, validate, isValid, validationMessages, value} =
    useDynamicFormField({
      name,
      setIsDirty,
      isRequired: required,
      register,
      defaultValue: preferredDefaultValue,
      format,
      type,
      validations,
      fieldRef,
      formatOptions,
      listGroupName,
      listGroupSectionId,
    });

  const handleOnChange = useCallback((fieldValue) => {
    setIsDirty(true);
    validate(fieldValue);
    captureChange(fieldValue);
  }, []);

  const isInvalidShowing = isDirty && !isRequiredValid;

  const isDependencyMet = ({fieldName, value} = {}) => {
    const isGroupField = typeof groupSectionIndex === 'number';
    const groupFieldValue = formValues[listGroupName]?.[groupSectionIndex]?.[fieldName];

    if (isGroupField && typeof groupFieldValue !== 'undefined' && groupFieldValue != value) {
      return false;
    }

    if (!isGroupField && formValues[fieldName] === null) {
      return false;
    }

    if (
      !isGroupField &&
      typeof formValues[fieldName] !== 'undefined' &&
      typeof formValues[fieldName] !== 'object' &&
      formValues[fieldName] != value
    ) {
      return false;
    }

    if (typeof formValues[fieldName] === 'object' && !formValues[fieldName].includes(value)) {
      return false;
    }

    return true;
  };

  const {isHiddenByDependency, isDisabledByDependency} =
    (dependsOn &&
      dependsOn.reduce((dependencies, fieldDependency) => {
        if (
          fieldDependency.status === 'hidden' &&
          !isDependencyMet({fieldName: fieldDependency.field, value: fieldDependency.value})
        ) {
          return {...dependencies, isHiddenByDependency: true};
        }

        if (
          fieldDependency.status === 'disabled' &&
          !isDependencyMet({fieldName: fieldDependency.field, value: fieldDependency.value})
        ) {
          return {...dependencies, isDisabledByDependency: true};
        }

        return dependencies;
      }, {})) ||
    (isGroupDisabled && {isHiddenByDependency: true, isDisabledByDependency: true}) ||
    {};

  useEffect(() => {
    if (isHiddenByDependency || isDisabledByDependency || isGroupDisabled) {
      if (fieldRef?.current) {
        fieldRef.current.value = null;
        handleOnChange(null);
      }
    }
  }, [isHiddenByDependency, isDisabledByDependency, isGroupDisabled]);

  const getName = () =>
    `${name}${listGroupName ? '--' : ''}${listGroupName ? listGroupName : ''}${
      listGroupSectionId ? '--' : ''
    }${listGroupSectionId ? listGroupSectionId : ''}`;

  const buildField = () => {
    switch (type) {
      case 'array':
        switch (format) {
          case 'checkbox':
            return (
              <>
                <div
                  {...classNames('d-flex flex-column', isDirty && !isRequiredValid && 'is-invalid')}
                >
                  <div
                    {...classNames(
                      'form-label',
                      isDirty && !isRequiredValid && 'is-invalid',
                      isDisabledByDependency && 'disabled',
                      isStudy && !isAnalysisField && 'mb-4',
                      isStudy && isAnalysisField && 'my-0 ms-0'
                    )}
                  >
                    {label}
                  </div>
                  {!isAnalysisField && (
                    <Checkbox
                      {...classNames(!isStudy && 'row', isStudy && 'form-check')}
                      name={getName()}
                      defaultValue={preferredDefaultValue}
                      items={items}
                      handleOnChange={handleOnChange}
                      isDisabledByDependency={isDisabledByDependency}
                      isStudy={isStudy}
                      isReadOnly={isReadOnly}
                    />
                  )}
                  {isAnalysisField && (
                    <CustomCheckbox
                      name={getName()}
                      defaultValue={preferredDefaultValue}
                      items={items}
                      onChange={(value) => handleOnChange(Number(value))}
                    />
                  )}
                </div>
                {isDirty && !isRequiredValid && (
                  <p className="invalid-feedback">* {requiredMessage}</p>
                )}
              </>
            );
          case 'radio':
            return (
              <>
                <div
                  {...classNames(
                    'd-flex',
                    (!isStudy || isAnalysisField) && 'align-items-center',
                    !isStudy && !isAnalysisField && 'justify-content-between',
                    isStudy && 'flex-column',
                    isAnalysisField && 'study-field-radio',
                    isDirty && !isRequiredValid && 'is-invalid',
                    isDisabledByDependency && 'radio-disabled'
                  )}
                >
                  <div
                    {...classNames(
                      'form-label',
                      !isAnalysisField && 'my-2',
                      isDirty && !isRequiredValid && 'is-invalid',
                      isStudy && !isAnalysisField && 'mb-4',
                      isStudy && isAnalysisField && 'my-0 ms-0'
                    )}
                  >
                    {label}
                  </div>
                  <div {...classNames('d-flex', isAnalysisField && 'w-100 mt-4 pt-1 px-3')}>
                    {items.map((option, optionIndex) => (
                      <RadioInput
                        name={getName()}
                        option={option}
                        key={`${getName()}-${option.id}`}
                        defaultChecked={
                          preferredDefaultValue !== null
                            ? Number(preferredDefaultValue) === Number(option.id)
                            : null
                        }
                        onChange={handleOnChange}
                        isValid={isRequiredValid}
                        isDirty={isDirty}
                        className={isDirty && !isRequiredValid ? 'is-invalid' : ''}
                        optionIndex={optionIndex}
                        disabled={Boolean(isReadOnly) || isDisabledByDependency}
                        isDisabledByDependency={isDisabledByDependency}
                        isHiddenByDependency={isHiddenByDependency}
                      />
                    ))}
                  </div>
                </div>
                {isDirty && !isRequiredValid && (
                  <p className="invalid-feedback">* {requiredMessage}</p>
                )}
              </>
            );
          case 'select':
            return (
              <SelectInput
                label={label}
                getName={getName}
                isDirty={isDirty}
                isRequiredValid={isRequiredValid}
                value={value}
                preferredDefaultValue={preferredDefaultValue}
                isReadOnly={isReadOnly}
                handleOnChange={handleOnChange}
                items={items}
                requiredMessage={requiredMessage}
                fetchItems={fetchItems}
                isDisabledByDependency={isDisabledByDependency}
                formValues={formValues}
                listGroupName={listGroupName}
                groupSectionIndex={groupSectionIndex}
                isStudy={isStudy}
                isAnalysisField={isAnalysisField}
                key={`select-input-${
                  Boolean(groupSectionIndex) && groupSectionIndex
                }-field-index-${fieldIndex}`}
              />
            );
        }
        break;
      case 'calculated':
        return (
          <CalculatedField
            isStudy={isStudy}
            format={format}
            params={params}
            label={label}
            formValues={formValues}
            savedValue={preferredDefaultValue}
            listGroupName={listGroupName}
            groupSectionIndex={groupSectionIndex}
            isListGroup={typeof groupSectionIndex === 'number'}
            isReadOnly={isReadOnly}
            isDisabledByDependency={isDisabledByDependency}
            handleOnChange={handleOnChange}
          />
        );
      case 'date':
        return (
          <>
            <label
              {...classNames(
                'form-label',
                isDisabledByDependency && 'disabled',
                isStudy && !isAnalysisField && 'mb-4',
                isStudy && isAnalysisField && 'my-0 ms-0'
              )}
            >
              {label}
            </label>
            <DatePicker
              id={getName()}
              defaultValue={daysjs(preferredDefaultValue)}
              onChange={(value) => handleOnChange(daysjs(value).format('YYYY-MM-DD'))}
              className="date-field"
              disabled={Boolean(isReadOnly) || isDisabledByDependency}
            />
            {isDirty && !isRequiredValid && (
              <span className="invalid-feedback">* {requiredMessage}</span>
            )}
            {validationMessages?.map((message, index) => (
              <span className="invalid-feedback" key={`invalid${index}`}>
                * {message}
              </span>
            ))}
          </>
        );
      case 'file':
        return (
          <AttachmentInput
            name={getName()}
            buttonLabel={buttonLabel}
            label={label}
            messages={messages}
            contentTypes={contentTypes}
            hasPreview={hasPreview}
            previewLabel={previewLabel}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            isRequiredValid={isRequiredValid}
            requiredMessage={requiredMessage}
            captureChange={captureChange}
            groupIndex={groupIndex}
            validate={validate}
            isReadOnly={isReadOnly || isDisabledByDependency}
            listGroupSectionId={listGroupSectionId}
            value={value}
          />
        );
      case 'integer':
        return (
          <>
            <label
              htmlFor={getName()}
              {...classNames(
                'form-label',
                isDisabledByDependency && 'disabled',
                isStudy && !isAnalysisField && 'mb-4',
                isStudy && isAnalysisField && 'my-0 ms-0'
              )}
            >
              {label}
            </label>
            <input
              type="number"
              id={getName()}
              name={getName()}
              placeholder={placeholder}
              {...classNames(
                'form-control',
                isDirty && (!isRequiredValid || !isValid) && 'is-invalid'
              )}
              ref={fieldRef}
              onChange={() => handleOnChange(Number(fieldRef.current.value))}
              defaultValue={preferredDefaultValue}
              disabled={Boolean(isReadOnly) || isDisabledByDependency}
            ></input>
            {isDirty && !isRequiredValid && (
              <span className="invalid-feedback">* {requiredMessage}</span>
            )}
            {validationMessages?.map((message, index) => (
              <span className="invalid-feedback" key={`invalid${index}`}>
                * {message}
              </span>
            ))}
          </>
        );
      case 'textarea':
        return (
          <>
            <label
              htmlFor={getName()}
              {...classNames(
                'form-label',
                isDisabledByDependency && 'disabled',
                isStudy && !isAnalysisField && 'mb-4',
                isStudy && isAnalysisField && 'my-0 ms-0'
              )}
            >
              {label}
            </label>
            <textarea
              id={getName()}
              name={getName()}
              placeholder={placeholder}
              className={`form-control ${
                isDirty && (!isRequiredValid || !isValid) ? 'is-invalid' : ''
              }`}
              ref={fieldRef}
              onChange={() => handleOnChange(fieldRef.current.value)}
              defaultValue={preferredDefaultValue}
              rows="4"
              disabled={Boolean(isReadOnly) || isDisabledByDependency}
            ></textarea>
            {isDirty && !isRequiredValid && (
              <span className="invalid-feedback">* {requiredMessage}</span>
            )}
            {validationMessages?.map((message, index) => (
              <span className="invalid-feedback" key={`invalid${index}`}>
                * {message}
              </span>
            ))}
          </>
        );
      case 'string':
        return (
          <>
            <label
              htmlFor={getName()}
              {...classNames(
                'form-label',
                isDisabledByDependency && 'disabled',
                isStudy && !isAnalysisField && 'mb-4',
                isStudy && isAnalysisField && 'my-0 ms-0'
              )}
            >
              {label}
            </label>
            <input
              type="text"
              id={getName()}
              name={getName()}
              placeholder={placeholder}
              {...classNames(
                'form-control',
                isDirty && (!isRequiredValid || !isValid) && 'is-invalid'
              )}
              ref={fieldRef}
              onChange={() => handleOnChange(fieldRef.current.value)}
              defaultValue={preferredDefaultValue}
              disabled={Boolean(isReadOnly) || isDisabledByDependency}
            ></input>
            {isDirty && !isRequiredValid && (
              <span className="invalid-feedback">* {requiredMessage}</span>
            )}
            {validationMessages?.map((message, index) => (
              <span className="invalid-feedback" key={`invalid${index}`}>
                * {message}
              </span>
            ))}
          </>
        );
    }
  };

  return (
    <div
      {...classNames(
        `field col-${isStudy ? `md-${customSize}` : `md-${size}` ?? 12} col-sm-12`,
        !isStudy && format === 'radio' && nextField?.format === 'radio' && 'radio-squash',
        !isStudy && format === 'radio' && fieldIndex % 2
          ? 'radio-even'
          : format === 'radio' && 'radio-odd',
        format === 'radio' && 'radio-field',
        !isStudy && format === 'radio' && 'questionnaire-radio-width',
        isInvalidShowing && 'is-invalid',
        isStudy && 'study-field',
        format === 'radio' && 'questionnaire-field',
        className,
        isHiddenByDependency && 'd-none',
        isAnalysisField && 'study-only'
      )}
    >
      {buildField()}
    </div>
  );
}

Field.propTypes = {
  field: PropTypes.object,
  formatOptions: PropTypes.object,
  isStudy: PropTypes.bool,
  fieldIndex: PropTypes.number,
  register: PropTypes.func,
  groupIndex: PropTypes.number,
  groupSectionIndex: PropTypes.number,
  nextField: PropTypes.object,
  customSize: PropTypes.number,
  isReadOnly: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  listGroupName: PropTypes.string,
  listGroupSectionId: PropTypes.number,
  formValues: PropTypes.object,
  isGroupDisabled: PropTypes.bool,
};
