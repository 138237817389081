import PropTypes from 'prop-types';
import './StudyModule.scss';
import useQuestionnaireModule from '../../../hooks/useQuestionnaireModule';
import StudyGroup from '../../../components/StudyGroup/StudyGroup';
import StudyListGroup from '../../../components/StudyListGroup/StudyListGroup';
import {formatFormValues, isAnalystSelectionIncomplete} from '../../../helpers/formHelper';
import useSaveAssessmentModule from '../useSaveAssessmentModule/useSaveAssessmentModule';
import {Toast, ToastContainer} from 'react-bootstrap';
import {Fragment, useEffect, useState} from 'react';
import studiesConst from '../../../constants/studiesConst';
import LoadingQuestionnaireModule from '../../../components/LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import useAssessmentModule from '../useAssessmentModule/useAssessmentModule';
import AssessmentStatusSelect from '@/components/AssessmentStatusSelect/AssessmentStatusSelect';

export default function StudyModule({
  id,
  assessmentId,
  questionnaireId,
  register,
  formValues,
  mutateAssessments,
}) {
  const {questionnaireModule, isLoading, isError} = useQuestionnaireModule(
    questionnaireId,
    id,
    assessmentId
  );
  const {assessmentModule, isLoading: isAssessmentLoading} = useAssessmentModule({
    assessmentId,
    moduleId: id,
  });

  const {
    trigger,
    isMutating,
    isError: isSaveError,
  } = useSaveAssessmentModule({assessmentId, moduleId: id});
  const [prevIsMutating, setPrevIsMutating] = useState(isMutating);
  const [isSavingToastShowing, setIsSavingToastShowing] = useState(false);
  const [isSaveSuccessToastShowing, setIsSaveSuccessToastShowing] = useState(false);
  const areAssessmentFinalFieldsHidden = isAnalystSelectionIncomplete(formValues);
  const [moduleStatus, setModuleStatus] = useState(0);
  const [moduleObservations, setModuleObservations] = useState('');
  const serverAssessmentModuleStatus = assessmentModule?.moduleStatus;
  const serverModuleObservations = assessmentModule?.moduleObservations;

  useEffect(() => {
    if (serverAssessmentModuleStatus) {
      setModuleStatus(serverAssessmentModuleStatus);
    }
  }, [serverAssessmentModuleStatus]);

  useEffect(() => {
    if (serverModuleObservations) {
      setModuleObservations(serverModuleObservations);
    }
  }, [serverModuleObservations]);

  if (prevIsMutating !== isMutating) {
    setPrevIsMutating(isMutating);

    if (isMutating) {
      setIsSavingToastShowing(true);
    }

    if (!isMutating && !isSaveError) {
      setIsSavingToastShowing(false);
      setIsSaveSuccessToastShowing(true);
    }
  }

  const handleAfterSave = () => {
    setIsSavingToastShowing(false);
    if (!isSaveError) {
      setIsSaveSuccessToastShowing(true);
    }
  };

  const handleAfterSaveSuccess = () => {
    setIsSaveSuccessToastShowing(false);
  };

  const handleSave = () => {
    trigger(
      JSON.stringify({
        ...formatFormValues(formValues),
        ...(moduleStatus && {moduleStatus}),
        ...(moduleObservations && {moduleObservations}),
      })
    ).then(() => mutateAssessments());
  };

  const handleReject = () => {
    const analysisValues = {
      ...formatFormValues(formValues),
      moduleStatus: studiesConst.moduleStatus.inProgress,
      moduleObservations: null,
    };
    trigger(JSON.stringify(analysisValues)).then(() => mutateAssessments());
  };

  return (
    <div className="study-module mt-4 pt-2">
      <form>
        <div className="module-inputs-container">
          <div className="container">
            <div className="name mb-2">{questionnaireModule?.name}</div>
          </div>
          {!isLoading &&
            !isAssessmentLoading &&
            !isError &&
            questionnaireModule &&
            questionnaireModule?.groups?.map((group, groupIndex) =>
              group.isModal ? (
                <Fragment key={`ListGroup${group.name}${groupIndex}`}>
                  {Boolean(group.groupTitle) && assessmentModule[group.name]?.length > 0 && (
                    <div className="container px-4">
                      {Boolean(group.groupTitle) && (
                        <div className="row">
                          <div className="col-12 name list-group-title-name">
                            {group.groupTitle}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <StudyListGroup
                    groupTitle={group.title}
                    groupName={group.name}
                    fields={group.fields}
                    groupIndex={groupIndex}
                    formValues={formValues}
                    register={register}
                    moduleId={id}
                    assessmentModule={assessmentModule}
                  />
                </Fragment>
              ) : (
                <Fragment key={`Group${group.name}${groupIndex}`}>
                  {(Boolean(group.groupTitle) || Boolean(group.title)) && (
                    <div className="container px-4">
                      {Boolean(group.groupTitle) && (
                        <div className="row">
                          <div className="col-12 name mb-2">{group.groupTitle}</div>
                        </div>
                      )}
                      {Boolean(group.title) && (
                        <div className="row">
                          <div className="col-12 ps-1">
                            <p className="fs-5 mb-2">{group.title}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <StudyGroup
                    fields={group.fields}
                    groupIndex={groupIndex}
                    formValues={formValues}
                    register={register}
                    moduleId={id}
                    assessmentModule={assessmentModule}
                  />
                </Fragment>
              )
            )}
          {(isLoading || isAssessmentLoading) && (
            <div className="container">
              <LoadingQuestionnaireModule className="mt-1" />
            </div>
          )}
        </div>

        {!areAssessmentFinalFieldsHidden && (
          <>
            <div className="container">
              <div className="name mb-2">Finalizar</div>
            </div>
            <div className="module-row">
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-4 mt-4">
                    <h6 className="mb-3">Observaciones Generales</h6>
                    <div className="d-flex align-items-center">
                      <div>
                        <AssessmentStatusSelect value={moduleStatus} onChange={setModuleStatus} />
                      </div>
                      <div className="w-100 ms-4 module-observations-field field">
                        <input
                          type="text"
                          className="form-control"
                          value={moduleObservations}
                          onChange={(e) => setModuleObservations(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {!isLoading && !isAssessmentLoading && !isError && questionnaireModule && (
          <div className="save-controls d-flex justify-content-center">
            <button onClick={handleSave} className="btn me-5 save" type="button">
              Guardar avance
            </button>
            <button onClick={handleReject} className="btn me-5 reject" type="button">
              Devolver al candidato
            </button>
          </div>
        )}
      </form>
      <ToastContainer className="m-4" position="top-center">
        <Toast
          className={`text-bg-${isSaveError ? 'danger' : 'warning'}`}
          show={isSavingToastShowing}
          onClose={handleAfterSave}
          autohide
          delay={5000}
        >
          <Toast.Body>
            {!isSaveError && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {isSaveError
              ? 'Error al guardar el modulo, verifica los campos e intenta de nuevo en unos segundos...'
              : ' Guardando modulo, por favor no cierre el navegador o la pestaña...'}
          </Toast.Body>
        </Toast>
        <Toast
          className={`text-bg-success`}
          show={isSaveSuccessToastShowing}
          onClose={handleAfterSaveSuccess}
          autohide
          delay={5000}
        >
          <Toast.Body>¡Modulo guardado con éxito!</Toast.Body>
        </Toast>
      </ToastContainer>
      {isSavingToastShowing && !isSaveError && <div className="modal-backdrop show"></div>}
    </div>
  );
}

StudyModule.propTypes = {
  id: PropTypes.number,
  questionnaireId: PropTypes.number,
  assessmentId: PropTypes.number,
  register: PropTypes.func,
  mutateAssessments: PropTypes.func,
  formValues: PropTypes.object,
};
