import useSWRMutation from 'swr/mutation';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0';

async function sendImageUploadRequest(url, {arg}) {
  return fetch(url, {
    method: 'POST',
    body: arg,
  }).then((res) => res.json());
}

function useMediaUpload(userId, questionnaireId, questionnaireModuleId) {
  const {trigger, data, error, isMutating} = useSWRMutation(
    `${API_ROUTE}/questionnaires/${questionnaireId}/modules/${questionnaireModuleId}/documents?applicantId=${userId}`,
    sendImageUploadRequest
  );

  return {
    trigger,
    imageData: data,
    isMutating,
    isError: error,
  };
}

export default useMediaUpload;
