import PropTypes from 'prop-types';
import './JobReferences.scss';
import useJobReferences from '@/hooks/useJobReferences';
import LoadingQuestionnaireModule from '../LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import classNames from '@/helpers/classNames';
import studiesConst from '@/constants/studiesConst';
import {useState} from 'react';
import JobReferencesGeneratedTextField from './JobReferencesGeneratedTextField';
import useSaveJobReferences from '@/hooks/useSaveJobReferences';
import Saving from '../Saving/Saving';

function JobReferences({assessmentId}) {
  const [isOpen, setIsOpen] = useState(null);
  const {jobReferencesData, isLoading} = useJobReferences({logbookId: assessmentId});
  const {trigger, isMutating, isSaveError} = useSaveJobReferences(assessmentId);
  const {register, control, handleSubmit} = useForm({
    values: {
      jobReferences: jobReferencesData,
    },
  });
  const {fields: jobReferencesFields} = useFieldArray({
    control,
    name: 'jobReferences',
  });

  const onStatusOptionClick = (status, onChange) => {
    onChange(status);
    setIsOpen(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case studiesConst.moduleStatus.accepted:
        return 'green';
      case studiesConst.moduleStatus.rejected:
        return 'red';
      case studiesConst.moduleStatus.partiallyAccepted:
        return 'yellow';
      default:
        return 'white';
    }
  };

  const onSubmit = (data) => {
    trigger(
      JSON.stringify(
        data.jobReferences.map((jobReference) => ({
          index: Number(jobReference.index),
          companyName: jobReference.companyName || '',
          referenceName: jobReference.referenceName || '',
          position: jobReference.position || '',
          officePhone: jobReference.officePhone || '',
          mobilePhone: jobReference.mobilePhone || '',
          email: jobReference.email || '',
          referenceNamePOC: jobReference.referenceNamePOC || '',
          positionPOC: jobReference.positionPOC || '',
          contactPhonePOC: jobReference.contactPhonePOC || '',
          emailPOC: jobReference.emailPOC || '',
          sourceContactPOC: jobReference.sourceContactPOC || '',
          employmentVerified:
            typeof jobReference.employmentVerified === 'undefined'
              ? null
              : jobReference.employmentVerified,
          endingPosition: jobReference.endingPosition || '',
          jobTenure: jobReference.jobTenure || '',
          reasonForLeaving: jobReference.reasonForLeaving || '',
          salaryInfoProvided:
            typeof jobReference.salaryInfoProvided === 'undefined'
              ? null
              : jobReference.salaryInfoProvided,
          initialSalary: jobReference.initialSalary || '',
          finalSalary: jobReference.finalSalary || '',
          eligibleForRehire:
            typeof jobReference.eligibleForRehire === 'undefined'
              ? null
              : jobReference.eligibleForRehire,
          risksDetected:
            typeof jobReference.risksDetected === 'undefined' ? null : jobReference.risksDetected,
          outputText: jobReference.outputText || '',
          generalObservations: jobReference.generalObservations || '',
          status: typeof jobReference.status === 'undefined' ? 0 : Number(jobReference.status),
        }))
      )
    );
  };

  if (isLoading) {
    return (
      <div className="container job-references dashboard">
        <LoadingQuestionnaireModule className="mt-3" />
      </div>
    );
  }

  return (
    <div className="container job-references dashboard">
      <div className="name mb-5">Referencias Laborales</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {jobReferencesFields.map((jobReferenceFields, jobReferenceFieldsIndex) => (
            <div className="col-12 outlined-background mb-4" key={jobReferenceFields.id}>
              <h5 className="fw-semibold mb-3">
                Referencia laboral {jobReferenceFieldsIndex + 1} - {jobReferenceFields.companyName}
              </h5>
              <div className="row summary">
                <div className="col-5">
                  <p className="info-title">Nombre del personal de Recursos Humanos</p>
                  <p className="reply">{jobReferenceFields.referenceName || '-'}</p>
                  <p className="info-title">Teléfono de oficina</p>
                  <p className="reply">{jobReferenceFields.officePhone || '-'}</p>
                  <p className="info-title">Correo electrónico</p>
                  <p className="reply mb-0">{jobReferenceFields.email || '-'}</p>
                </div>
                <div className="col-2" />
                <div className="col-5">
                  <p className="info-title">Puesto</p>
                  <p className="reply">{jobReferenceFields.position || '-'}</p>
                  <p className="info-title">Teléfono Móvil</p>
                  <p className="reply">{jobReferenceFields.mobilePhone || '-'}</p>
                </div>
              </div>
              <hr className="my-5" />
              <h5 className="fw-semibold mb-3">Sobre la persona que da la referencia</h5>
              <div className="row reference-about">
                <div className="col-5">
                  <div className="reference-name mb-4 pb-2">
                    <div className="mb-1">Nombre</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.referenceNamePOC`)}
                    />
                  </div>
                  <div className="reference-phone mb-4 pb-2">
                    <div className="mb-1">Télefono</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.contactPhonePOC`)}
                    />
                  </div>
                  <div className="reference-phone">
                    <div>¿Cuál es el medio por el cuál pudiste contactarlo?</div>
                    <select
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.sourceContactPOC`)}
                      defaultValue={''}
                      className="form-select"
                    >
                      <option value="" disabled>
                        Seleccione una opción
                      </option>
                      <option value="SRC-CALL">Llamada telefónica</option>
                      <option value="SRC-SMS">SMS</option>
                      <option value="SRC-WA">WhatsApp</option>
                      <option value="SRC-EMAIL">Correo electrónico</option>
                    </select>
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5">
                  <div className="reference-position mb-4 pb-2">
                    <div className="mb-1">Puesto</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.positionPOC`)}
                    />
                  </div>
                  <div className="reference-email">
                    <div className="mb-1">Correo electrónico</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.emailPOC`)}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <h5 className="fw-semibold mb-3">Datos del Candidato</h5>
              <div className="row candidate-empoyment">
                <div className="col-5">
                  <div className="employment-confirmation mb-4 pb-2">
                    <div className="mb-1">¿Confirman que laboró con ellos?</div>
                    <Controller
                      control={control}
                      name={`jobReferences.${jobReferenceFieldsIndex}.employmentVerified`}
                      render={({field}) => (
                        <div className="d-flex">
                          <div className="form-check form-check-inline pe-4 me-5">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.employmentVerified-yes`}
                              className="form-check-input"
                              type="radio"
                              value="true"
                              {...field}
                              checked={field.value === true}
                              onChange={() => field.onChange(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.employmentVerified-yes`}
                            >
                              Si
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.employmentVerified-no`}
                              className="form-check-input"
                              type="radio"
                              value="false"
                              {...field}
                              checked={field.value === false}
                              onChange={() => field.onChange(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.employmentVerified-no`}
                            >
                              No
                            </label>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="ending-position mb-4 pb-2">
                    <div className="mb-1">¿Cuál fue el último puesto que ocupó el candidato?</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.endingPosition`)}
                    />
                  </div>
                  <div className="exit-motive">
                    <div className="mb-1">Motivo de Salida</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.reasonForLeaving`)}
                    />
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5">
                  <div className="tenure">
                    <div className="mb-1">¿Cuanto tiempo laboro ahí?</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.jobTenure`)}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <div className="row candidate-salary">
                <div className="col-5">
                  <div className="salary-confirmation mb-4 pb-2">
                    <div className="mb-1">¿Te dieron información acerca del sueldo?</div>
                    <Controller
                      control={control}
                      name={`jobReferences.${jobReferenceFieldsIndex}.salaryInfoProvided`}
                      render={({field}) => (
                        <div className="d-flex">
                          <div className="form-check form-check-inline pe-4 me-5">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.salaryInfoProvided-yes`}
                              className="form-check-input"
                              type="radio"
                              value="true"
                              {...field}
                              checked={field.value === true}
                              onChange={() => field.onChange(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.salaryInfoProvided-yes`}
                            >
                              Si
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.salaryInfoProvided-no`}
                              className="form-check-input"
                              type="radio"
                              value="false"
                              {...field}
                              checked={field.value === false}
                              onChange={() => field.onChange(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.salaryInfoProvided-no`}
                            >
                              No
                            </label>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5" />
                <div className="col-5">
                  <div className="initial-salary">
                    <div className="mb-1">Sueldo Inicial</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.initialSalary`)}
                    />
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5">
                  <div className="final-salary">
                    <div className="mb-1">Sueldo Final</div>
                    <input
                      type="text"
                      className="form-control"
                      {...register(`jobReferences.${jobReferenceFieldsIndex}.finalSalary`)}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <div className="row candidate-rehire">
                <div className="col-5">
                  <div className="rehire-confirmation">
                    <div className="mb-1">¿Lo volverían a contratar?</div>
                    <Controller
                      control={control}
                      name={`jobReferences.${jobReferenceFieldsIndex}.eligibleForRehire`}
                      render={({field}) => (
                        <div className="d-flex">
                          <div className="form-check form-check-inline pe-4 me-5">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.eligibleForRehire-yes`}
                              className="form-check-input"
                              type="radio"
                              value="true"
                              {...field}
                              checked={field.value === true}
                              onChange={() => field.onChange(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.eligibleForRehire-yes`}
                            >
                              Si
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.eligibleForRehire-no`}
                              className="form-check-input"
                              type="radio"
                              value="false"
                              {...field}
                              checked={field.value === false}
                              onChange={() => field.onChange(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.eligibleForRehire-no`}
                            >
                              No
                            </label>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5">
                  <div className="risks-detected">
                    <div className="mb-1">¿Detectaste elementos de riesgo?</div>
                    <Controller
                      control={control}
                      name={`jobReferences.${jobReferenceFieldsIndex}.risksDetected`}
                      render={({field}) => (
                        <div className="d-flex">
                          <div className="form-check form-check-inline pe-4 me-5">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.risksDetected-yes`}
                              className="form-check-input"
                              type="radio"
                              value="true"
                              {...field}
                              checked={field.value === true}
                              onChange={() => field.onChange(true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.risksDetected-yes`}
                            >
                              Si
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id={`jobReferences.${jobReferenceFieldsIndex}.risksDetected-no`}
                              className="form-check-input"
                              type="radio"
                              value="false"
                              {...field}
                              checked={field.value === false}
                              onChange={() => field.onChange(false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`jobReferences.${jobReferenceFieldsIndex}.risksDetected-no`}
                            >
                              No
                            </label>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-5" />
              <div className="row generate-text">
                <div className="col-12">
                  <Controller
                    control={control}
                    name={`jobReferences.${jobReferenceFieldsIndex}.outputText`}
                    render={({field}) => (
                      <JobReferencesGeneratedTextField
                        logbookId={assessmentId}
                        jobReferenceIndex={jobReferenceFields.index}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                  <div className="observations">
                    <div className="mb-1">Observaciones</div>
                    <div className="d-flex">
                      <div>
                        <Controller
                          control={control}
                          name={`jobReferences.${jobReferenceFieldsIndex}.status`}
                          render={({field}) => (
                            <>
                              <div
                                {...classNames(
                                  'form-control d-flex align-items-center status-select',
                                  isOpen === jobReferenceFieldsIndex && 'focused'
                                )}
                                onClick={() => setIsOpen(jobReferenceFieldsIndex)}
                              >
                                <div {...classNames('dot', getStatusColor(field.value))} />
                                <i className="bi bi-chevron-down"></i>
                              </div>
                              <div
                                {...classNames(
                                  'status-select-options',
                                  isOpen === jobReferenceFieldsIndex ? 'd-flex' : 'd-none'
                                )}
                              >
                                <div
                                  className="option"
                                  onClick={() =>
                                    onStatusOptionClick(
                                      studiesConst.moduleStatus.accepted,
                                      field.onChange
                                    )
                                  }
                                >
                                  <div className="dot green" />
                                </div>
                                <div
                                  className="option"
                                  onClick={() =>
                                    onStatusOptionClick(
                                      studiesConst.moduleStatus.partiallyAccepted,
                                      field.onChange
                                    )
                                  }
                                >
                                  <div className="dot yellow" />
                                </div>
                                <div
                                  className="option"
                                  onClick={() =>
                                    onStatusOptionClick(
                                      studiesConst.moduleStatus.rejected,
                                      field.onChange
                                    )
                                  }
                                >
                                  <div className="dot red" />
                                </div>
                              </div>
                            </>
                          )}
                        />
                      </div>
                      <div className="w-100">
                        <input
                          type="text"
                          className="form-control"
                          {...register(
                            `jobReferences.${jobReferenceFieldsIndex}.generalObservations`
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="submit-button d-flex justify-content-center mt-3 controls">
              <button className="btn btn-warning action" type="submit">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
      <Saving
        isMutating={isMutating}
        isSaveError={isSaveError}
        savingMessage="Guardando las referencias laborales, por favor no cierre el navegador o la pestaña..."
        errorMessage="Error al guardar las referencias laborales, verifica los campos e intenta de nuevo en unos segundos..."
        successMessage="¡Referencias laborales guardadas con éxito!"
      />
      <div
        {...classNames('overlay', isOpen === null && 'd-none')}
        onClick={() => setIsOpen(null)}
      />
    </div>
  );
}

JobReferences.propTypes = {
  assessmentId: PropTypes.string,
};

export default JobReferences;
