import PropTypes from 'prop-types';
import AnalystHeader from '../../components/AnalystHeader/AnalystHeader';
import CandidateHeader from '../../components/CandidateHeader/CandidateHeader';
import rolesConst from '../../constants/rolesConst';
import ErrorPage from '../../error-page';
import {useRouteLoaderData} from 'react-router-dom';
import Header from '../../components/Header/Header';

export default function UnauthorizedPage({goBackUrl}) {
  const {userRole} = useRouteLoaderData('root');

  const getHeaderComponent = () => {
    if (userRole) {
      switch (userRole) {
        case rolesConst.analyst:
          return <AnalystHeader />;
        case rolesConst.applicant:
          return <CandidateHeader />;
        default:
          <Header />;
      }
    }
  };

  return (
    <>
      {getHeaderComponent()}
      <ErrorPage goBackUrl={goBackUrl} />
    </>
  );
}

UnauthorizedPage.propTypes = {
  goBackUrl: PropTypes.string,
};
