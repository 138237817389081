/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Header from '@/components/Header/Header';
import userConst from '@/constants/userConst';
import routesConst from '@/constants/routesConst';
import rolesConst from '@/constants/rolesConst';
import {redirectToLogin} from '@/helpers/navigationHelper';
import LoadingQuestionnaireModule from '@/components/LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import {getUserSession, login} from '@/helpers/userHelper';

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async () => {
    login({
      accessToken: searchParams.get('at'),
      idToken: searchParams.get('idt'),
      refreshToken: searchParams.get('rt'),
    });
    const userSession = await getUserSession();
    setIsLoading(false);

    switch (userSession?.idToken?.payload?.[userConst.role]) {
      case rolesConst.admin:
        navigate(routesConst.adminHome);
        break;
      case rolesConst.analyst:
        navigate(routesConst.analystHome);
        break;
      case rolesConst.applicant:
        navigate(routesConst.candidateHome);
        break;
      default:
        redirectToLogin();
    }
  };

  useEffect(() => {
    if (searchParams.get('at') && searchParams.get('idt') && searchParams.get('rt')) {
      handleLogin();
    }
  }, [searchParams]);

  return (
    <>
      <Header />
      <main>{isLoading ? <LoadingQuestionnaireModule /> : 'Login success'}</main>
    </>
  );
}
