/* eslint-disable react-hooks/exhaustive-deps */
import {useSearchParams} from 'react-router-dom';
import useAssessments from '../../hooks/useAssessments';
import {Tab, Tabs} from '@mui/material';
import {useEffect, useState} from 'react';
import StudyModule from './StudyModule/StudyModule';
import useDynamicForm from '../../hooks/useDynamicForm';
import classNames from '../../helpers/classNames';
import studiesConst from '../../constants/studiesConst';
import '../../components/Dashboard/Dashboard.scss';
import './AnalystStudyPage.scss';
import Logbook from '@/components/Logbook/Logbook';
import {SelectedModuleIdContext} from '@/context/SelectedModuleIdContext';
import LogbookReview from '@/components/LogbookReview/LogbookReview';
import useLogbook from '@/hooks/useLogbook';
import PropTypes from 'prop-types';
import RemoteVisit from '@/components/RemoteVisit/RemoteVisit';
import JobReferences from '@/components/JobReferences/JobReferences';
import GeneralObservations from '@/components/GeneralObservations/GeneralObservations';

function LogbookReviewAnalyst({assessmentId}) {
  const {logbook, isLoading} = useLogbook({assessmentId});

  if (isLoading) {
    return;
  }

  return <LogbookReview logbook={logbook} />;
}

export default function AnalystStudyPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {assessments: assessment, isLoading, mutate} = useAssessments({id: searchParams.get('id')});
  const [selectedModuleId, setSelectedModuleId] = useState(() => searchParams.get('moduleId'));
  const {register, formValues, clear} = useDynamicForm();

  useEffect(() => {
    if (!selectedModuleId) {
      history.back();
    }

    const searchParamsModuleId = isNaN(Number(searchParams.get('moduleId')))
      ? searchParams.get('moduleId')
      : Number(searchParams.get('moduleId'));

    if (selectedModuleId !== searchParamsModuleId) {
      searchParams.set('moduleId', selectedModuleId);
      setSearchParams(searchParams);
    }
  }, [selectedModuleId]);

  useEffect(() => {
    if (!searchParams.get('moduleId')) {
      history.back();
    }

    const searchParamsModuleId = isNaN(Number(searchParams.get('moduleId')))
      ? searchParams.get('moduleId')
      : Number(searchParams.get('moduleId'));

    if (selectedModuleId !== searchParamsModuleId) {
      setSelectedModuleId(searchParamsModuleId);
    }
  }, [searchParams]);

  const handleChange = (event, newModuleId) => {
    clear();
    setSelectedModuleId(isNaN(Number(newModuleId)) ? newModuleId : Number(newModuleId));
  };

  const getStudyColor = (moduleStatus) => {
    switch (moduleStatus) {
      case studiesConst.moduleStatus.noStatus:
        return 'figure--white';
      case studiesConst.moduleStatus.inProgress:
        return 'figure--gray';
      case studiesConst.moduleStatus.completed:
      case studiesConst.moduleStatus.inReview:
        return 'figure--blue';
      case studiesConst.moduleStatus.accepted:
        return 'figure--green';
      case studiesConst.moduleStatus.partiallyAccepted:
        return 'figure--yellow';
      case studiesConst.moduleStatus.rejected:
        return 'figure--red';
      default:
        return 'figure--white';
    }
  };

  return (
    <main>
      <div className="study-dashboard dashboard d-flex align-items-center flex-column">
        {!isLoading && assessment && selectedModuleId && (
          <>
            <div className="module-tabs container">
              <Tabs
                value={selectedModuleId}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
              >
                <Tab className="logbook-tab-btn" label="Bitácora" value="logbook" />
                {assessment?.modules?.map((assessmentModule) => (
                  <Tab
                    key={assessmentModule.id}
                    label={assessmentModule.name}
                    value={assessmentModule.id}
                    iconPosition="start"
                    icon={
                      <span
                        {...classNames('dot rounded', getStudyColor(assessmentModule.moduleStatus))}
                      />
                    }
                  />
                ))}
                <Tab className="logbook-tab-btn" label="Visita Remota" value="remoteVisit" />
                <Tab
                  className="logbook-tab-btn"
                  label="Referencias Laborales"
                  value="jobReferences"
                />
                <Tab
                  className="logbook-tab-btn"
                  label="Observaciones Generales"
                  value="generalObservations"
                />
              </Tabs>
            </div>
            <SelectedModuleIdContext.Provider value={selectedModuleId}>
              {selectedModuleId === 'logbook' ? (
                assessment?.reportAvailable ? (
                  <div className="mt-5">
                    <LogbookReviewAnalyst assessmentId={searchParams.get('id')} />
                  </div>
                ) : (
                  <Logbook assessmentId={searchParams.get('id')} />
                )
              ) : selectedModuleId === 'remoteVisit' ? (
                <RemoteVisit assessmentId={searchParams.get('id')} />
              ) : selectedModuleId === 'jobReferences' ? (
                <JobReferences assessmentId={searchParams.get('id')} />
              ) : selectedModuleId === 'generalObservations' ? (
                <GeneralObservations assessmentId={searchParams.get('id')} />
              ) : (
                <StudyModule
                  id={selectedModuleId}
                  assessmentId={Number(searchParams.get('id'))}
                  questionnaireId={assessment.questionnaireId}
                  register={register}
                  formValues={formValues}
                  mutateAssessments={() => mutate()}
                />
              )}
            </SelectedModuleIdContext.Provider>
          </>
        )}
      </div>
    </main>
  );
}

LogbookReviewAnalyst.propTypes = {
  assessmentId: PropTypes.string,
};
