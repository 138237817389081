const splitFieldNameFromGroupName = (valueParam) => ({
  name: valueParam.split('*')[0],
  group: valueParam.split('*')?.[1] ?? null,
  groupIndex: valueParam.split('*')?.[1]?.split('#')?.[1]?.split('@')[0] ?? null,
});

const addProperty = (splittedParams) =>
  splittedParams.group
    ? {
        ...splittedParams,
        group: splittedParams.group.split('#')?.[0],
        valueProperty: splittedParams.group.split('#')?.[1]?.split('@')?.[1] ?? 'value',
      }
    : {
        name: splittedParams.name.split('@')[0],
        valueProperty: splittedParams.name.split('@')?.[1] ?? 'value',
      };

export const formatFormValues = (formValues = {}) => {
  const valuesParams = Object.keys(formValues);

  return valuesParams.reduce((accValues, currentValue) => {
    const valueParams = addProperty(splitFieldNameFromGroupName(currentValue));
    const isGroup = Boolean(valueParams.group);
    const isValueInAccValues = isGroup
      ? Boolean(accValues?.[valueParams?.group])
      : Boolean(accValues?.[valueParams?.name]);

    if (isGroup && isValueInAccValues) {
      const isIndexInGroup = Boolean(accValues[valueParams.group][valueParams.groupIndex]);

      if (isIndexInGroup) {
        const isNameInGroupIndex = Boolean(
          accValues[valueParams.group][valueParams.groupIndex][valueParams.name]
        );

        if (isNameInGroupIndex) {
          accValues[valueParams.group][valueParams.groupIndex][valueParams.name][
            valueParams.valueProperty
          ] = formValues[currentValue];

          return accValues;
        }

        if (!isNameInGroupIndex) {
          accValues[valueParams.group][valueParams.groupIndex] = {
            ...accValues[valueParams.group][valueParams.groupIndex],
            [valueParams.name]: {[valueParams.valueProperty]: formValues[currentValue]},
          };

          return accValues;
        }
      }

      if (!isIndexInGroup) {
        accValues[valueParams.group][valueParams.groupIndex] = {
          [valueParams.name]: {[valueParams.valueProperty]: formValues[currentValue]},
        };

        return accValues;
      }
    }

    if (isGroup && !isValueInAccValues) {
      accValues[valueParams.group] = [];
      accValues[valueParams.group][valueParams.groupIndex] = {
        [valueParams.name]: {[valueParams.valueProperty]: formValues[currentValue]},
      };

      return accValues;
    }

    if (!isGroup && isValueInAccValues) {
      accValues[valueParams.name][valueParams.valueProperty] = formValues[currentValue];

      return accValues;
    }

    if (!isGroup && !isValueInAccValues) {
      accValues[valueParams.name] = {[valueParams.valueProperty]: formValues[currentValue]};

      return accValues;
    }
  }, {});
};

export const isAnalystSelectionIncomplete = (formValues) =>
  Object.keys(formValues).reduce((prevAnswer, formValueName) => {
    const isAnalystSelection = formValueName.split('@analystSelection').length === 2;
    return isAnalystSelection && formValues[formValueName] === null ? true : prevAnswer;
  }, false);
