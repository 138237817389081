import {Outlet, ScrollRestoration} from 'react-router-dom';
import useUser from '../hooks/useUser';
import {useState} from 'react';
import Footer from '../components/Footer/Footer';
import AnalystHeader from '../components/AnalystHeader/AnalystHeader';
import useRoleNavigation from '../hooks/useRoleNavigation';
import rolesConst from '../constants/rolesConst';

export default function AnalystRoot() {
  const {isUnauthorized, renderUnauthorized} = useRoleNavigation(rolesConst.analyst);
  const {user, isLoading, isError} = useUser();
  const [hasModuleSaved, setHasModuleSaved] = useState(false);
  const [selectedAssessmentDetailsId, setSelectedAssessmentDetailsId] = useState(null);

  if (isUnauthorized) {
    return renderUnauthorized;
  }

  return (
    <>
      <AnalystHeader />
      {!isLoading && !isError && user ? (
        <Outlet
          context={{
            hasModuleSaved,
            setHasModuleSaved,
            isAdmin: true,
            selectedAssessmentDetailsId,
            setSelectedAssessmentDetailsId,
          }}
        />
      ) : (
        <div style={{minHeight: '90vh'}}></div>
      )}
      <Footer />
      <ScrollRestoration />
    </>
  );
}
