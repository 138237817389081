import useSWR from 'swr';
import {useParams, useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '@/helpers/fetcherWithToken';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/questionnaires';

function useAnswers(user) {
  const {userSession} = useRouteLoaderData('root') || {};
  const {questionnaireModuleId} = useParams();
  const {data, error, isLoading} = useSWR(
    () =>
      user.id && user.questionnaireId && questionnaireModuleId
        ? [
            `${API_ROUTE}/${user.questionnaireId}/modules/${questionnaireModuleId}/answers?applicantId=${user.id}`,
            userSession?.accessToken?.jwtToken,
          ]
        : null,
    ([url, token]) => fetcherWithToken(url, token)
  );

  return {
    answers: data,
    isLoading,
    isError: error,
  };
}

export default useAnswers;
