import studiesConst from '@/constants/studiesConst';
import PropTypes from 'prop-types';
import './AssessmentStatusSelect.scss';
import {useState} from 'react';
import classNames from '@/helpers/classNames';

function AssessmentStatusSelect({value, onChange}) {
  const [isOpen, setIsOpen] = useState(false);

  const getStatusColor = (status) => {
    switch (status) {
      case studiesConst.moduleStatus.completed:
      case studiesConst.moduleStatus.inReview:
        return 'blue';
      case studiesConst.moduleStatus.accepted:
        return 'green';
      case studiesConst.moduleStatus.rejected:
        return 'red';
      case studiesConst.moduleStatus.partiallyAccepted:
        return 'yellow';
      case studiesConst.moduleStatus.invalid:
        return 'strong-gray';
      default:
        return 'white';
    }
  };

  const onStatusOptionClick = (status) => {
    onChange && onChange(status);
    setIsOpen(false);
  };

  return (
    <div className="assessment-status-select">
      <div
        {...classNames('form-control d-flex align-items-center status-select', isOpen && 'focused')}
        onClick={() => setIsOpen(true)}
      >
        <div {...classNames('dot', getStatusColor(value))} />
        <i className="bi bi-chevron-down"></i>
      </div>
      <div {...classNames('status-select-options', isOpen ? 'd-flex' : 'd-none')}>
        <div
          className="option"
          onClick={() => onStatusOptionClick(studiesConst.moduleStatus.accepted)}
        >
          <div className="dot green" />
        </div>
        <div
          className="option"
          onClick={() => onStatusOptionClick(studiesConst.moduleStatus.partiallyAccepted)}
        >
          <div className="dot yellow" />
        </div>
        <div
          className="option"
          onClick={() => onStatusOptionClick(studiesConst.moduleStatus.rejected)}
        >
          <div className="dot red" />
        </div>
        <div
          className="option"
          onClick={() => onStatusOptionClick(studiesConst.moduleStatus.invalid)}
        >
          <div className="dot strong-gray" />
        </div>
      </div>
      <div
        {...classNames('overlay', isOpen === false && 'd-none')}
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
}

AssessmentStatusSelect.propTypes = {
  assessmentId: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default AssessmentStatusSelect;
