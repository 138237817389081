/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import classNames from '../../helpers/classNames';
import PropTypes from 'prop-types';

export default function Checkbox({
  items,
  defaultValue,
  name,
  handleOnChange,
  className,
  isDisabledByDependency,
  isStudy,
  isReadOnly,
}) {
  const [selectedValues, setSelectedValues] = useState(
    () =>
      (defaultValue &&
        defaultValue.reduce((selectedValues, value) => ({...selectedValues, [value]: true}), {})) ||
      {}
  );

  useEffect(() => {
    if (isDisabledByDependency) {
      setSelectedValues({});
      handleOnChange(null);
    }
  }, [handleOnChange, isDisabledByDependency]);

  const handleCheckboxChange = (e) => {
    const newSelectedValues = {
      ...selectedValues,
      [e.target.value]: !selectedValues[e.target.value],
    };
    const formattedValues = Object.keys(newSelectedValues).filter(
      (value) => newSelectedValues[value]
    );

    setSelectedValues(newSelectedValues);
    handleOnChange(formattedValues);
  };

  return (
    <div
      {...classNames(
        className,
        'checkbox-group',
        !isStudy && 'p-3',
        isStudy && 'checkbox-study-group'
      )}
    >
      {items
        .filter((item) => !isStudy || selectedValues[item.id])
        .map((item, itemIndex) => (
          <div
            key={`${name}${itemIndex}${item.id}--checkbox`}
            {...classNames(
              item.size && !isStudy && `col-${item.size <= 3 ? 'xl' : 'md'}-${item.size}`,
              item.size <= 3 && 'col-lg-4',
              item.size <= 3 && 'col-md-6',
              item.size > 3 && 'col-sm-12',
              'd-flex justify-content-start'
            )}
          >
            <div
              {...classNames(
                'd-flex justify-content-start',
                item.size > 3 && 'checkbox-column-gap',
                'mb-1'
              )}
            >
              <input
                {...classNames('form-check-input me-2')}
                type="radio"
                value={item.id}
                checked={selectedValues[item.id] || false}
                id={`${name}${itemIndex}`}
                onClick={handleCheckboxChange}
                onChange={() => {}}
                disabled={isDisabledByDependency || Boolean(isReadOnly)}
              />
              <label className="form-check-label me-2 ps-1" htmlFor={`${name}${itemIndex}`}>
                {item.value}
              </label>
            </div>
          </div>
        ))}
    </div>
  );
}

Checkbox.propTypes = {
  items: PropTypes.array,
  defaultValue: PropTypes.array,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  className: PropTypes.string,
  isDisabledByDependency: PropTypes.bool,
  isStudy: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
