import PropTypes from 'prop-types';
import useServerSelectOptions from '../../hooks/useServerSelectOptions';
import {useEffect, useRef, useState} from 'react';
import classNames from '../../helpers/classNames';

export default function SelectInput({
  label,
  getName,
  isDirty,
  isRequiredValid,
  value,
  preferredDefaultValue,
  isReadOnly,
  handleOnChange,
  items,
  requiredMessage,
  fetchItems,
  isDisabledByDependency,
  formValues,
  listGroupName,
  groupSectionIndex,
  isHiddenByDependency,
  isStudy,
  isAnalysisField,
}) {
  const inputRef = useRef(null);
  const isServerOptions = typeof fetchItems !== 'undefined' && Boolean(fetchItems.relatedTo);
  const studyRelatedTo =
    isServerOptions &&
    isStudy &&
    fetchItems.relatedTo.concat(getName().substring(getName().search(/\*/)));
  const isListGroup = typeof groupSectionIndex === 'number';
  const listGroupLinkedFieldValue =
    isListGroup && isStudy
      ? formValues?.[studyRelatedTo]
      : formValues?.[listGroupName]?.[groupSectionIndex]?.[fetchItems?.relatedTo];
  const linkedFieldValue = formValues?.[fetchItems?.relatedTo];
  const [prevIsLinkedToValue, setPrevIsLinkedToValue] = useState(null);
  const [isDefaultValueLoaded, setIsDefaultValueLoaded] = useState(false);
  const {fetchedOptions, isLoading, isError} = useServerSelectOptions({
    optionsBy: fetchItems?.identifiedBy,
    linkedFieldResponse: prevIsLinkedToValue,
  });
  const fetchedOptionsSize = fetchedOptions?.items.length;

  useEffect(() => {
    if (isHiddenByDependency || isDisabledByDependency) {
      if (inputRef?.current) {
        inputRef.current.value = '';
        handleOnChange(null);
      }
    }
  }, [handleOnChange, isHiddenByDependency, isDisabledByDependency]);

  useEffect(() => {
    if (isServerOptions) {
      if (
        isListGroup &&
        typeof listGroupLinkedFieldValue !== 'undefined' &&
        listGroupLinkedFieldValue !== prevIsLinkedToValue
      ) {
        setPrevIsLinkedToValue(listGroupLinkedFieldValue);
        inputRef.current.value = '';
        handleOnChange(null);
      } else if (
        typeof linkedFieldValue !== 'undefined' &&
        linkedFieldValue !== prevIsLinkedToValue
      ) {
        setPrevIsLinkedToValue(linkedFieldValue);
        inputRef.current.value = '';
        handleOnChange(null);
      }

      if (
        fetchedOptionsSize &&
        preferredDefaultValue &&
        !isDefaultValueLoaded &&
        !isLoading &&
        prevIsLinkedToValue &&
        (linkedFieldValue || listGroupLinkedFieldValue)
      ) {
        setIsDefaultValueLoaded(true);
        inputRef.current.value = preferredDefaultValue;
        handleOnChange(preferredDefaultValue);
      }
    }
  }, [
    isServerOptions,
    isListGroup,
    prevIsLinkedToValue,
    listGroupLinkedFieldValue,
    linkedFieldValue,
    handleOnChange,
    isDefaultValueLoaded,
    fetchedOptionsSize,
    preferredDefaultValue,
    value,
    isLoading,
  ]);

  const renderOptions = (options) =>
    options &&
    options.map((option, index) =>
      option.id === null || typeof option.id === 'undefined' ? (
        <option value="" key={`${getName()}-${index}-option`} disabled>
          {option.value}
        </option>
      ) : (
        <option value={option.id} key={`${getName()}-${index}-option`}>
          {option.value}
        </option>
      )
    );

  return (
    <>
      <label
        htmlFor={getName()}
        {...classNames(
          'form-label',
          isDisabledByDependency && 'disabled',
          isStudy && !isAnalysisField && 'mb-4',
          isStudy && isAnalysisField && 'my-0 ms-0'
        )}
      >
        {label}
      </label>
      <select
        id={getName()}
        name={getName()}
        {...classNames(
          'form-select',
          isDirty && !isRequiredValid && 'is-invalid',
          typeof value !== 'number' && typeof value !== 'string' && 'default-selected'
        )}
        defaultValue={preferredDefaultValue || ''}
        ref={inputRef}
        onChange={() => handleOnChange(inputRef.current.value)}
        disabled={Boolean(isReadOnly) || isDisabledByDependency}
      >
        {items
          ? renderOptions(items)
          : isServerOptions &&
            (prevIsLinkedToValue && fetchedOptions && !isLoading && !isError
              ? renderOptions(fetchedOptions.items)
              : renderOptions(fetchItems.defaultValues))}
      </select>
      {isDirty && !isRequiredValid && <p className="invalid-feedback">* {requiredMessage}</p>}
    </>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  getName: PropTypes.func,
  isDirty: PropTypes.bool,
  isRequiredValid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  preferredDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isReadOnly: PropTypes.bool,
  handleOnChange: PropTypes.func,
  items: PropTypes.array,
  requiredMessage: PropTypes.string,
  fetchItems: PropTypes.object,
  fieldRef: PropTypes.object,
  isDisabledByDependency: PropTypes.bool,
  isHiddenByDependency: PropTypes.bool,
  isStudy: PropTypes.bool,
  isAnalysisField: PropTypes.bool,
  formValues: PropTypes.object,
  listGroupName: PropTypes.string,
  groupSectionIndex: PropTypes.number,
};
