import {useState} from 'react';
import classNames from '../../helpers/classNames';
import PropTypes from 'prop-types';

export default function CustomCheckbox({items, defaultValue, name, onChange}) {
  const [selectedValue, setSelectedValue] = useState(() =>
    defaultValue === null || typeof defaultValue === 'undefined' ? null : defaultValue ? 1 : 0
  );
  const handleCheckboxChange = (e) => {
    onChange(Number(e.target.value));
    setSelectedValue(Number(e.target.value));
  };

  return (
    <div className="d-flex mx-1 mt-2 justify-content-between flex-wrap">
      {items.map((item, itemIndex) => (
        <div key={`${name}${itemIndex}--checkbox`} className="form-check">
          <input
            {...classNames(
              'form-check-input',
              selectedValue === Number(item.id)
                ? Number(item.id) === 1
                  ? 'text-bg-success'
                  : 'text-bg-danger'
                : ''
            )}
            type="checkbox"
            value={item.id}
            checked={selectedValue === Number(item.id)}
            id={`${name}${itemIndex}`}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label me-2" htmlFor={`${name}${itemIndex}`}>
            {item.value}
          </label>
        </div>
      ))}
    </div>
  );
}

CustomCheckbox.propTypes = {
  items: PropTypes.array,
  defaultValue: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
