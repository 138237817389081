import {Outlet, ScrollRestoration} from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import useRoleNavigation from '../hooks/useRoleNavigation';
import useUser from '../hooks/useUser';
import AdminHeader from '../components/AdminHeader/AdminHeader';
import rolesConst from '../constants/rolesConst';

export default function AdminRoot() {
  const {isUnauthorized, renderUnauthorized} = useRoleNavigation(rolesConst.admin);
  const {user, isLoading, isError} = useUser();

  if (isUnauthorized) {
    return renderUnauthorized;
  }

  return (
    <>
      <AdminHeader />
      {!isLoading && !isError && user ? <Outlet /> : <div style={{minHeight: '90vh'}}></div>}
      <Footer />
      <ScrollRestoration />
    </>
  );
}
