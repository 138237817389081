import {NavLink} from 'react-router-dom';
import './Header.scss';
import logo from '/logo.png';
import PropTypes from 'prop-types';

export default function Header({children, roleHome}) {
  return (
    <nav className="header">
      <div className="inner">
        <div className="header-section container">
          <NavLink to={roleHome} className="logo-container">
            <img src={logo} alt="Ethics Socioeconomics and Background Check Logo" />
          </NavLink>
          <div className="header-section-nav">{children}</div>
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  children: PropTypes.array,
  roleHome: PropTypes.string,
};
