import PropTypes from 'prop-types';
import Field from '../Field/Field';

export default function StudyGroup({fields, register, groupIndex, formValues, assessmentModule}) {
  return (
    <>
      {fields
        .filter(
          (field) =>
            field.name !== 'id' &&
            field.type !== 'padding' &&
            ((field.format === 'checkbox' &&
              assessmentModule?.[field.name]?.value !== null &&
              typeof assessmentModule?.[field.name]?.value !== 'undefined') ||
              field.format !== 'checkbox')
        )
        .map((field, fieldIndex) => (
          <div className="module-row group" key={`${fieldIndex}${field.name}row`}>
            <div className="container">
              <div className="row">
                <Field
                  field={field}
                  defaultValue={assessmentModule?.[field.name]?.value}
                  register={register}
                  formValues={formValues}
                  groupIndex={groupIndex}
                  fieldIndex={fieldIndex}
                  customSize={5}
                  isReadOnly
                  isStudy
                />
                <Field
                  field={{
                    name: `${field.name}@analystSelection`,
                    label: 'Evaluación',
                    type: 'array',
                    format: 'radio',
                    isAnalysisField: true,
                    items: [
                      {id: '1', value: 'Aceptado'},
                      {id: '0', value: 'Rechazado'},
                    ],
                    isRequired: true,
                    requiredMessage: 'Este valor es requerido',
                  }}
                  defaultValue={assessmentModule?.[field.name]?.analystSelection}
                  register={register}
                  groupIndex={groupIndex}
                  customSize={3}
                  isStudy
                />
                <Field
                  field={{
                    name: `${field.name}@analystObservations`,
                    label: 'Observaciones',
                    type: 'string',
                    isRequired: true,
                    requiredMessage: 'Este valor es requerido',
                  }}
                  defaultValue={assessmentModule?.[field.name]?.analystObservations}
                  register={register}
                  groupIndex={groupIndex}
                  customSize={4}
                  isStudy
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

StudyGroup.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  register: PropTypes.func,
  groupIndex: PropTypes.number,
  formValues: PropTypes.object,
  assessmentModule: PropTypes.object,
};
