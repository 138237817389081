import useSWRInfinite from 'swr/infinite';
import {useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '@/helpers/fetcherWithToken';
import useUser from './useUser';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/assessments';

function useLogbooks() {
  const {userSession} = useRouteLoaderData('root') || {};
  const {user} = useUser();
  const {data, error, isLoading, mutate, isValidating, size, setSize} = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (!userSession?.accessToken?.jwtToken || !user?.companyId) {
        return null;
      }
      if (previousPageData && !previousPageData.logbooks?.length) {
        return null;
      }
      return [
        `${API_ROUTE}/logbooks?companyId=${user?.companyId}&page=${pageIndex + 1}&size=2`,
        userSession?.accessToken?.jwtToken,
        pageIndex,
      ];
    },
    ([url, token]) => fetcherWithToken(url, token)
  );

  return {
    data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
    size,
    setSize,
  };
}

export default useLogbooks;
