import PropTypes from 'prop-types';
import Field from '../Field/Field';
import './Group.scss';
import useAnswers from '../../hooks/useAnswers';
import {useOutletContext} from 'react-router-dom';

export default function Group({title, groupTitle, fields, register, groupIndex, formValues}) {
  const {user} = useOutletContext();
  const {answers} = useAnswers(user);

  return (
    <div className="group row mb-1">
      {groupTitle && <h2 className="group-title">{groupTitle}</h2>}
      {title && <p className="fs-5">{title}</p>}
      {fields.map((field, fieldIndex) => (
        <Field
          field={field}
          defaultValue={answers?.[field?.name]}
          register={register}
          groupIndex={groupIndex}
          nextField={fields[fieldIndex + 1] || null}
          fieldIndex={fieldIndex}
          formValues={formValues}
          key={`field-${groupIndex}-${fieldIndex}`}
        />
      ))}
    </div>
  );
}

Group.propTypes = {
  title: PropTypes.string,
  groupTitle: PropTypes.string,
  fields: PropTypes.array,
  register: PropTypes.func,
  groupIndex: PropTypes.number,
  formValues: PropTypes.object,
};
