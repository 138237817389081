import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

export default function RadioInput({
  name,
  option,
  onChange,
  defaultChecked,
  className,
  isValid,
  isDirty,
  optionIndex,
  disabled,
  isDisabledByDependency,
  isHiddenByDependency,
}) {
  const radioInputRef = useRef(null);
  const [prevIsDirty, setPrevIsDirty] = useState(() => isDirty);

  useEffect(() => {
    if (isHiddenByDependency || isDisabledByDependency) {
      if (radioInputRef?.current) {
        radioInputRef.current.checked = false;
        onChange(null);
      }
    }
  }, [onChange, isHiddenByDependency, isDisabledByDependency]);

  if (prevIsDirty !== isDirty) {
    setPrevIsDirty(isDirty);

    if (!isValid && optionIndex === 0) {
      radioInputRef.current.focus();
    }
  }

  return (
    <div className="form-check form-check-inline" id={`${name}`} key={`${name}-${option.id}`}>
      <input
        className={`form-check-input me-2 ps-1 ${className}`}
        type="radio"
        name={name}
        ref={radioInputRef}
        onChange={() => radioInputRef.current.checked && onChange(Number(option.id))}
        defaultChecked={defaultChecked}
        id={`${name}${option.id}`}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={`${name}${option.id}`}>
        {option.value}
      </label>
    </div>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string,
  option: PropTypes.object,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  isHiddenByDependency: PropTypes.bool,
  isDisabledByDependency: PropTypes.bool,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  isDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  optionIndex: PropTypes.number,
};
