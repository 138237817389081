export default function classNames(...args) {
  if (!args || !args.length) {
    return {};
  }

  const className = args
    .filter((className) => Boolean(className))
    .reduce((classNamesAcc, className) => classNamesAcc.concat(className), [])
    .join(' ');

  return className ? {className} : {};
}
