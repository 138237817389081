import useSaveLogbookObservationAccept from '@/hooks/useSaveLogbookObservationAccept';
import PropTypes from 'prop-types';
import './ReviewGeneralObservation.scss';
import classNames from '@/helpers/classNames';

function ReviewGeneralObservation({
  id,
  message,
  creationDate,
  acceptedDate,
  index,
  assessmentId,
  isLast,
  mutate,
}) {
  const {trigger} = useSaveLogbookObservationAccept(assessmentId, id);

  const handleAccept = () => {
    trigger().then(() => mutate());
  };

  return (
    <div
      className="section-content mt-1 review-general-observation"
      key={`generalObservation.${index}`}
    >
      <div
        {...classNames(
          'section-row-content row',
          !assessmentId && 'mb-4',
          index === 0 ? 'mt-2' : 'mt-3'
        )}
      >
        <div className="col-9">
          <div className="section-row-title">
            <div
              {...classNames(
                'section-row-name',
                !isLast && 'reply-alternative',
                !assessmentId && 'fw-semibold'
              )}
            >
              Nota {index + 1}
            </div>
          </div>
          <p className="reply">{message}</p>
        </div>
        <div className="col-3 observation-dates">
          <div className="d-flex justify-content-between flex-wrap mb-2">
            <div className="fw-semibold reply-alternative">Enviado:</div>
            <div className="observation-date date reply">{creationDate} hrs</div>
          </div>
          {assessmentId && mutate ? (
            <>
              {acceptedDate ? (
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="fw-semibold accentuate">Aceptado:</div>
                  <div className="observation-date date reply">{acceptedDate} hrs</div>
                </div>
              ) : (
                <div className="row mt-2">
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-warning rounded-pill px-4 lh-1"
                      onClick={handleAccept}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-between flex-wrap">
              <div {...classNames('fw-semibold', acceptedDate ? 'accentuate' : 'reply')}>
                Aceptado:
              </div>
              {acceptedDate && (
                <div className="observation-date date reply">{acceptedDate} hrs</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ReviewGeneralObservation.propTypes = {
  id: PropTypes.number,
  assessmentId: PropTypes.number,
  message: PropTypes.string,
  creationDate: PropTypes.string,
  acceptedDate: PropTypes.string,
  index: PropTypes.number,
  mutate: PropTypes.func,
  isLast: PropTypes.bool,
};

export default ReviewGeneralObservation;
