import useSWR from 'swr';
import {useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '@/helpers/fetcherWithToken';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/logbooks/';

function useSummary({logbookId}, options = {}) {
  const {userSession} = useRouteLoaderData('root') || {};
  const {data, error, isLoading, mutate, isValidating} = useSWR(
    () =>
      logbookId && userSession?.accessToken?.jwtToken
        ? [`${API_ROUTE}${logbookId}/summary`, userSession?.accessToken?.jwtToken]
        : null,
    ([url, token]) => fetcherWithToken(url, token),
    options
  );

  return {
    summary: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export default useSummary;
