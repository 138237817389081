import useJobReferenceTextGenerator from '@/hooks/useJobReferenceTextGenerator';
import PropTypes from 'prop-types';

function JobReferencesGeneratedTextField({logbookId, jobReferenceIndex, onChange, value}) {
  const {trigger, generatedText} = useJobReferenceTextGenerator({logbookId, jobReferenceIndex});
  const handleGenerateText = async () => {
    const triggerResult = await trigger();

    onChange(triggerResult.outputText);
  };
  return (
    <>
      <div className="d-flex justify-content-center controls mt-0 mb-5">
        <button
          className="btn btn-warning action"
          type="button"
          onClick={() => handleGenerateText()}
        >
          Generar Texto
        </button>
      </div>
      <div className="generated-text mb-5">
        <div className="reply">{generatedText?.outputText || value}</div>
      </div>
    </>
  );
}

JobReferencesGeneratedTextField.propTypes = {
  logbookId: PropTypes.string,
  jobReferenceIndex: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default JobReferencesGeneratedTextField;
