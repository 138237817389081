import useSWRMutation from 'swr/mutation';
import {useRouteLoaderData} from 'react-router-dom';
import {getUserSession} from '@/helpers/userHelper';
import {useState} from 'react';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/assessments';

async function sendRequest([url, token]) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: '{}',
  }).then(async (res) => {
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return res.ok;
  });
}

function useSaveLogbookObservationAccept(assessmentId, messageId) {
  const {userSession} = useRouteLoaderData('root') || {};
  const [session, setSession] = useState(() => userSession);
  const {trigger, error, isMutating, reset} = useSWRMutation(
    [
      `${API_ROUTE}/${assessmentId}/logbook/observations/${messageId}/accepted`,
      session?.accessToken?.jwtToken,
    ],
    sendRequest,
    {
      shouldRetryOnError: true,
      onErrorRetry: async (error, key, config, revalidate, {retryCount}) => {
        // Only retry up to 2 times.
        if (retryCount >= 2) return;

        const newSession = await getUserSession();
        setSession(newSession);
        revalidate();
      },
    }
  );

  return {
    trigger,
    isMutating,
    isError: error,
    reset,
  };
}

export default useSaveLogbookObservationAccept;
