import 'dayjs/locale/es';
import daysjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizationConst from '../constants/localizationConst';
import dateConst from '../constants/dateConst';

daysjs.extend(updateLocale);
daysjs.updateLocale(localizationConst.es, {
  months: dateConst.monthsES,
});
