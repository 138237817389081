import useSWR from 'swr';
import fetcher from '../helpers/fetcher';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0';

function useMedia(userId, questionnaireId, questionnaireModuleId) {
  const {data, error, isLoading, mutate, isValidating} = useSWR(
    () =>
      userId && questionnaireId && questionnaireModuleId
        ? `${API_ROUTE}/questionnaires/${questionnaireId}/modules/${questionnaireModuleId}/documents?applicantId=${userId}`
        : null,
    fetcher
  );

  return {
    media: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export default useMedia;
