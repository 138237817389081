/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigate, useParams} from 'react-router-dom';
import useQuestionnaireModule from '../../../hooks/useQuestionnaireModule';
import './QuestionnaireModule.scss';
import Group from '../../../components/Group/Group';
import ListGroup from '../../../components/ListGroup/ListGroup';
import useAnswers from '../../../hooks/useAnswers';
import {useEffect, useRef, useState} from 'react';
import useDynamicForm from '../../../hooks/useDynamicForm';
import {Modal, Toast, ToastContainer} from 'react-bootstrap';
import {useOutletContext} from 'react-router-dom';
import LoadingQuestionnaireModule from '../../../components/LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import useSaveAnswers from '../useSaveAnswers/useSaveAnswers';
import routesConst from '../../../constants/routesConst';
import studiesConst from '../../../constants/studiesConst';

export default function QuestionnaireModule() {
  let {questionnaireModuleId} = useParams();
  const {setHasModuleSaved, user} = useOutletContext();
  const {questionnaireModule, isLoading, isError} = useQuestionnaireModule(
    user.questionnaireId,
    questionnaireModuleId
  );
  const {answers, isAnswersLoading, isAnswersError} = useAnswers(user);
  const [isDeveloperModeOn, setIsDeveloperModeOn] = useState(false);
  const formRef = useRef(null);
  const {register, validate, formValues, unregister, unregisterMultiple} = useDynamicForm();
  const [isConfirmModalShowing, setIsConfirmModalShowing] = useState(false);
  const [isToastShowing, setIsToastShowing] = useState(false);
  const navigate = useNavigate();
  const {
    trigger: triggerSaveAnswers,
    isError: isSaveError,
    reset: resetSaveAnswers,
  } = useSaveAnswers(user);

  useEffect(() => {
    setHasModuleSaved(false);
  }, []);

  const handleFinishModule = async () => {
    const validationResult = validate();
    setIsConfirmModalShowing(false);

    if (!validationResult.isValid) {
      return;
    }
    setIsToastShowing(true);
    const isSavedOk = await triggerSaveAnswers(
      JSON.stringify({...formValues, moduleStatus: studiesConst.moduleStatus.completed})
    );
    if (isSavedOk) {
      handleAfterSave();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationResult = validate();
    if (!validationResult.isValid) {
      return;
    }
    setIsToastShowing(true);
    const isSavedOk = await triggerSaveAnswers(
      JSON.stringify({...formValues, moduleStatus: studiesConst.moduleStatus.inProgress})
    );
    if (isSavedOk) {
      handleAfterSave();
    }
  };

  const handleAfterSave = () => {
    setIsToastShowing(false);
    resetSaveAnswers();
    if (!isSaveError) {
      setHasModuleSaved(true);
      navigate(routesConst.candidateHome);
    }
  };

  return !isLoading &&
    !isError &&
    !isAnswersLoading &&
    !isAnswersError &&
    questionnaireModule?.groups &&
    answers ? (
    <div className="questionnaire-module mb-3 mx-4">
      <h2 className="mb-2 mx-1 module-name fs-1">{questionnaireModule.name}</h2>
      <div
        className="mb-4 mx-1 module-description"
        dangerouslySetInnerHTML={{__html: questionnaireModule.description}}
      />
      <form onSubmit={handleSubmit} ref={formRef}>
        {questionnaireModule.groups.map((group, groupIndex) =>
          group.isModal ? (
            <ListGroup
              title={group.title}
              groupTitle={group.groupTitle}
              fields={group.fields}
              buttonLabel={group.buttonLabel}
              name={group.name}
              key={`list-group-${groupIndex}`}
              register={register}
              unregister={unregister}
              unregisterMultiple={unregisterMultiple}
              formValues={formValues}
              dependsOn={group.dependsOn}
            />
          ) : (
            <Group
              title={group.title}
              groupTitle={group.groupTitle}
              fields={group.fields}
              register={register}
              groupIndex={groupIndex}
              key={`group-${groupIndex}`}
              formValues={formValues}
            />
          )
        )}
        <div className="d-flex flex-row-reverse justify-content-around save-module-controls">
          <button
            className="btn btn-warning mt-2 fs-6 fw-semibold rounded-pill"
            type="button"
            onClick={() => setIsConfirmModalShowing(true)}
            disabled={isToastShowing}
          >
            Finalizar
          </button>
          <button
            className="btn btn-light mt-2 me-2 fs-6 fw-semibold rounded-pill"
            type="submit"
            disabled={isToastShowing}
          >
            Guardar avance
          </button>
        </div>
      </form>
      <Modal show={isConfirmModalShowing} onHide={() => setIsConfirmModalShowing(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 d-flex flex-column">
            <p>
              ¿Estás seguro de que quieres finalizar el modulo? Ya no podrás realizar modificaciones
            </p>
            <button className="btn btn-success mx-5" onClick={handleFinishModule}>
              Aceptar
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={() => setIsConfirmModalShowing(false)}>
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer className="m-4" position="top-center">
        <Toast
          className={`text-bg-${isSaveError ? 'danger' : 'warning'}`}
          show={isToastShowing}
          onClose={handleAfterSave}
          autohide
          delay={3000}
        >
          <Toast.Body>
            {!isSaveError && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {isSaveError
              ? 'Error al guardar el modulo, verifica los campos e intenta de nuevo en unos segundos...'
              : ' Guardando modulo, por favor no cierre el navegador o la pestaña...'}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {isToastShowing && !isSaveError && <div className="modal-backdrop show"></div>}
      {import.meta.env.DEV && (
        <>
          <button
            className="btn btn-warning developer-mode-btn m-1"
            onClick={() => setIsDeveloperModeOn(!isDeveloperModeOn)}
          >
            <i className="bi bi-code-slash"></i>
          </button>
          {isDeveloperModeOn && (
            <div className="developer-mode-console card m-1">
              <div className="card-body">
                <div className="card-title">
                  Developer Console <i className="bi bi-code-slash"></i>
                </div>
                <pre>{JSON.stringify(formValues, null, 2)}</pre>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <LoadingQuestionnaireModule />
  );
}
