import routesConst from '../constants/routesConst';
import userConst from '../constants/userConst';
import {redirectToLogin} from '../helpers/navigationHelper';
import {useRouteLoaderData} from 'react-router-dom';
import UnauthorizedPage from '../pages/UnauthorizedPage/UnauthorizedPage';

function useRoleNavigation(role = null) {
  const {userSession, userRole} = useRouteLoaderData('root') || {};

  if (
    !userSession ||
    !userSession.isLoggedIn ||
    !userSession.accessToken?.jwtToken ||
    !userSession.idToken?.payload?.[userConst.role]
  ) {
    redirectToLogin();
  }

  if (userRole !== role) {
    return {
      isUnauthorized: true,
      renderUnauthorized: <UnauthorizedPage goBackUrl={routesConst?.[`${userRole}Home`] || null} />,
    };
  }
  return {
    isUnauthorized: false,
  };
}

export default useRoleNavigation;
