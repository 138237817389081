import useSWR from 'swr';
import fetcher from '../helpers/fetcher';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';
import {SelectedModuleIdContext} from '@/context/SelectedModuleIdContext';
import {useContext} from 'react';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0';

function useServerSelectOptions({optionsBy, linkedFieldResponse} = {}) {
  const {user} = useOutletContext();
  const {questionnaireModuleId} = useParams();
  const [searchParams] = useSearchParams();
  const selectedModuleId = useContext(SelectedModuleIdContext);
  const {data, error, isLoading, isValidating} = useSWR(
    () =>
      optionsBy && linkedFieldResponse
        ? `${API_ROUTE}/questionnaires/${
            user?.questionnaireId || searchParams.get('questionnaireId')
          }/modules/${
            questionnaireModuleId || selectedModuleId
          }/items?identifiedBy=${optionsBy}&fieldValue=${linkedFieldResponse}`
        : null,
    fetcher
  );

  return {
    fetchedOptions: data,
    isLoading,
    isValidating,
    isError: error,
  };
}

export default useServerSelectOptions;
