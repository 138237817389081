/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import './AttachmentInput.scss';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';
import useMediaUpload from '../../hooks/useMediaUpload';
import useMedia from '../../hooks/useMedia';
import {useContext, useEffect, useMemo, useState} from 'react';
import useMediaDelete from '../../hooks/useMediaDelete';
import classNames from '../../helpers/classNames';
import {SelectedModuleIdContext} from '@/context/SelectedModuleIdContext';

export default function AttachmentInput({
  name,
  buttonLabel,
  label,
  messages,
  contentTypes,
  hasPreview,
  previewLabel,
  isDirty,
  isRequiredValid,
  requiredMessage,
  setIsDirty,
  groupIndex,
  captureChange,
  validate,
  isReadOnly = false,
  listGroupSectionId,
  value,
}) {
  let {questionnaireModuleId} = useParams();
  const {user} = useOutletContext();
  const [fileSizeError, setFileSizeError] = useState('');
  const [searchParams] = useSearchParams();
  const selectedModuleId = useContext(SelectedModuleIdContext);
  const {media, mutate, isValidating} = useMedia(
    isReadOnly ? searchParams.get('userId') : user.id,
    isReadOnly ? searchParams.get('questionnaireId') : user.questionnaireId,
    isReadOnly ? selectedModuleId : questionnaireModuleId
  );
  const {trigger: triggerUpload, isMutating} = useMediaUpload(
    user?.id,
    user?.questionnaireId,
    questionnaireModuleId
  );
  const isList = typeof listGroupSectionId === 'number';

  const matchingMedia = useMemo(() => {
    if (media?.length) {
      if (isList) {
        return media.reduce(
          (prev, media) =>
            media.groupId === groupIndex && (isReadOnly ? value === media.id : media.name === name)
              ? media
              : prev,
          {}
        );
      }
      return media.reduce((prev, media) => (media.name === name ? media : prev), {});
    }
    return {};
  }, [media?.length, value]);

  useEffect(() => {
    if (matchingMedia?.id) {
      captureChange(matchingMedia.id);
    }
  }, [matchingMedia?.id, isValidating]);

  const {trigger: triggerDelete, isMutating: isMediaDeleteMutating} = useMediaDelete(
    matchingMedia?.id
  );

  const handleFiles = async (files) => {
    const imageForm = new FormData();
    imageForm.append('file', files[0]);
    imageForm.append('body', JSON.stringify({name, groupId: groupIndex}));

    if ((files[0].size / 15728640).toFixed(1) > 15) {
      setFileSizeError('El archivo es demasiado grande, el límite por archivo es de 15 Megabytes');
      return;
    }

    const imageUploadResult = await triggerUpload(imageForm);
    setFileSizeError('');
    setIsDirty(true);
    await mutate();
    validate(imageUploadResult?.id);
  };

  const handleMediaDelete = async () => {
    await triggerDelete();
    setIsDirty(true);
    await mutate();
    validate(null);
    captureChange(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item) => {
        if (!contentTypes.includes(item.type)) {
          return;
        }
        if (item.kind !== 'file') {
          return;
        }

        handleFiles(event.dataTransfer.files);
      });
    }
  };

  const handleFile = (event) => {
    handleFiles(event.target.files);
  };

  return (
    <div className={`row attachment-input-component ${hasPreview && !isReadOnly ? 'mt-5' : ''}`}>
      <div className={hasPreview ? 'col-md-8 col-xs-12' : 'col-12'}>
        {messages?.map((message, index) => (
          <div
            {...classNames(!isReadOnly && 'mb-2 mt-2 fs-5 fw-light')}
            key={`message-${index}`}
            dangerouslySetInnerHTML={{__html: message}}
          />
        ))}
        {!isReadOnly &&
          (matchingMedia && matchingMedia.url ? (
            <div className="table-container">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td className="media-filename">
                      <p>{matchingMedia.fileName}</p>
                    </td>
                    <td className="media-controls">
                      <a
                        className="btn btn-warning text-color-white open-image-btn rounded-pill"
                        href={matchingMedia.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Ver
                      </a>
                    </td>
                    <td className="media-controls">
                      <button
                        className="btn btn-warning rounded-pill"
                        type="button"
                        onClick={handleMediaDelete}
                      >
                        Eliminar{' '}
                        {isMediaDeleteMutating ? (
                          <span
                            className="spinner-grow spinner-grow-sm media-delete-spinner"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div {...classNames(isDirty && !isRequiredValid && 'is-invalid')}>
              <div
                {...classNames('attachment-input', isDirty && !isRequiredValid && 'is-invalid')}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <label className="upload-file-label">
                  {isMutating ? (
                    <>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{' '}
                      Cargando archivo...
                    </>
                  ) : (
                    label
                  )}
                </label>
                <input
                  id={name}
                  type="file"
                  accept={contentTypes?.join(', ')}
                  className="visually-hidden"
                  onChange={handleFile}
                />
                <label htmlFor={name} className="btn btn-warning image-upload-btn rounded-pill">
                  {buttonLabel}
                </label>
              </div>
            </div>
          ))}
        {isDirty && !isRequiredValid && !isReadOnly && (
          <span className="invalid-feedback">* {requiredMessage}</span>
        )}
        {fileSizeError && <span className="invalid-feedback">* {fileSizeError}</span>}
      </div>
      {hasPreview && !isReadOnly && (
        <>
          <div className="col-md-1" />
          <div className="col-md-3 col-sm-12 d-flex flex-column align-items-center image-preview">
            <div>{previewLabel}</div>
            <div className="image-container">
              {!isMutating && matchingMedia?.url && (
                <img src={matchingMedia.url} className="img-fluid" />
              )}
            </div>
          </div>
        </>
      )}
      {isReadOnly && (
        <div className="readonly-media">
          {matchingMedia?.fileName ? (
            <>
              <div className="filename">{matchingMedia.fileName}</div>
              <a
                className="btn btn-info text-color-white open-image-btn"
                href={matchingMedia.url}
                target="_blank"
                rel="noreferrer"
              >
                Ver
              </a>
            </>
          ) : (
            <div>El candidato no ha enviado el archivo</div>
          )}
        </div>
      )}
    </div>
  );
}

AttachmentInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  messages: PropTypes.array,
  contentTypes: PropTypes.array,
  buttonLabel: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previewLabel: PropTypes.string,
  hasPreview: PropTypes.bool,
  isRequired: PropTypes.bool,
  groupIndex: PropTypes.number,
  isRequiredValid: PropTypes.bool,
  isDirty: PropTypes.bool,
  requiredMessage: PropTypes.string,
  setIsDirty: PropTypes.func,
  captureChange: PropTypes.func,
  validate: PropTypes.func,
  isReadOnly: PropTypes.bool,
  listGroupSectionId: PropTypes.number,
  value: PropTypes.number,
};
