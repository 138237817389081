import {Link} from 'react-router-dom';
import '../../components/Dashboard/Dashboard.scss';
import routesConst from '@/constants/routesConst';
import {useEffect, useMemo, useState} from 'react';
import {DataGrid, esES} from '@mui/x-data-grid';
import useUser from '@/hooks/useUser';
import './AdminHome.scss';
import adminCandidatesConst from '@/constants/adminCandidatesConst';
import useAssessments from '@/hooks/useAssessments';
import StatusLegend from '@/components/StatusLegend/StatusLegend';
import dayjs from 'dayjs';
import studiesConst from '@/constants/studiesConst';
import classNames from '@/helpers/classNames';
import serverRoutesConst from '@/constants/serverRoutesConst';
import {useMediaQuery} from '@uidotdev/usehooks';
import mediaQueryConst from '@/constants/mediaQueryConst';
import ApplicantField from '@/components/ApplicantField/ApplicantField';
import ApplicantFieldMobile from '@/components/ApplicantFieldMobile/ApplicantFieldMobile';
import AssessmentStatusBadge from '@/components/AssessmentStatusBadge/AssessmentStatusBadge';

export default function AdminHome() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [visibleColumns, setVisibleColumns] = useState({
    mobileApplicantName: false,
  });
  const {user} = useUser();
  const [sortModel, setSortModel] = useState([]);
  const {assessments, isLoading, totalCount} = useAssessments({
    companyId: user.companyId,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    search,
    sort: {
      column: sortModel?.[0]?.field && adminCandidatesConst.columns[sortModel?.[0]?.field],
      direction: sortModel?.[0]?.sort === 'asc' ? '+' : '-',
    },
  });

  const isMediumDevice = useMediaQuery(mediaQueryConst.MD);
  const isSmallDevice = useMediaQuery(mediaQueryConst.SM);

  useEffect(() => {
    if (isMediumDevice) {
      setVisibleColumns({
        mobileApplicantName: true,
        applicantName: false,
        buttonControls: false,
        analysisEndDate: true,
      });
    } else if (isSmallDevice) {
      setVisibleColumns({
        mobileApplicantName: true,
        applicantName: false,
        buttonControls: false,
        analysisEndDate: false,
      });
    } else {
      setVisibleColumns({
        mobileApplicantName: false,
        applicantName: true,
        buttonControls: true,
        analysisEndDate: true,
      });
    }
  }, [isMediumDevice, isSmallDevice]);

  const candidatesRows = useMemo(() => {
    if (assessments) {
      return assessments.map((assessment) => ({
        ...assessment,
        id: assessment.assessmentId,
        buttonControls: {
          executiveReport: assessment.executiveReport ?? false,
          detailedReport: assessment.detailedReport ?? false,
        },
      }));
    }

    return [];
  }, [assessments]);

  const getReportName = (reportType, isMobile) => {
    switch (reportType) {
      case studiesConst.reportType.executive:
        return isMobile ? 'Ejecutivo' : 'Resumen Ejecutivo';
      case studiesConst.reportType.detailed:
        return isMobile ? 'General' : 'Reporte General';
    }
  };

  const reportField = ({assessmentId, reportType, isActive, className, isMobile} = {}) => {
    return (
      <Link
        {...classNames(
          'btn btn-info report-btn',
          studiesConst.reportName[reportType],
          !isActive && 'disabled',
          className
        )}
        to={
          isActive
            ? `${serverRoutesConst.API}/assessments/${assessmentId}/download?type=${reportType}`
            : null
        }
        target="_blank"
      >
        {getReportName(reportType, isMobile)}
      </Link>
    );
  };

  const reportFields = ({
    report,
    assessmentId,
    assessmentModules,
    applicantId,
    isMobile = false,
  } = {}) => {
    if (
      assessmentModules.every(
        (assessmentModule) => assessmentModule.moduleStatus === studiesConst.moduleStatus.noStatus
      )
    ) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Link
            to={`${routesConst.adminHome}/candidato/${applicantId}`}
            {...classNames('btn btn-info modify-candidate-button', isMobile && 'mt-2 ms-4')}
          >
            Modificar
          </Link>
        </div>
      );
    }

    if (!report.executiveReport && !report.detailedReport) {
      return null;
    }

    return (
      <div {...classNames('d-flex justify-content-center w-100', isMobile ? 'mt-2' : 'me-3')}>
        {reportField({
          reportType: studiesConst.reportType.executive,
          isActive: report.executiveReport,
          assessmentId,
          className: isMobile ? 'me-1' : 'me-3',
          isMobile,
        })}
        {reportField({
          reportType: studiesConst.reportType.detailed,
          isActive: report.detailedReport,
          assessmentId,
          isMobile,
        })}
      </div>
    );
  };

  const columns = [
    {
      field: 'assessmentStatus',
      headerName: 'Estado',
      minWidth: 30,
      maxWidth: 80,
      flex: 1,
      sortable: false,
      align: 'center',
      renderCell: (params) => <AssessmentStatusBadge status={params.value} />,
    },
    {
      field: 'assessmentId',
      headerName: isMediumDevice || isSmallDevice ? 'ID' : 'Identificación',
      flex: 1,
      minWidth: 30,
      maxWidth: 150,
      align: 'center',
    },
    {
      field: 'applicantName',
      headerName: 'Candidato',
      flex: 1,
      minWidth: 280,
      maxWidth: 410,
      renderCell: (params) => (
        <ApplicantField
          name={params.value}
          modules={params.row.modules}
          assessmentId={params.row.id}
        />
      ),
    },
    {
      field: 'mobileApplicantName',
      headerName: 'Candidato',
      flex: 1,
      minWidth: 252,
      renderCell: (params) => (
        <ApplicantFieldMobile name={params.row.applicantName} modules={params.row.modules}>
          {reportFields({
            report: params.row.buttonControls,
            assessmentId: params.row.assessmentId,
            assessmentModules: params.row.modules,
            applicantId: params.row.applicantId,
            isMobile: true,
          })}
        </ApplicantFieldMobile>
      ),
    },
    {
      field: 'analysisEndDate',
      headerName: 'Fecha de revisión',
      width: 180,
      renderCell: (params) => <>{params.value && dayjs(params.value).format('DD-MM-YYYY')}</>,
    },
    {
      field: 'buttonControls',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 350,
      align: 'center',
      renderCell: (params) =>
        reportFields({
          report: params.value,
          assessmentId: params.row.assessmentId,
          assessmentModules: params.row.modules,
          applicantId: params.row.applicantId,
        }),
    },
  ];

  return (
    <main className="container admin-candidates-page">
      <div className="dashboard candidates-page">
        <div className="name">Listado de Candidatos</div>
        <div className="row">
          <div {...classNames('col-12', isMediumDevice || isSmallDevice ? 'mt-3' : 'mt-5 mb-2')}>
            <StatusLegend
              legends={[
                {color: 'white', message: 'El candidato aún no responde'},
                {color: 'gray', message: 'El candidato aún no termina'},
                {color: 'blue', message: 'En revisión'},
                {color: 'mint', message: 'Revisado'},
              ]}
            />
          </div>
          <div className="col-12 mb-2">
            <StatusLegend
              legends={[
                {color: 'green', message: 'Apto para la posición a contratar'},
                {color: 'yellow', message: 'Parcialmente apto para la posición a contratar'},
                {color: 'red', message: 'Rechazado para la posición a contratar'},
              ]}
              shrink
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <Link
              to={routesConst.adminLogbooks}
              className="btn btn-warning admin-btn-link logbook-button me-3 mb-3 fw-semibold"
            >
              Avances
            </Link>
          </div>
          <div className="col-12 mb-4 pb-2 mt-2">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="d-flex search-container">
                <input
                  type="text"
                  className="form-control me-3 search-input"
                  id="searchInput"
                  name="searchInput"
                  placeholder="Buscar candidato"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && setSearch(searchInput)}
                ></input>
                <button
                  type="button"
                  className="btn btn-warning search-button"
                  onClick={() => setSearch(searchInput)}
                >
                  Buscar
                </button>
              </div>
              <div className="pagination-filter d-flex align-items-center">
                <div className="me-2">Mostrar</div>
                <select
                  onChange={(e) =>
                    setPaginationModel({
                      ...paginationModel,
                      pageSize: Number(e.target.value),
                    })
                  }
                  className="form-control form-select page-size-select"
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <DataGrid
              rows={candidatesRows}
              columns={columns}
              columnVisibilityModel={visibleColumns}
              paginationModel={paginationModel}
              paginationMode="server"
              pageSizeOptions={[10, 50, 100]}
              onPaginationModelChange={setPaginationModel}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              loading={isLoading}
              rowCount={totalCount || 0}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              rowHeight={111}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end mt-4">
            <Link
              to={routesConst.adminNewCandidate}
              className="btn btn-warning admin-btn-link add-candidate-button fw-semibold"
            >
              Añadir Candidato
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
