import useSWR from 'swr';
import {useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '@/helpers/fetcherWithToken';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/applicants';

function useCandidate({candidateId}, options = {}) {
  const {userSession} = useRouteLoaderData('root') || {};
  const {data, error, isLoading, mutate, isValidating} = useSWR(
    () =>
      candidateId ? [`${API_ROUTE}/${candidateId}`, userSession?.accessToken?.jwtToken] : null,
    ([url, token]) => fetcherWithToken(url, token),
    options
  );

  return {
    candidate: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export default useCandidate;
