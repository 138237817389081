import useSWRMutation from 'swr/mutation';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/assessments';

async function sendSaveRequest(url, {arg}) {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: arg,
  }).then(async (res) => {
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return res.ok;
  });
}

function useSaveAssessmentModule({assessmentId, moduleId}) {
  const {trigger, error, isMutating, reset} = useSWRMutation(
    `${API_ROUTE}/${assessmentId}/modules/${moduleId}`,
    sendSaveRequest
  );

  return {
    trigger,
    isMutating,
    isError: error,
    reset,
  };
}

export default useSaveAssessmentModule;
