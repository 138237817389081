import CandidateForm from '@/components/CandidateForm/CandidateForm';
import '../../components/Dashboard/Dashboard.scss';
import './AdminNewCandidatePage.scss';

export default function AdminNewCandidatePage() {
  return (
    <main className="container admin-new-candidate-page">
      <div className="dashboard candidates-page">
        <div className="name">Ingreso de Nuevo Candidato</div>
        <div>
          Campos con <span className="text-danger">*</span> son requeridos.
        </div>
        <CandidateForm />
      </div>
    </main>
  );
}
