import './Footer.scss';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="d-flex justify-content-between footer-inner">
        <div className="column">
          <h5 className="mb-0">Ethics Data Analytics © 2024</h5>
          <span>Todos los derechos reservados</span>
        </div>
      </div>
    </footer>
  );
}
