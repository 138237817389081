import {useEffect, useMemo} from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import './CalculatedField.scss';
import classNames from '@/helpers/classNames';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export default function CalculatedField({
  format,
  params,
  label,
  formValues,
  listGroupName,
  groupSectionIndex,
  isListGroup,
  handleOnChange,
  isReadOnly,
  isDisabledByDependency,
  isStudy,
  savedValue,
}) {
  const isDisabled = isReadOnly || isDisabledByDependency;
  const bodyMassIndex = ({weight, height} = {}) => {
    if (!weight || !height) {
      return null;
    }
    const heightInMeters = height / 100;
    const heightSquared = heightInMeters * heightInMeters;

    return Number(weight / heightSquared).toFixed(1);
  };
  const dateRange = ({start, end} = {}) => {
    const dateDuration = dayjs.duration(dayjs(end).diff(dayjs(start)));
    const years = dateDuration.years();
    const months = dateDuration.months();
    const days = dateDuration.days();
    const daysUnit = days === 1 ? 'día' : 'días';
    const monthsUnit = months === 1 ? 'mes' : 'meses';
    const yearsUnit = years === 1 ? 'año' : 'años';
    const yearsFormat = years ? `Y [${yearsUnit}] ` : '';
    const monthsFormat = months ? `M [${monthsUnit}] ` : '';
    const daysFormat = days ? `D [${daysUnit}]` : '';

    return dateDuration.format(`${yearsFormat} ${monthsFormat} ${daysFormat}`);
  };
  const getStringDigits = (string) => string?.replace(/[^0-9]/g, '');
  const getFormValue = (value) => {
    const listGroupFormValues = formValues?.[listGroupName]?.[groupSectionIndex];

    if (!value) {
      return null;
    }

    return isListGroup ? listGroupFormValues?.[value] : formValues?.[value];
  };
  const calculatedValue = useMemo(() => {
    if (isDisabledByDependency) {
      return null;
    }

    if (isStudy) {
      return savedValue;
    }

    switch (format) {
      case 'bmi':
        return bodyMassIndex({
          weight: getStringDigits(getFormValue(params?.weight)),
          height: getStringDigits(getFormValue(params?.height)),
        });
      case 'dateRange':
        return dateRange({start: getFormValue(params.start), end: getFormValue(params.end)});
    }

    return null;
  }, [
    getFormValue(params?.weight),
    getFormValue(params?.height),
    getFormValue(params?.start),
    getFormValue(params?.end),
    groupSectionIndex,
    listGroupName,
    isListGroup,
    isDisabledByDependency,
  ]);

  useEffect(() => {
    if (calculatedValue) {
      handleOnChange(calculatedValue);
    }
  }, [calculatedValue]);

  return (
    <div className="calculated-field">
      <span {...classNames('d-inline-block form-label', isDisabled && !isStudy && 'disabled')}>
        {label}
      </span>
      <span {...classNames('d-block form-control', isDisabled && 'disabled')}>
        {calculatedValue}
      </span>
    </div>
  );
}
