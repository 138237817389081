import routesConst from '@/constants/routesConst';
import studiesConst from '@/constants/studiesConst';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default function ApplicantField({name, modules, assessmentId}) {
  return (
    <div className="flex candidate-field mb-1">
      <Link
        className="fw-semibold candidate-name-field"
        to={`${routesConst.adminLogbooks}/${assessmentId}`}
      >
        {name}
      </Link>
      <div className="d-flex mt-2 mb-4 candidate-legend-field">
        <div className="me-4 d-flex align-items-center">
          <span className="ellipse figure--white" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.noStatus || !module.moduleStatus
                ? total + 1
                : total,
            0
          )}
        </div>
        <div className="me-4 d-flex align-items-center">
          <span className="ellipse figure--gray" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.inProgress ? total + 1 : total,
            0
          )}
        </div>
        <div className="me-4 d-flex align-items-center">
          <span className="ellipse figure--blue" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.completed ||
              module.moduleStatus === studiesConst.moduleStatus.inReview
                ? total + 1
                : total,
            0
          )}
        </div>
        <div className="me-4 d-flex align-items-center">
          <span className="ellipse figure--mint" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.accepted ||
              module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted ||
              module.moduleStatus === studiesConst.moduleStatus.rejected
                ? total + 1
                : total,
            0
          )}
        </div>
      </div>
    </div>
  );
}

ApplicantField.propTypes = {
  name: PropTypes.string,
  modules: PropTypes.array,
  assessmentId: PropTypes.number,
};
