/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import validators from '../helpers/validators';

export default function useDynamicFormField({
  name,
  isRequired,
  register,
  setIsDirty,
  defaultValue,
  format,
  type,
  validations,
  fieldRef,
  listGroupName,
  listGroupSectionId,
}) {
  const getIsRequiredValid = (value) => (isRequired ? value === 0 || !!value : true);
  const [isRequiredValid, setIsRequiredValid] = useState(true);
  const [validationMessages, setValidationMessages] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [lastValue, setLastValue] = useState(null);

  const handleRegistration = (value) => {
    setLastValue(value);
    register({
      name,
      value,
      format,
      type,
      field: fieldRef?.current,
      listGroupName,
      listGroupSectionId,
      validate: () => validate(value),
    });
  };

  const validate = (value) => {
    const currentValidationMessages = [];
    let currentIsValid = true;
    let currentIsRequiredValid = true;

    validations?.forEach((validation) => {
      if (!validators[validation.type](validation.constraint, value)) {
        currentIsValid = false;
        setIsValid(false);
        currentValidationMessages.push(validation.message);
      }
    });

    setValidationMessages([...currentValidationMessages]);

    if (!getIsRequiredValid(value)) {
      setIsRequiredValid(false);
      currentIsRequiredValid = false;
    }

    if (!currentIsValid || !currentIsRequiredValid) {
      setIsDirty(true);
    }

    if (currentIsValid) {
      setIsValid(true);
    }

    if (currentIsRequiredValid) {
      setIsRequiredValid(true);
    }

    return currentIsValid && currentIsRequiredValid;
  };

  useEffect(() => {
    handleRegistration(defaultValue);
  }, []);

  return {
    captureChange: handleRegistration,
    isRequiredValid,
    validate,
    validationMessages,
    isValid,
    value: lastValue,
  };
}
