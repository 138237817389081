import useSWR from 'swr';
import fetcher from '../helpers/fetcher';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/questionnaires';

function useQuestionnaire(user) {
  const {data, error, isLoading} = useSWR(
    () => user.questionnaireId && `${API_ROUTE}/${user.questionnaireId}?applicantId=${user.id}`,
    fetcher
  );

  return {
    questionnaire: data?.modules,
    message: data?.message,
    reminder: data?.reminder,
    isLoading,
    isError: error,
  };
}

export default useQuestionnaire;
