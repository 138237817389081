import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {redirectToLogin} from './navigationHelper';
import {CognitoUserPool} from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'us-east-1_Xyh6wP5Iq',
  ClientId: '6bqtn3lmq2dnrp95ccpogrpomm',
};

const userPool = new CognitoUserPool(poolData);

export function login({accessToken, idToken, refreshToken}) {
  const userData = {
    Username: 'current',
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.setSignInUserSession(
    new CognitoUserSession({
      IdToken: new CognitoIdToken({
        IdToken: idToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: accessToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
    })
  );
}

const getSession = (cognitoUser, callback) => {
  cognitoUser.getSession(function (err, session) {
    if (err) {
      alert(err.message || JSON.stringify(err));

      return;
    }

    callback({...session, isLoggedIn: true});
  });
};

const handleExpiredSession = (callback) => {
  signOut(userPool).then(() => redirectToLogin());
  callback({isLoggedIn: false});
};

const handleRefreshToken = (cognitoUser, callback) => {
  cognitoUser.refreshSession(session.getRefreshToken(), (err) => {
    if (err) {
      handleExpiredSession(callback);
    }

    cognitoUser.getUserAttributes(function (err) {
      if (err) {
        handleExpiredSession(callback);
      }

      getSession(cognitoUser, callback);
    });
  });
};

export function getUserSession() {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve) => {
    const accessTokenExpiration = cognitoUser.signInUserSession?.accessToken?.payload?.exp;
    const isAccessTokenExpired = accessTokenExpiration < Math.round(Date.now() / 1000);

    if (cognitoUser === null) {
      resolve({isLoggedIn: false});
    }

    if (isAccessTokenExpired) {
      handleRefreshToken(cognitoUser, resolve);
    }

    getSession(cognitoUser, resolve);
  });
}

export async function signOut() {
  const cognitoUser = userPool.getCurrentUser();

  if (!userPool) {
    return Promise.resolve(true);
  }

  return new Promise((resolve) => {
    if (!cognitoUser) {
      resolve(true);
    }
    cognitoUser.signOut(() => resolve(true));
  });
}
