import {useRef, useState} from 'react';

export default function useDynamicForm() {
  const fieldsRef = useRef({});
  const [form, setForm] = useState({});

  const validate = () => {
    const invalidFields = [];

    Object.keys(fieldsRef.current).forEach((fieldName) => {
      if (!fieldsRef.current[fieldName].validate()) {
        invalidFields.push(fieldName);

        if (invalidFields.length === 1) {
          fieldsRef.current[fieldName]?.field?.focus();
        }
      }
    });

    return {
      isValid: !invalidFields.length,
      ...(invalidFields.length && {invalidFields}),
    };
  };

  const getFieldValue = (field) => {
    if (field.value !== null) {
      return field.value;
    }

    if (field.value === null || typeof field.value === 'undefined') {
      return null;
    }

    if ((field.format === 'radio' || field.format === 'checkbox') && field.value !== null) {
      return Boolean(field.value);
    }

    return null;
  };

  const getFormValues = () => {
    const sectionIdPosition = {};
    const listFormValues = Object.keys(fieldsRef.current).reduce((accFieldValues, fieldName) => {
      if (
        fieldsRef.current[fieldName].type === 'padding' &&
        fieldsRef.current[fieldName].name !== 'id'
      ) {
        return accFieldValues;
      }

      if (fieldsRef.current[fieldName].listGroupName) {
        const listGroupName = fieldsRef.current[fieldName].listGroupName;
        const listGroupSectionId = fieldsRef.current[fieldName].listGroupSectionId;
        const fieldNameWithoutListDetails = fieldsRef.current[fieldName].name;

        if (typeof accFieldValues[listGroupName] === 'object') {
          if (typeof sectionIdPosition[listGroupSectionId] === 'number') {
            accFieldValues[listGroupName][sectionIdPosition[listGroupSectionId]] = {
              ...accFieldValues[listGroupName][sectionIdPosition[listGroupSectionId]],
              [fieldNameWithoutListDetails]: getFieldValue(fieldsRef.current[fieldName]),
            };
          } else {
            sectionIdPosition[listGroupSectionId] = accFieldValues[listGroupName].length;
            accFieldValues[listGroupName].push({
              [fieldNameWithoutListDetails]: getFieldValue(fieldsRef.current[fieldName]),
            });
          }
        } else {
          accFieldValues[listGroupName] = [];
          sectionIdPosition[listGroupSectionId] = 0;
          accFieldValues[listGroupName].push({
            [fieldNameWithoutListDetails]: getFieldValue(fieldsRef.current[fieldName]),
          });
        }

        return accFieldValues;
      }

      accFieldValues[fieldName] = getFieldValue(fieldsRef.current[fieldName]);

      return accFieldValues;
    }, {});

    return listFormValues;
  };

  const formValues = {
    ...getFormValues(),
  };

  const register = (field) => {
    fieldsRef.current = {
      ...fieldsRef.current,
      [`${field.name}${field.listGroupName ? '--' : ''}${
        field.listGroupName ? field.listGroupName : ''
      }${field.listGroupSectionId ? '--' : ''}${
        field.listGroupSectionId ? field.listGroupSectionId : ''
      }`]: field,
    };
    setForm(fieldsRef.current);
  };

  const unregister = (field) => {
    const unregisterFieldName = `${field.name}${field.listGroupName ? '--' : ''}${
      field.listGroupName ? field.listGroupName : ''
    }${field.listGroupSectionId ? '--' : ''}${
      field.listGroupSectionId ? field.listGroupSectionId : ''
    }`;
    const updatedFields = Object.keys(fieldsRef.current)
      .filter((fieldName) => {
        return fieldName !== unregisterFieldName;
      })
      .reduce((fields, fieldName) => ({...fields, [fieldName]: fieldsRef.current[fieldName]}), {});
    fieldsRef.current = updatedFields;
    setForm(updatedFields);
  };

  const unregisterMultiple = (fields) => {
    const unregisterNamesMap = fields.reduce((fieldsNames, field) => {
      const unregisterFieldName = `${field.name}${field.listGroupName ? '--' : ''}${
        field.listGroupName ? field.listGroupName : ''
      }${field.listGroupSectionId ? '--' : ''}${
        field.listGroupSectionId ? field.listGroupSectionId : ''
      }`;
      return {...fieldsNames, [unregisterFieldName]: true};
    }, {});
    const updatedFields = Object.keys(fieldsRef.current)
      .filter((fieldName) => {
        return unregisterNamesMap[fieldName] !== true;
      })
      .reduce((fields, fieldName) => ({...fields, [fieldName]: fieldsRef.current[fieldName]}), {});

    fieldsRef.current = updatedFields;
    setForm(updatedFields);
  };

  const clear = () => {
    setForm({});
    fieldsRef.current = {};
  };

  return {register, validate, form, formValues, clear, unregister, unregisterMultiple};
}
