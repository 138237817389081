import {useRouteLoaderData} from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/applicants';

async function updateRequest([url, token], {arg}) {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: arg,
  }).then(async (res) => {
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      throw error;
    }

    return res.ok;
  });
}

function useUpdateCandidate(applicantId) {
  const {userSession} = useRouteLoaderData('root') || {};
  const {trigger, error, isMutating, reset, data} = useSWRMutation(
    [`${API_ROUTE}/${applicantId}`, userSession?.accessToken?.jwtToken],
    updateRequest
  );

  return {
    trigger,
    isSuccess: data,
    isMutating,
    isError: error,
    reset,
  };
}

export default useUpdateCandidate;
