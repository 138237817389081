import {Controller, useFieldArray} from 'react-hook-form';
import PropTypes from 'prop-types';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import classNames from '@/helpers/classNames';
import dayjs from 'dayjs';

function JobReferenceAttempts({control, index, register, setValue, disabled, errors}) {
  const {fields, append} = useFieldArray({control, name: `jobReferences.${index}.attempts`});

  return (
    <div className="job-reference-attempts mt-3">
      {fields.map((jobReferenceAttemptField, jobReferenceAttemptIndex) => (
        <div key={jobReferenceAttemptField.id} className="row job-reference-attempt">
          <div className="col-5">
            <h6 className="attempt-title fw-semibold">Intento {jobReferenceAttemptIndex + 1}</h6>
            <div className="attempt-date">
              <div className="attempt-date-title">¿Cuándo?</div>
              <div className="row">
                <div className="col-5">
                  <p className="mb-0">Fecha</p>
                  <Controller
                    control={control}
                    name={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDate`}
                    rules={{required: !disabled}}
                    disabled={disabled}
                    defaultValue={null}
                    render={({field}) => (
                      <DatePicker
                        id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDate`}
                        {...classNames(
                          'date-field w-100',
                          errors?.jobReferences?.[index]?.attempts?.[jobReferenceAttemptIndex]
                            ?.contactDate && 'is-invalid'
                        )}
                        value={field.value && dayjs(field.value)}
                        inputRef={field.ref}
                        disabled={disabled}
                        onChange={(value) =>
                          setValue(
                            `jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDate`,
                            dayjs(value),
                            {shouldValidate: true}
                          )
                        }
                        slotProps={{
                          textField: {
                            id: `jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDate`,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-5">
                  <p className="mb-0">Hora</p>
                  <Controller
                    control={control}
                    name={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDateHour`}
                    rules={{required: !disabled}}
                    disabled={disabled}
                    defaultValue={
                      jobReferenceAttemptField.contactDate
                        ? dayjs(jobReferenceAttemptField.contactDate)
                        : null
                    }
                    render={({field}) => (
                      <TimePicker
                        id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDateHour`}
                        {...classNames(
                          'date-field w-100',
                          errors?.jobReferences?.[index]?.attempts?.[jobReferenceAttemptIndex]
                            ?.contactDateHour && 'is-invalid'
                        )}
                        value={field.value && dayjs(field.value)}
                        inputRef={field.ref}
                        disabled={disabled}
                        onChange={(value) =>
                          setValue(
                            `jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDateHour`,
                            dayjs(value),
                            {shouldValidate: true}
                          )
                        }
                        slotProps={{
                          textField: {
                            id: `jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.contactDateHour`,
                          },
                        }}
                        ampm
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="attempt-responded">
              <div className="attempt-response-title">¿Ya respondieron?</div>
              <div className="d-flex">
                <Controller
                  control={control}
                  name={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.replied`}
                  disabled={disabled}
                  render={({field}) => (
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          {...field}
                          checked={field.value === true}
                          onChange={() => field.onChange(true)}
                          className="form-check-input"
                          type="radio"
                          value="true"
                          id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.replied-true`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.replied-true`}
                        >
                          Si
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          {...field}
                          checked={field.value === false}
                          onChange={() => field.onChange(false)}
                          className="form-check-input"
                          type="radio"
                          value="false"
                          id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.replied-false`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.replied-false`}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-2" />
          <div className="col-5">
            <div className="attempt-source">
              <label htmlFor={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.source`}>
                ¿Por qué medio lo contactaste?
              </label>
              <select
                {...register(`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.source`, {
                  disabled,
                  required: !disabled,
                })}
                defaultValue={''}
                {...classNames(
                  'form-select',
                  errors?.jobReferences?.[index]?.attempts?.[jobReferenceAttemptIndex]?.source &&
                    'is-invalid'
                )}
                id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.source`}
                disabled={disabled}
              >
                <option value="" disabled>
                  Seleccione una opción
                </option>
                <option value="SRC-CALL">Llamada telefónica</option>
                <option value="SRC-SMS">SMS</option>
                <option value="SRC-WA">WhatsApp</option>
                <option value="SRC-EMAIL">Correo electrónico</option>
              </select>
            </div>
            <div className="attempt-observations">
              <label
                htmlFor={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.observations`}
              >
                Observaciones
              </label>
              <textarea
                {...register(
                  `jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.observations`
                )}
                id={`jobReferences.${index}.attempts.${jobReferenceAttemptIndex}.observations`}
                className="form-control"
                rows="2"
              />
            </div>
          </div>
          <hr className="mt-2 mb-4" />
        </div>
      ))}
      <div className="attempt-add d-flex justify-content-center">
        <button
          className="btn btn-warning rounded-pill add-element-btn mt-2 mb-3"
          type="button"
          disabled={disabled}
          onClick={() =>
            append({
              contactDate: null,
              replied: null,
              source: '',
              observations: '',
            })
          }
        >
          <i className="bi bi-plus-lg"></i> Agregar Intento
        </button>
      </div>
    </div>
  );
}

JobReferenceAttempts.propTypes = {
  control: PropTypes.object,
  index: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
};

export default JobReferenceAttempts;
