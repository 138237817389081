import studiesConst from '@/constants/studiesConst';
import PropTypes from 'prop-types';

export default function AssessmentStatusBadge({status}) {
  switch (status) {
    case studiesConst.moduleStatus.inProgress:
      return (
        <div className="status-field">
          <span className="ellipse figure--gray" />
        </div>
      );
    case studiesConst.moduleStatus.completed:
    case studiesConst.moduleStatus.inReview:
      return (
        <div className="status-field">
          <span className="ellipse figure--blue" />
        </div>
      );
    case studiesConst.moduleStatus.accepted:
      return (
        <div className="status-field">
          <span className="ellipse figure--green" />
        </div>
      );
    case studiesConst.moduleStatus.partiallyAccepted:
      return (
        <div className="status-field">
          <span className="ellipse figure--yellow" />
        </div>
      );
    case studiesConst.moduleStatus.rejected:
      return (
        <div className="status-field">
          <span className="ellipse figure--red" />
        </div>
      );
    default:
      return <div />;
  }
}

AssessmentStatusBadge.propTypes = {
  status: PropTypes.number,
};
