import classNames from '@/helpers/classNames';
import PropTypes from 'prop-types';

function LoadingQuestionnaireModule({className}) {
  return (
    <div {...classNames('questionnaire-module placeholder-glow px-5', className)}>
      <div className="row">
        <h3 className="placeholder col-5 mb-4"></h3>
        <h1 className="placeholder col-12 mb-5"></h1>
      </div>
      <div className="row d-grid gap-5 grid-loading-2 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-3 my-4 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-2 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-3 my-4 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row">
        <h3 className="placeholder col-5 my-4"></h3>
      </div>
      <div className="row d-grid gap-5 grid-loading-3 my-4 h4">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-2 h5">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-3 my-4 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-2 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
      <div className="row d-grid gap-5 grid-loading-3 my-4 h3">
        <span className="placeholder"></span>
        <span className="placeholder"></span>
        <span className="placeholder"></span>
      </div>
    </div>
  );
}

LoadingQuestionnaireModule.propTypes = {
  className: PropTypes.string,
};

export default LoadingQuestionnaireModule;
