import PropTypes from 'prop-types';
import Field from '../Field/Field';
import {Fragment} from 'react';

export default function StudyListGroup({
  groupTitle,
  groupName,
  fields,
  register,
  groupIndex,
  formValues,
  assessmentModule,
}) {
  return (
    <>
      {assessmentModule &&
        assessmentModule?.[groupName]?.length > 0 &&
        assessmentModule?.[groupName].map((listGroupAnswers, listGroupIndex) => (
          <Fragment key={`groupTitle${listGroupIndex}${groupName}${groupTitle}`}>
            <div className="container px-4 group-title">
              {groupTitle} {Number(listGroupIndex) + 1}
            </div>
            {fields
              .filter(
                (field) =>
                  field.name !== 'id' &&
                  field.type !== 'padding' &&
                  ((field.format === 'checkbox' &&
                    listGroupAnswers?.[field?.name]?.value !== null &&
                    typeof listGroupAnswers?.[field?.name]?.value !== 'undefined') ||
                    field.format !== 'checkbox')
              )
              .map((field, fieldIndex) => (
                <div
                  className="module-row list-group"
                  key={`${fieldIndex}${listGroupIndex}${field.name}row`}
                >
                  <div className="container">
                    <div className="row">
                      <Field
                        field={{
                          ...field,
                          name: `${field?.name}*${groupName}#${listGroupIndex}`,
                          label: `${field?.label}`,
                        }}
                        defaultValue={listGroupAnswers?.[field?.name]?.value ?? undefined}
                        register={register}
                        formValues={formValues}
                        groupIndex={listGroupAnswers?.id?.value || groupIndex}
                        listGroupSectionId={listGroupAnswers?.id?.value}
                        groupSectionIndex={listGroupIndex}
                        fieldIndex={fieldIndex}
                        customSize={5}
                        isReadOnly
                        isStudy
                      />
                      <Field
                        field={{
                          name: `${field?.name}*${groupName}#${listGroupIndex}@analystSelection`,
                          label: 'Evaluación',
                          type: 'array',
                          format: 'radio',
                          isAnalysisField: true,
                          items: [
                            {id: '1', value: 'Aceptado'},
                            {id: '0', value: 'Rechazado'},
                          ],
                          isRequired: true,
                          requiredMessage: 'Este valor es requerido',
                        }}
                        defaultValue={
                          assessmentModule?.[groupName]?.[listGroupIndex]?.[field?.name]
                            ?.analystSelection ?? undefined
                        }
                        register={register}
                        groupIndex={groupIndex}
                        customSize={3}
                        isStudy
                      />
                      <Field
                        field={{
                          name: `${field?.name}*${groupName}#${listGroupIndex}@analystObservations`,
                          label: 'Observaciones',
                          type: 'string',
                          isRequired: true,
                          requiredMessage: 'Este valor es requerido',
                        }}
                        defaultValue={
                          assessmentModule?.[groupName]?.[listGroupIndex]?.[field?.name]
                            ?.analystObservations
                        }
                        register={register}
                        groupIndex={groupIndex}
                        customSize={4}
                        isStudy
                      />
                    </div>
                  </div>
                </div>
              ))}
          </Fragment>
        ))}
    </>
  );
}

StudyListGroup.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  register: PropTypes.func,
  formValues: PropTypes.object,
  assessmentModule: PropTypes.object,
  groupIndex: PropTypes.number,
  groupTitle: PropTypes.string,
  groupName: PropTypes.string,
};
