import useLogbook from '@/hooks/useLogbook';
import PropTypes from 'prop-types';
import LoadingQuestionnaireModule from '../LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import './Logbook.scss';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import JobReferenceAttempts from './JobReferenceAttempts/JobReferenceAttempts';
import useSaveLogbook from '@/hooks/useSaveLogbook';
import studiesConst from '@/constants/studiesConst';
import classNames from '@/helpers/classNames';
import Saving from '../Saving/Saving';
import ReviewGeneralObservation from '../LogbookReview/GeneralObservation/ReviewGeneralObservation';

function Logbook({assessmentId}) {
  const {logbook, isLoading} = useLogbook({assessmentId});
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    formState: {errors},
    handleSubmit,
  } = useForm({
    values: {
      firstContactAttempts: logbook?.firstContact?.attempts?.length
        ? logbook.firstContact.attempts
        : [
            {
              applicantReached: null,
              contactDate: null,
              source: '',
              applicantRespond: null,
              providedInfo: null,
            },
          ],
      documentsObservations: logbook?.documents?.observations
        ? logbook?.documents?.observations
        : '',
      documentsMandatory: logbook?.documents?.missing ? logbook.documents.missing : [],
      jobReferences: logbook?.jobReferences?.length
        ? logbook.jobReferences
        : [
            {
              infoProvided: null,
              reached: null,
              attempts: [
                {
                  contactDate: null,
                  replied: null,
                  source: '',
                  observations: '',
                },
              ],
            },
          ],
      generalObservations: logbook?.generalObservations?.length
        ? logbook.generalObservations
        : [
            {
              message: '',
            },
          ],
    },
  });
  const {trigger, isMutating, isError: isSaveError} = useSaveLogbook(assessmentId);
  const {fields: firstContactAttemptsFields, append: appendFirstContactAttempt} = useFieldArray({
    control,
    name: 'firstContactAttempts',
  });
  const {fields: jobReferencesFields} = useFieldArray({
    control,
    name: 'jobReferences',
  });
  const {fields: generalObservationsFields, append: appendGeneralObservation} = useFieldArray({
    control,
    name: 'generalObservations',
  });

  const firstContactAttempts = watch('firstContactAttempts');
  const jobReferences = watch('jobReferences');
  const generalObservations = watch('generalObservations');

  const firstContactApplicantReached = (index) =>
    Boolean(firstContactAttempts[index].applicantReached);
  const firstContactApplicantRespond = (index) =>
    Boolean(firstContactAttempts[index].applicantRespond);
  const jobReferenceInfoProvided = (index) => Boolean(jobReferences[index].infoProvided);
  const jobReferenceReached = (index) => Boolean(jobReferences[index].reached);
  const isObservationSubmitted = (index) => Boolean(generalObservations[index].creationDate);

  const providedInfoOptions = [
    {value: 'FC-INF-WELCOME', label: 'Bienvenida'},
    {value: 'FC-INF-INSTRUCTIONS', label: 'Instrucciones'},
    {value: 'FC-INF-FOLLOWUP', label: 'Seguimiento'},
    {value: 'FC-INF-VISIT', label: 'Visita'},
  ];

  const handleCheckboxGroupChange = ({value, name} = {}) => {
    const currentValues = getValues(name) || [];
    const newValues = currentValues.includes(value)
      ? currentValues.filter((currentValue) => currentValue !== value)
      : [...currentValues, value];
    setValue(name, newValues);
  };

  const handleMissingDocumentsMandatoryChange = (index) => {
    const currentValues = getValues('documentsMandatory') || [];
    setValue(
      'documentsMandatory',
      currentValues.map((document, documentIndex) => {
        if (index === documentIndex) {
          return {...document, mandatory: !document.mandatory};
        }
        return document;
      })
    );
  };

  const onSubmit = (data) => {
    trigger(
      JSON.stringify({
        id: assessmentId,
        firstContact: {
          attempts: data.firstContactAttempts.map((attempt) => ({
            applicantReached:
              typeof attempt.applicantReached === 'undefined' ? null : attempt.applicantReached,
            applicantRespond:
              typeof attempt.applicantRespond === 'undefined' ? null : attempt.applicantRespond,
            contactDate:
              attempt.contactDate && attempt.contactDateHour
                ? dayjs(
                    dayjs(attempt.contactDate).format('YYYY-MM-DD') +
                      dayjs(attempt.contactDateHour).format('HH:mm:ss')
                  ).format(dateFormat)
                : null,
            providedInfo: attempt.providedInfo || [],
            source: attempt.source || null,
          })),
        },
        documents: {
          missing: data.documentsMandatory,
          observations: data.documentsObservations,
        },
        jobReferences: data.jobReferences.map((jobReference) => ({
          ...jobReference,
          attempts: jobReference.attempts.map((attempt) => ({
            contactDate:
              attempt.contactDate && attempt.contactDateHour
                ? dayjs(
                    dayjs(attempt.contactDate).format('YYYY-MM-DD') +
                      dayjs(attempt.contactDateHour).format('HH:mm:ss')
                  ).format(dateFormat)
                : null,
            replied: typeof attempt.replied === 'undefined' ? null : attempt.replied,
            source: attempt.source || null,
            observations: attempt.observations,
          })),
        })),
        generalObservations: data.generalObservations
          .filter((observation) => observation.message)
          .map((observation) => ({
            ...observation,
            ...(!observation.creationDate && {creationDate: dayjs().format(dateFormat)}),
          })),
      })
    );
  };

  return !isLoading ? (
    <div className="container logbook dashboard">
      <div className="name mb-2">Bitácora</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 outlined-background">
            <div className="general-title">
              <h5 className="fw-semibold mb-3">Información Candidato</h5>
            </div>
            <div className="general-info row">
              <div className="col-6">
                <p className="info-title">ID Candidato</p>
                <p className="reply">{logbook.applicantId}</p>
                <p className="info-title">Nombre completo del candidato</p>
                <p className="reply">{logbook.applicantName}</p>
                <p className="info-title">Teléfono</p>
                <p className="reply">{logbook.phoneNumber ? logbook.phoneNumber : '-'}</p>
              </div>
              <div className="col-4">
                <p className="info-title">Nombre de la empresa</p>
                <p className="reply">{logbook.companyName}</p>
                <p className="info-title">Correo electrónico</p>
                <p className="reply">{logbook.email}</p>
                <p className="info-title">Fecha y Hora de Registro</p>
                <p className="date reply">
                  {logbook.creationDate && `${dayjs(logbook.creationDate).format(dateFormat)} hrs`}
                </p>
              </div>
              <div className="col-2">
                <div className="glance-picture">
                  {logbook.personalPictureFile && (
                    <img src={logbook.personalPictureFile} className="personal-picture" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mt-4">
            <h5 className="fw-semibold mb-3">Primer Contacto</h5>
            <div className="first-contact">
              {firstContactAttemptsFields.map(
                (firstContactAttemptFields, firstContactAttemptIndex) => (
                  <div
                    className="row first-contact-attempt mb-3"
                    key={firstContactAttemptFields.id}
                  >
                    <div className="col-5">
                      <h6 className="attempt-title fw-semibold mb-2">
                        Intento {firstContactAttemptIndex + 1}
                      </h6>
                      <div className="attempt-made mb-2">
                        <div className="first-contact-reached">¿Ya contactaste al candidato?</div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`firstContactAttempts.${firstContactAttemptIndex}.applicantReached`}
                            rules={{validate: (value) => typeof value === 'boolean'}}
                            render={({field}) => (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                        ?.applicantReached && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="true"
                                    id={`firstContactAttempts.${firstContactAttemptIndex}.applicantReached-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`firstContactAttempts.${firstContactAttemptIndex}.applicantReached-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => {
                                      field.onChange(false);
                                      setValue(
                                        `firstContactAttempts.${firstContactAttemptIndex}.applicantRespond`,
                                        null
                                      );
                                    }}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                        ?.applicantReached && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="false"
                                    id={`firstContactAttempts.${firstContactAttemptIndex}.applicantReached-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`firstContactAttempts.${firstContactAttemptIndex}.applicantReached-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="attempt-source mb-2">
                        <div className="row">
                          <div className="col">
                            <label
                              htmlFor={`firstContactAttempts.${firstContactAttemptIndex}.source`}
                            >
                              ¿Por qué medio le contactaste?
                            </label>
                            <select
                              {...register(
                                `firstContactAttempts.${firstContactAttemptIndex}.source`,
                                {
                                  required: firstContactApplicantReached(firstContactAttemptIndex),
                                  disabled: !firstContactApplicantReached(firstContactAttemptIndex),
                                }
                              )}
                              defaultValue={''}
                              id={`firstContactAttempts.${firstContactAttemptIndex}.source`}
                              disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                              {...classNames(
                                'form-select',
                                errors?.firstContactAttempts?.[firstContactAttemptIndex]?.source &&
                                  'is-invalid'
                              )}
                            >
                              <option value="" disabled>
                                Seleccione una opción
                              </option>
                              <option value="SRC-CALL">Llamada telefónica</option>
                              <option value="SRC-SMS">SMS</option>
                              <option value="SRC-WA">WhatsApp</option>
                              <option value="SRC-EMAIL">Correo electrónico</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="attempt-provided-info mb-2">
                        <div className="attempt-provided-info-title">
                          ¿Qué información le compartiste?
                        </div>
                        <Controller
                          control={control}
                          name={`firstContactAttempts.${firstContactAttemptIndex}.providedInfo`}
                          disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                          render={({field}) => (
                            <div className="checkbox-group row g-2 pt-2">
                              {providedInfoOptions.map((providedInfoOption, index) => (
                                <div
                                  className="d-flex justify-content-start col-6"
                                  key={`firstContactAttempt-${firstContactAttemptIndex}-providedInfoOption-${index}`}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`firstContactAttempt-${firstContactAttemptIndex}-providedInfoOption-${providedInfoOption.value}`}
                                    value={providedInfoOption.value}
                                    disabled={
                                      !firstContactApplicantReached(firstContactAttemptIndex)
                                    }
                                    checked={
                                      field.value?.includes(providedInfoOption.value) || false
                                    }
                                    onChange={() => {}}
                                    onClick={() =>
                                      handleCheckboxGroupChange({
                                        value: providedInfoOption.value,
                                        name: `firstContactAttempts.${firstContactAttemptIndex}.providedInfo`,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor={`firstContactAttempt-${firstContactAttemptIndex}-providedInfoOption-${providedInfoOption.value}`}
                                    className="form-check-label"
                                  >
                                    {providedInfoOption.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <div className="attempt-date mb-2">
                        <div className="attempt-date-title">¿Cuándo?</div>
                        <div className="row">
                          <div className="col-5">
                            <p className="mb-0">Fecha</p>
                            <Controller
                              control={control}
                              name={`firstContactAttempts.${firstContactAttemptIndex}.contactDate`}
                              disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                              rules={{
                                required: firstContactApplicantReached(firstContactAttemptIndex),
                              }}
                              defaultValue={null}
                              render={({field}) => (
                                <DatePicker
                                  id={`firstContactAttempts.${firstContactAttemptIndex}.contactDate-value`}
                                  required={firstContactApplicantReached(firstContactAttemptIndex)}
                                  {...classNames(
                                    'date-field w-100',
                                    errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                      ?.contactDate && 'is-invalid'
                                  )}
                                  value={field.value && dayjs(field.value)}
                                  inputRef={field.ref}
                                  onChange={(value) =>
                                    setValue(
                                      `firstContactAttempts.${firstContactAttemptIndex}.contactDate`,
                                      dayjs(value),
                                      {shouldValidate: true}
                                    )
                                  }
                                  disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                                  slotProps={{
                                    textField: {
                                      id: `firstContactAttempts.${firstContactAttemptIndex}.contactDate-value`,
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-5">
                            <p className="mb-0">Hora</p>
                            <Controller
                              control={control}
                              name={`firstContactAttempts.${firstContactAttemptIndex}.contactDateHour`}
                              disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                              rules={{
                                required: firstContactApplicantReached(firstContactAttemptIndex),
                              }}
                              defaultValue={
                                firstContactAttemptFields.contactDate
                                  ? dayjs(firstContactAttemptFields.contactDate)
                                  : null
                              }
                              render={({field}) => (
                                <TimePicker
                                  id={`firstContactAttempts.${firstContactAttemptIndex}.contactDateHour-value`}
                                  required={firstContactApplicantReached(firstContactAttemptIndex)}
                                  {...classNames(
                                    'date-field w-100',
                                    errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                      ?.contactDateHour && 'is-invalid'
                                  )}
                                  value={field.value && dayjs(field.value)}
                                  inputRef={field.ref}
                                  onChange={(value) =>
                                    setValue(
                                      `firstContactAttempts.${firstContactAttemptIndex}.contactDateHour`,
                                      dayjs(value),
                                      {shouldValidate: true}
                                    )
                                  }
                                  disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                                  slotProps={{
                                    textField: {
                                      id: `firstContactAttempts.${firstContactAttemptIndex}.contactDateHour-value`,
                                    },
                                  }}
                                  ampm
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="attempt-responded mb-2">
                        <div className="attempt-response-title">¿Respondió el candidato?</div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`firstContactAttempts.${firstContactAttemptIndex}.applicantRespond`}
                            disabled={!firstContactApplicantReached(firstContactAttemptIndex)}
                            rules={{
                              validate: (value) =>
                                firstContactApplicantReached(firstContactAttemptIndex)
                                  ? typeof value === 'boolean'
                                  : true,
                            }}
                            render={({field}) => (
                              <div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                        ?.applicantRespond && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="true"
                                    id={`firstContactAttempts.${firstContactAttemptIndex}.applicantRespond-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`firstContactAttempts.${firstContactAttemptIndex}.applicantRespond-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => field.onChange(false)}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.firstContactAttempts?.[firstContactAttemptIndex]
                                        ?.applicantRespond && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="false"
                                    id={`firstContactAttempts.${firstContactAttemptIndex}.applicantRespond-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`firstContactAttempts.${firstContactAttemptIndex}.applicantRespond-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="mt-4" />
                  </div>
                )
              )}
              <div className="attempt-add d-flex justify-content-center mb-3">
                <button
                  className="btn btn-warning rounded-pill add-element-btn"
                  type="button"
                  disabled={
                    firstContactApplicantRespond(firstContactAttemptsFields.length - 1) ||
                    !firstContactApplicantReached(firstContactAttemptsFields.length - 1)
                  }
                  onClick={() =>
                    appendFirstContactAttempt(
                      {
                        applicantReached: null,
                        contactDate: null,
                        source: '',
                        applicantRespond: null,
                        providedInfo: null,
                      },
                      {
                        shouldFocus: true,
                        focusName: `firstContactAttempts.${firstContactAttemptsFields.length}.applicantReached`,
                      }
                    )
                  }
                >
                  <i className="bi bi-plus-lg" /> Agregar Intento
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 outlined-background">
            <h5 className="fw-semibold mb-3">Visita Remota</h5>
            <div className="review-section">
              {!logbook.remoteVisit?.attempts.length && <p>-</p>}
              {logbook.remoteVisit.attempts.map((attempt, attemptIndex) => (
                <div className="section-content mt-1" key={`remoteVisit.attempt.${attemptIndex}`}>
                  <div className="section-row-title d-flex">
                    {attempt.occurred ? (
                      <span className="ellipse figure--green" />
                    ) : (
                      <span className="ellipse figure--red" />
                    )}
                    <div className="section-row-name">Intento {attemptIndex + 1}</div>
                  </div>
                  <div className="section-row-content mt-2 row">
                    <div className="col-6">
                      {attempt.scheduled && (
                        <>
                          <p className="info-title">
                            El horario en el que se agendó la visita remota es:
                          </p>
                          <p className="date reply">
                            {attempt.visitDate &&
                              `${dayjs(attempt.visitDate).format('DD/MMM/YYYY\xa0 HH : mm')} hrs`}
                          </p>
                        </>
                      )}
                      {attempt.observations && (
                        <>
                          <p className="info-title">Observaciones</p>
                          <p className="reply">{attempt.observations}</p>
                        </>
                      )}
                    </div>
                    <div className="col-6">
                      <p className="info-title">Estatus</p>

                      {!attempt.scheduled && (
                        <p className="reply">La visita remota no se encuentra agendada </p>
                      )}

                      {attempt.scheduled &&
                        !attempt.occurred &&
                        !logbook.remoteVisit.attempts[attemptIndex + 1] && (
                          <p className="reply">La visita remota se encuentra agendada </p>
                        )}

                      {attempt.occurred && <p className="reply">La visita remota tuvo lugar</p>}

                      {!attempt.occurred && logbook.remoteVisit.attempts[attemptIndex + 1] && (
                        <p className="reply">No ocurrió</p>
                      )}

                      {attempt.duration && (
                        <>
                          <p className="info-title">Duración</p>
                          <p className="reply">{attempt.duration.split(':')[0]} hr {attempt.duration.split(':')[1]} min
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 mt-4 outlined-background">
            <h5 className="fw-semibold">Documentos Faltantes</h5>
            <div className="row missing-documents-section">
              <div className="col-6">
                <div className="row missing-documents">
                  <Controller
                    control={control}
                    name={`documentsMandatory`}
                    render={({field}) => (
                      <div className="checkbox-group row g-2 pt-2">
                        {logbook?.documents?.missing?.map(
                          (missingDocument, missingDocumentIndex) => (
                            <div
                              className="d-flex justify-content-start col-6 mb-2"
                              key={`missingDocument-${missingDocumentIndex}-option`}
                            >
                              <input
                                type="radio"
                                className="form-check-input"
                                id={`missingDocument-${missingDocumentIndex}-radio`}
                                checked={field?.value?.[missingDocumentIndex]?.mandatory || false}
                                onChange={() => {}}
                                onClick={() =>
                                  handleMissingDocumentsMandatoryChange(missingDocumentIndex)
                                }
                              />
                              <label
                                htmlFor={`missingDocument-${missingDocumentIndex}-radio`}
                                className="form-check-label"
                              >
                                {missingDocument.name}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-1" />
              <div className="col-5">
                <div className="missing-documents-observations">
                  <label htmlFor={`missingDocuments.observations`}>Observaciones</label>
                  <textarea
                    {...register(`documentsObservations`)}
                    id={`missingDocuments.observations`}
                    className="form-control"
                    rows="1"
                  />
                </div>
              </div>
            </div>
          </div>
          {jobReferencesFields.map((jobReferenceFields, jobReferenceIndex) => (
            <div className="col-12 mt-4 outlined-background" key={jobReferenceFields.id}>
              <div className="job-references">
                <div className="row job-reference">
                  <div className="col-12 mb-3">
                    <h5 className="job-reference-title fw-semibold mb-3">
                      Referencia Laboral {jobReferenceIndex + 1}
                    </h5>
                    <div className="row">
                      <div className="job-reference-info-provided col-5">
                        <div className="info-provided-title">
                          ¿El candidato ya subió la información a la plataforma?
                        </div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`jobReferences.${jobReferenceIndex}.infoProvided`}
                            render={({field}) => (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    className="form-check-input"
                                    type="radio"
                                    value="true"
                                    id={`jobReferences.${jobReferenceIndex}.infoProvided-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`jobReferences.${jobReferenceIndex}.infoProvided-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => {
                                      field.onChange(false);
                                      setValue(`jobReferences.${jobReferenceIndex}.reached`, null);
                                    }}
                                    className="form-check-input"
                                    type="radio"
                                    value="false"
                                    id={`jobReferences.${jobReferenceIndex}.infoProvided-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`jobReferences.${jobReferenceIndex}.infoProvided-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-2" />
                      <div className="job-reference-reached col-5">
                        <div className="info-reached-title">¿Ya las contactaste?</div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`jobReferences.${jobReferenceIndex}.reached`}
                            disabled={!jobReferenceInfoProvided(jobReferenceIndex)}
                            render={({field}) => (
                              <div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    className="form-check-input"
                                    type="radio"
                                    value="true"
                                    id={`jobReferences.${jobReferenceIndex}.reached-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`jobReferences.${jobReferenceIndex}.reached-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => field.onChange(false)}
                                    className="form-check-input"
                                    type="radio"
                                    value="false"
                                    id={`jobReferences.${jobReferenceIndex}.reached-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`jobReferences.${jobReferenceIndex}.reached-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <JobReferenceAttempts
                    control={control}
                    index={jobReferenceIndex}
                    register={register}
                    setValue={setValue}
                    disabled={
                      !jobReferenceReached(jobReferenceIndex) ||
                      !jobReferenceInfoProvided(jobReferenceIndex)
                    }
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 mt-4 outlined-background">
            <h5 className="fw-semibold">Módulos</h5>
            <div className="row information">
              <div className="col-12">
                <div className="d-flex mt-1 candidate-legend-field">
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--white" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.noStatus ||
                        !module.moduleStatus
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--gray" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.inProgress
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--blue" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.completed ||
                        module.moduleStatus === studiesConst.moduleStatus.inReview
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--green" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.accepted
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--yellow" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="ellipse figure--red" />
                    {logbook?.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.rejected
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 outlined-background">
            <h5 className="fw-semibold">Notas del Especialista</h5>
            <hr />
            <div className="row general-observations">
              {generalObservationsFields.map(
                (generalObservationFields, generalObservationIndex) => (
                  <div
                    className="col-12 general-observation mb-2"
                    key={generalObservationFields.id}
                  >
                    {isObservationSubmitted(generalObservationIndex) ? (
                      <ReviewGeneralObservation
                        message={generalObservations[generalObservationIndex].message}
                        creationDate={dayjs(
                          generalObservations[generalObservationIndex].creationDate
                        ).format(dateFormat)}
                        acceptedDate={
                          generalObservations[generalObservationIndex].acceptedDate &&
                          dayjs(generalObservations[generalObservationIndex].acceptedDate).format(
                            dateFormat
                          )
                        }
                        index={generalObservationIndex}
                      />
                    ) : (
                      <>
                        <label
                          className="fw-semibold mt-2"
                          htmlFor={`generalObservations.${generalObservationIndex}.message`}
                        >
                          Observación General {generalObservationIndex + 1}
                        </label>
                        <div className="observations">
                          <textarea
                            {...register(`generalObservations.${generalObservationIndex}.message`)}
                            id={`generalObservations.${generalObservationIndex}.message`}
                            {...classNames(
                              'form-control mb-4',
                              errors?.generalObservations?.[generalObservationIndex]?.message &&
                                'is-invalid'
                            )}
                            rows="1"
                          />
                        </div>
                        <hr className="mb-1" />
                      </>
                    )}
                  </div>
                )
              )}
              <div className="general-observations-add d-flex justify-content-center mt-4 mb-3">
                <button
                  className="btn btn-warning rounded-pill add-element-btn"
                  type="button"
                  disabled={!isObservationSubmitted(generalObservationsFields.length - 1)}
                  onClick={() =>
                    appendGeneralObservation({
                      message: '',
                    })
                  }
                >
                  <i className="bi bi-plus-lg" /> Agregar Observación
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="submit-button d-flex justify-content-center mt-3 save-controls">
              <button className="btn btn-warning save" type="submit">
                Guardar Bitácora
              </button>
            </div>
          </div>
        </div>
      </form>
      <Saving
        isMutating={isMutating}
        isSaveError={isSaveError}
        savingMessage="Guardando bitácora, por favor no cierre el navegador o la pestaña..."
        errorMessage="Error al guardar la bitácora, verifica los campos e intenta de nuevo en unos segundos..."
        successMessage="¡Bitácora guardada con éxito!"
      />
    </div>
  ) : (
    <div className="container logbook dashboard">
      <LoadingQuestionnaireModule className="mt-3" />
    </div>
  );
}

Logbook.propTypes = {
  assessmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Logbook;
