import classNames from '@/helpers/classNames';
import PropTypes from 'prop-types';
import './StatusLegend.scss';

export default function StatusLegend({legends, shrink = false}) {
  return (
    <div className="d-flex status-legend flex-wrap">
      {legends &&
        legends.map((legend, index) => (
          <div {...classNames('me-4 pb-1 d-flex', shrink && 'shrink')} key={`legend-${index}`}>
            <span {...classNames('ellipse', `figure--${legend.color}`)} />
            <span className="figure-legend">{legend.message}</span>
          </div>
        ))}
    </div>
  );
}

StatusLegend.propTypes = {
  legends: PropTypes.array,
  shrink: PropTypes.bool,
};
