import useSWR from 'swr';
import fetcher from '../helpers/fetcher';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/questionnaires';

function useQuestionnaireModule(questionnaireId, questionnaireModuleId, assessmentId) {
  const {data, error, isLoading} = useSWR(
    () =>
      questionnaireId && questionnaireModuleId
        ? `${API_ROUTE}/${questionnaireId}/modules/${questionnaireModuleId}/${(assessmentId) ? '?assessmentId=' + assessmentId : ''}`
        : null,
    fetcher
  );

  return {
    questionnaireModule: data,
    isLoading,
    isError: error,
  };
}

export default useQuestionnaireModule;
