import studiesConst from '@/constants/studiesConst';
import PropTypes from 'prop-types';
import './LogbookReview.scss';
import dayjs from 'dayjs';
import logoIcon from '/Logo_Icon_EDA.webp';
import ReviewGeneralObservation from './GeneralObservation/ReviewGeneralObservation';

function LogbookReview({logbook, mutate}) {
  const dateFormat = 'DD/MMM/YYYY\xa0 HH : mm';
  const sourceOptions = {
    'SRC-CALL': 'Llamada telefónica',
    'SRC-SMS': 'SMS',
    'SRC-WA': 'WhatsApp',
    'SRC-EMAIL': 'Correo electrónico',
  };
  const providedInfoOptions = {
    'FC-INF-WELCOME': 'Bienvenida',
    'FC-INF-INSTRUCTIONS': 'Primeras instrucciones',
    'FC-INF-FOLLOWUP': 'Seguimiento',
    'FC-INF-VISIT': 'Visita remota',
  };

  if (!logbook?.applicantName) {
    return null;
  }

  return (
    <div className="logbook-review">
      <div className="candidate-glance d-flex justify-content-between">
        <div className="glance-general">
          <div className="glance-name">{logbook.applicantName}</div>
          <div className="glance-modules candidate-legend-field mt-2 mb-4 d-flex">
            <div className="me-4 d-flex align-items-center">
              <span className="ellipse figure--white" />
              {logbook.modules.reduce(
                (total, module) =>
                  module.moduleStatus === studiesConst.moduleStatus.noStatus || !module.moduleStatus
                    ? total + 1
                    : total,
                0
              )}
            </div>
            <div className="me-4 d-flex align-items-center">
              <span className="ellipse figure--gray" />
              {logbook.modules.reduce(
                (total, module) =>
                  module.moduleStatus === studiesConst.moduleStatus.inProgress ? total + 1 : total,
                0
              )}
            </div>
            <div className="me-4 d-flex align-items-center">
              <span className="ellipse figure--blue" />
              {logbook.modules.reduce(
                (total, module) =>
                  module.moduleStatus === studiesConst.moduleStatus.completed ||
                  module.moduleStatus === studiesConst.moduleStatus.inReview
                    ? total + 1
                    : total,
                0
              )}
            </div>
            <div className="me-4 d-flex align-items-center">
              <span className="ellipse figure--mint" />
              {logbook.modules.reduce(
                (total, module) =>
                  module.moduleStatus === studiesConst.moduleStatus.accepted ||
                  module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted ||
                  module.moduleStatus === studiesConst.moduleStatus.rejected
                    ? total + 1
                    : total,
                0
              )}
            </div>
          </div>
        </div>
        <div className="glance-picture">
          {logbook.personalPictureFile && (
            <img src={logbook.personalPictureFile} className="personal-picture" />
          )}
        </div>
      </div>
      <hr />
      <div className="general-info row">
        <div className="col-6">
          <p className="info-title">ID Candidato</p>
          <p className="reply">{logbook.applicantId}</p>
          <p className="info-title">Teléfono</p>
          <p className="reply">{logbook.phoneNumber ? logbook.phoneNumber : '-'}</p>
        </div>
        <div className="col-6">
          <p className="info-title">Fecha y Hora de Registro</p>
          <p className="date reply">
            {logbook.creationDate && `${dayjs(logbook.creationDate).format(dateFormat)} hrs`}
          </p>
          <p className="info-title">Correo electrónico</p>
          <p className="reply">{logbook.email}</p>
        </div>
      </div>
      <div className="review-section">
        <div className="section-header d-flex">
          <div className="section-name">Primer Contacto</div>
          <hr className="w-100 ms-3" />
        </div>
        {!logbook.firstContact?.attempts.length && <p>-</p>}
        {logbook.firstContact.attempts.map((attempt, attemptIndex) => (
          <div className="section-content mt-1" key={`firstContact.attempt.${attemptIndex}`}>
            <div className="section-row-title d-flex">
              {attempt.applicantRespond ? (
                <span className="ellipse figure--green" />
              ) : (
                <span className="ellipse figure--red" />
              )}
              <div className="section-row-name">Intento {attemptIndex + 1}</div>
            </div>
            <div className="section-row-content mt-2 row">
              <div className="col-6">
                {!attempt.applicantReached && <p>El equipo de analistas aún no hace contacto.</p>}
                {attempt.applicantReached && (
                  <>
                    <p className="reply">
                      {attempt.applicantRespond
                        ? 'El equipo de analistas ya tiene contacto con el candidato.'
                        : 'El equipo de analistas no ha obtenido respuesta del candidato.'}
                    </p>
                    <p className="info-title">El intento se hizo a tráves de:</p>
                    <p className="reply">{attempt.source && sourceOptions[attempt.source]}</p>
                  </>
                )}
              </div>
              {attempt.applicantReached && (
                <>
                  <div className="col-6">
                    <p className="info-title">
                      {attempt.applicantRespond ? 'Lo confirmamos el:' : 'Lo intentamos el:'}
                    </p>
                    <p className="date reply">
                      {attempt.contactDate &&
                        `${dayjs(attempt.contactDate).format(dateFormat)} hrs`}
                    </p>
                    {!!attempt.providedInfo?.length && (
                      <p className="info-title">¿Qué información se le proporcionó?</p>
                    )}
                    <div className="row">
                      {!!attempt.providedInfo?.length &&
                        attempt.providedInfo.map((info, infoIndex) => (
                          <div
                            className="col-6 d-flex align-items-center gap-3 mb-2"
                            key={`firstContact.providedInfo.${infoIndex}`}
                          >
                            <img src={logoIcon} alt="logo icon" width="15" height="13" />
                            <p className="reply mb-0">{providedInfoOptions[info]}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="review-section">
        <div className="section-header d-flex">
          <div className="section-name">Visita Remota</div>
          <hr className="w-100 ms-3" />
        </div>
        {!logbook.remoteVisit?.attempts.length && <p>-</p>}
        {logbook.remoteVisit.attempts.map((attempt, attemptIndex) => (
          <div className="section-content mt-1" key={`remoteVisit.attempt.${attemptIndex}`}>
            <div className="section-row-title d-flex">
              {attempt.occurred ? (
                <span className="ellipse figure--green" />
              ) : (
                <span className="ellipse figure--red" />
              )}
              <div className="section-row-name">Intento {attemptIndex + 1}</div>
            </div>
            <div className="section-row-content mt-2 row">
              <div className="col-6">
                {attempt.scheduled && (
                  <>
                    <p className="info-title">
                      El horario en el que se agendó la visita remota es:
                    </p>
                    <p className="date reply">
                      {attempt.visitDate && `${dayjs(attempt.visitDate).format(dateFormat)} hrs`}
                    </p>
                  </>
                )}
                {attempt.observations && (
                  <>
                    <p className="info-title">Observaciones</p>
                    <p className="reply">{attempt.observations}</p>
                  </>
                )}
              </div>
              <div className="col-6">
                <p className="info-title">Estatus</p>

                {!attempt.scheduled && (
                  <p className="reply">La visita remota no se encuentra agendada </p>
                )}

                {attempt.scheduled &&
                  !attempt.occurred &&
                  !logbook.remoteVisit.attempts[attemptIndex + 1] && (
                    <p className="reply">La visita remota se encuentra agendada </p>
                  )}

                {attempt.occurred && <p className="reply">La visita remota tuvo lugar</p>}

                {!attempt.occurred && logbook.remoteVisit.attempts[attemptIndex + 1] && (
                  <p className="reply">No ocurrió</p>
                )}

                {attempt.duration && (
                  <>
                    <p className="info-title">Duración</p>
                    <p className="reply">{attempt.duration.split(':')[0]} hr {attempt.duration.split(':')[1]} min
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="review-section mt-3">
        <div className="section-header d-flex">
          <div className="section-name">Documentos Faltantes</div>
          <hr className="w-100 ms-3" />
        </div>
        <div className="row missing-documents-section">
          <div className="col-6">
            <div className="row missing-documents">
              {!logbook?.documents?.missing?.length && <p>Ninguno</p>}
              {logbook?.documents?.missing?.map(
                (document) =>
                  document.mandatory && (
                    <div className="col-6 pb-2" key={`missing-document.${document.id}`}>
                      <div className="d-flex gap-3 align-items-center">
                        <img src={logoIcon} alt="logo icon" width="15" height="13" />
                        <p className="mb-0 reply">{document.name}</p>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="col-6 missing-documents-observations">
            {logbook?.documents?.observations && (
              <>
                <p className="info-title">Observaciones</p>
                <p className="reply">{logbook?.documents?.observations}</p>
              </>
            )}
          </div>
        </div>
      </div>
      {!logbook.jobReferences.length && (
        <div className="review-section mt-3 mb-4">
          <div className="section-header d-flex">
            <div className="section-name">Referencias Laborales</div>
            <hr className="w-100 ms-3" />
          </div>
          <p>-</p>
        </div>
      )}
      {logbook.jobReferences?.map((jobReference, jobReferenceIndex) => (
        <div className="review-section mt-3" key={`jobReference.${jobReferenceIndex}`}>
          <div className="section-header d-flex">
            <div className="section-name">Referencia Laboral {jobReferenceIndex + 1}</div>
            <hr className="w-100 ms-3" />
          </div>
          {!jobReference.infoProvided && (
            <p className="reply">Candidato aún no proporciona información de contacto.</p>
          )}
          {jobReference.infoProvided && !jobReference.reached && <p>Aún no se hace contacto.</p>}
          {jobReference.infoProvided &&
            jobReference.reached &&
            jobReference.attempts.map((attempt, attemptIndex) => (
              <div
                className="section-content mt-1"
                key={`jobReference.${jobReferenceIndex}.attempt.${attemptIndex}`}
              >
                <div className="section-row-title d-flex">
                  {attempt.replied ? (
                    <span className="ellipse figure--green" />
                  ) : (
                    <span className="ellipse figure--red" />
                  )}
                  <div className="section-row-name">Intento {attemptIndex + 1}</div>
                </div>
                <div className="section-row-content mt-2 row">
                  <div className="col-6">
                    <p className="reply">El candidato ya subio la información a la plataforma.</p>
                    {attempt.contactDate && (
                      <>
                        <p className="info-title">
                          {attempt.replied ? 'Se obtuvo respuesta el:' : 'Lo intentamos el:'}
                        </p>
                        <p className="date reply">
                          {dayjs(attempt.contactDate).format(dateFormat)} hrs
                        </p>
                      </>
                    )}
                    <p className="info-title">Observaciones</p>
                    <p className="reply">{attempt.observations || '-'}</p>
                  </div>
                  <div className="col-6">
                    <p className="reply">
                      {attempt.replied
                        ? 'El equipo de analistas obtuvo respuesta de la referencia.'
                        : 'El equipo de analistas no ha obtenido respuesta de la referencia.'}
                    </p>
                    <p className="info-title">El intento se hizo a tráves de:</p>
                    <p className="reply">{attempt.source && sourceOptions[attempt.source]}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
      <div className="review-section mt-3 mb-4">
        <div className="section-header d-flex">
          <div className="section-name">Notas del Especialista</div>
        </div>
        <hr className="w-100" />
        {!logbook.generalObservations.length && <p>-</p>}
        {logbook.generalObservations?.map((observation, observationIndex) => (
          <ReviewGeneralObservation
            key={`generalObservation.${observationIndex}`}
            id={observation.id}
            message={observation.message}
            creationDate={
              observation.creationDate && dayjs(observation.creationDate).format(dateFormat)
            }
            acceptedDate={
              observation.acceptedDate && dayjs(observation.acceptedDate).format(dateFormat)
            }
            mutate={mutate}
            index={observationIndex}
            assessmentId={logbook.id}
            isLast={observationIndex === logbook.generalObservations.length - 1}
          />
        ))}
      </div>
    </div>
  );
}

LogbookReview.propTypes = {
  logbook: PropTypes.object,
  mutate: PropTypes.func,
};

export default LogbookReview;
