import PropTypes from 'prop-types';
import './GeneralObservations.scss';
import {Controller, useForm} from 'react-hook-form';
import LoadingQuestionnaireModule from '../LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import Saving from '../Saving/Saving';
import useSummary from '@/hooks/useSummary';
import studiesConst from '@/constants/studiesConst';
import logoIcon from '/Logo_Icon_EDA.webp';
import classNames from '@/helpers/classNames';
import useSaveSummary from '@/hooks/useSaveSummary';
import useSaveGeneralResult from '@/hooks/useSaveGeneralResult';
import AssessmentStatusSelect from '../AssessmentStatusSelect/AssessmentStatusSelect';
import {useNavigate} from 'react-router-dom';
import routesConst from '@/constants/routesConst';

function GeneralObservations({assessmentId}) {
  const navigate = useNavigate();
  const {summary, isLoading, mutate} = useSummary({logbookId: assessmentId});
  const {trigger, isMutating, isError} = useSaveSummary(assessmentId);
  const {
    trigger: triggerGeneratePdf,
    isMutating: isGeneratePdfMutating,
    isError: isGeneratePdfError,
  } = useSaveGeneralResult({assessmentId});
  const {register, handleSubmit, control, watch} = useForm({
    values: {
      goal: summary?.goal,
      assessmentStatus: summary?.assessmentStatus,
      generalDescription: summary?.generalDescription,
    },
  });

  const assessmentStatus = watch('assessmentStatus');

  const getStatusColor = (status) => {
    switch (status) {
      case studiesConst.moduleStatus.completed:
      case studiesConst.moduleStatus.inReview:
        return 'blue';
      case studiesConst.moduleStatus.accepted:
        return 'green';
      case studiesConst.moduleStatus.rejected:
        return 'red';
      case studiesConst.moduleStatus.partiallyAccepted:
        return 'yellow';
      case studiesConst.moduleStatus.invalid:
        return 'strong-gray';
      default:
        return 'white';
    }
  };

  const getStatusMessage = () => {
    switch (assessmentStatus) {
      case studiesConst.moduleStatus.accepted:
        return 'Apto para la posición a contratar';
      case studiesConst.moduleStatus.rejected:
        return 'Rechazado para la posición a contratar';
      case studiesConst.moduleStatus.partiallyAccepted:
        return 'Parcialmente apto para la posición a contratar';
      case studiesConst.moduleStatus.invalid:
        return 'Inválido';
      default:
        return '';
    }
  };

  const getModuleStatusByName = (moduleName) =>
    summary.modules.find((summaryModule) => summaryModule.name === moduleName)?.moduleStatus || 6;

  const handleGeneratePdf = () => {
    triggerGeneratePdf(
      JSON.stringify({
        assessmentStatus: summary.assessmentStatus,
        generalObservations: summary.generalDescription,
      })
    ).then(() =>
      mutate().then(
        (data) =>
          data?.reportAvailable && navigate(`${routesConst.analystHome}?search=${assessmentId}`)
      )
    );
  };

  const onSubmit = (data) => {
    trigger(
      JSON.stringify({
        goal: data?.goal || '',
        assessmentStatus: data?.assessmentStatus || '',
        generalComments:
          typeof data?.generalDescription === 'undefined' ? 0 : data.generalDescription,
      })
    );
  };

  if (isLoading || !summary) {
    return (
      <div className="container general-observations dashboard">
        <LoadingQuestionnaireModule className="mt-3" />
      </div>
    );
  }

  return (
    <div className="container general-observations dashboard">
      <div className="name mb-5">Observaciones Generales</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 outlined-background mb-4">
            <div className="candidate-glance d-flex justify-content-between">
              <div className="glance-general">
                <div className="glance-name">{summary.applicantName}</div>
                <div className="glance-modules candidate-legend-field mt-2 mb-4 d-flex">
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--white" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.noStatus ||
                        !module.moduleStatus
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--gray" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.inProgress
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--strong-gray" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.invalid
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--blue" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.completed ||
                        module.moduleStatus === studiesConst.moduleStatus.inReview
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--green" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.accepted
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--yellow" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                  <div className="me-4 d-flex align-items-center">
                    <span className="ellipse figure--red" />
                    {summary.modules.reduce(
                      (total, module) =>
                        module.moduleStatus === studiesConst.moduleStatus.rejected
                          ? total + 1
                          : total,
                      0
                    )}
                  </div>
                </div>
              </div>
              <div className="glance-picture">
                {summary.personalPictureFile && (
                  <img src={summary.personalPictureFile} className="personal-picture" />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Objetivo</h5>
            <div className="row goal">
              <div className="col-12">
                <input type="text" className="form-control" {...register('goal')} />
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Módulos con Alerta de Riesgo</h5>
            <div className="reply">{summary.sectionsDescription}</div>
            <div className="row high-risk-modules">
              <div className="col-8">
                <div className="row">
                  {summary.modules
                    .filter((summaryModule) => summaryModule.risksDetected)
                    .map((summaryModule, summaryModuleIndex) => (
                      <div
                        className="col-4 d-flex align-items-center py-1"
                        key={`summarySection-${summaryModuleIndex}`}
                      >
                        <span
                          {...classNames(
                            'ellipse mb-1',
                            `figure--${getStatusColor(summaryModule.moduleStatus)}`
                          )}
                        />
                        {summaryModule.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Información de los módulos con alertas de riesgo</h5>
            <div className="reply">{summary.modulesDescription}</div>
            <div className="row high-risk-modules mt-3">
              {summary.modules
                .filter((summaryModule) => summaryModule.risksDetected)
                .map((summaryModule, summaryModuleIndex) => (
                  <div
                    className="col-12 d-flex align-items-center py-1"
                    key={`summaryModule-${summaryModuleIndex}`}
                  >
                    <span
                      {...classNames(
                        'ellipse mb-1',
                        `figure--${getStatusColor(summaryModule.moduleStatus)}`
                      )}
                    />
                    <div className="fw-semibold me-2">
                      {summaryModule.name}
                      {Boolean(summaryModule.moduleObservations) && ':'}
                    </div>
                    {summaryModule.moduleObservations}
                  </div>
                ))}
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Documentación Recibida</h5>
            <div className="reply">{summary.documents.providedDocumentsDescription}</div>
            <div className="row high-risk-modules mt-3">
              {summary.documents.provided
                .filter((documentProvided) => documentProvided.risksDetected)
                .map((documentProvided, documentProvidedIndex) => (
                  <div
                    className="col-12 d-flex align-items-center py-1"
                    key={`documentProvided-${documentProvidedIndex}`}
                  >
                    <span
                      {...classNames(
                        'ellipse mb-1',
                        `figure--${getStatusColor(
                          getModuleStatusByName(documentProvided.moduleName)
                        )}`
                      )}
                    />
                    <div className="fw-semibold me-2">
                      {documentProvided.name}
                      {Boolean(documentProvided.analystObservations) && ':'}
                    </div>
                    {documentProvided.analystObservations}
                  </div>
                ))}
            </div>
            <h5 className="fw-semibold mb-3 mt-4">Documentación Faltante</h5>
            <div className="reply">{summary.documents.missingDocumentsDescription}</div>
            <div className="row high-risk-modules mt-3">
              {summary.documents.missing.map((documentMissing, documentMissingKey) => (
                <div
                  className="col-4 d-flex align-items-center py-1"
                  key={`documentMissing-${documentMissingKey}`}
                >
                  <span className="ellipse figure--red mb-1" />
                  <div className="missingDocument-name">{documentMissing.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Referencias Laborales</h5>
            <div className="reply">{summary.jobReferences.description}</div>
            <div className="row high-risk-modules">
              {summary.jobReferences.items.map((jobReference, jobReferenceIndex) => (
                <div className="col-12 py-1" key={`jobReference-${jobReferenceIndex}`}>
                  <div className="d-flex align-items-center py-3">
                    <span
                      {...classNames(
                        'ellipse mb-1',
                        `figure--${getStatusColor(jobReference.status)}`
                      )}
                    />
                    <div className="fw-semibold me-2">
                      Referencia Laboral {jobReferenceIndex + 1}{' '}
                      {Boolean(jobReference.companyName) && '-'} {jobReference.companyName}
                    </div>
                  </div>
                  <div className="reply"
                    dangerouslySetInnerHTML={{__html: (jobReference.outputText || "").replace(/\n/g, '<br>')}} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Visita Remota</h5>
            <div className="row remote-visit">
              <div className="col-12">
                <div className="reply">{summary.remoteVisit.description || '-'}</div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Observación de Visita Remota</h5>
            <div className="row remote-visit">
              <div className="col-12">
                <div className="reply">{summary.remoteVisit.generalObservations || '-'}</div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Geolocalización</h5>
            <div className="row geolocation">
              <div className="col-12">
                <div className="reply">{summary.remoteVisit.geolocationObservations || '-'}</div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Preguntas de Seguridad</h5>
            <div className="row security-questions">
              <div className="col-12">
                <div className="reply">
                  {summary.remoteVisit.securityQuestionsObservations || '-'}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Verificación de preguntas aleatorias</h5>
            <div className="reply">
              {summary.remoteVisit.securityVerificationObservations || '-'}
            </div>
            <div className="row security-verification mt-3">
              <div className="col-6">
                <div className="row">
                  {summary.modules
                    .filter((summaryModule) => summaryModule.securityVerificationSelected)
                    .map((summaryModule, summaryModuleIndex) => (
                      <div
                        className="col-6 d-flex align-items-center py-1"
                        key={`securityVerificationModule-${summaryModuleIndex}`}
                      >
                        <img src={logoIcon} alt="logo icon" width="15" height="13" />
                        {summaryModule.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Observaciones Generales</h5>
            <div className="row general-observations-row">
              <div className="col-12">
                <textarea className="form-control" rows="2" {...register('generalDescription')} />
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h6 className="mb-3">En función a lo anterior, al candidato se le considera:</h6>
            <div className="d-flex align-items-center">
              <div>
                <Controller
                  control={control}
                  name="assessmentStatus"
                  render={({field}) => (
                    <AssessmentStatusSelect value={field.value} onChange={field.onChange} />
                  )}
                />
              </div>
              <div className="w-100 ms-4 pb-2">
                <div className="reply">{getStatusMessage()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="submit-button d-flex justify-content-center mt-3 save-controls">
              <button
                className="btn btn-warning save me-5"
                type="submit"
                disabled={summary?.reportAvailable || isGeneratePdfMutating || isMutating}
              >
                Guardar Avance
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={handleGeneratePdf}
                disabled={
                  !summary?.assessmentStatus || summary?.reportAvailable || isGeneratePdfMutating
                }
              >
                Generar PDF
              </button>
            </div>
          </div>
        </div>
      </form>
      <Saving
        isMutating={isMutating}
        isSaveError={isError}
        savingMessage="Guardando observaciones generales, por favor no cierre el navegador o la pestaña..."
        errorMessage="Error al guardar las observaciones generales, verifica los campos e intenta de nuevo en unos segundos..."
        successMessage="¡Observaciones generales guardadas con éxito!"
      />
      <Saving
        isMutating={isGeneratePdfMutating}
        isSaveError={isGeneratePdfError}
        savingMessage="Generando PDF, por favor no cierre el navegador o la pestaña..."
        errorMessage="Error al generar el PDF, verifica los campos e intenta de nuevo en unos segundos..."
        successMessage="¡PDF generado con éxito!"
      />
    </div>
  );
}

GeneralObservations.propTypes = {
  assessmentId: PropTypes.string,
};

export default GeneralObservations;
