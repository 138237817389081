import {Outlet, ScrollRestoration} from 'react-router-dom';
import CandidateHeader from '../components/CandidateHeader/CandidateHeader';
import useUser from '../hooks/useUser';
import {useState} from 'react';
import Footer from '../components/Footer/Footer';
import rolesConst from '../constants/rolesConst';
import useRoleNavigation from '../hooks/useRoleNavigation';

export default function CandidateRoot() {
  const {isUnauthorized, renderUnauthorized} = useRoleNavigation(rolesConst.applicant);
  const {user, isLoading, isError} = useUser();
  const [hasModuleSaved, setHasModuleSaved] = useState(false);

  if (isUnauthorized) {
    return renderUnauthorized;
  }

  return (
    <>
      <CandidateHeader />
      {!isLoading && !isError && user ? (
        <Outlet context={{hasModuleSaved, setHasModuleSaved, user}} />
      ) : (
        <div style={{minHeight: '90vh'}}></div>
      )}
      <Footer />
      <ScrollRestoration />
    </>
  );
}
