const reportType = {
  executive: 1,
  detailed: 2,
};

const status = {
  noStatus: 0,
  inProgress: 1,
  completed: 2,
  inReview: 3,
  partiallyAccepted: 4,
  accepted: 5,
  rejected: 6,
  invalid: 8,
};

export default {
  assessmentStatus: {
    ...status,
  },
  reportType,
  reportName: {
    [reportType.executive]: 'ejecutivo',
    [reportType.detailed]: 'detallado',
  },
  moduleStatus: {
    ...status,
  },
  minCompletedStatus: 4,
  columns: {
    col2: 'id',
    col3: 'firstName',
    col4: 'companyName',
  },
};
