import {Outlet, useNavigation} from 'react-router-dom';

export default function Root() {
  const navigation = useNavigation();

  return (
    <>
      <Outlet />
      {navigation.state === 'loading' && navigation.location.pathname === '/signout' && (
        <div className="modal-backdrop show" />
      )}
    </>
  );
}
