import studiesConst from '@/constants/studiesConst';
import PropTypes from 'prop-types';

export default function ApplicantFieldMobile({name, modules, children}) {
  return (
    <div className="flex candidate-field mb-1">
      <div className="fw-semibold candidate-name-field">{name}</div>
      <div className="d-flex mt-2 candidate-legend-field">
        <div className="me-3 d-flex align-items-center">
          <span className="ellipse figure--white" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.noStatus || !module.moduleStatus
                ? total + 1
                : total,
            0
          )}
        </div>
        <div className="me-3 d-flex align-items-center">
          <span className="ellipse figure--gray" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.inProgress ? total + 1 : total,
            0
          )}
        </div>
        <div className="me-3 d-flex align-items-center">
          <span className="ellipse figure--blue" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.completed ||
              module.moduleStatus === studiesConst.moduleStatus.inReview
                ? total + 1
                : total,
            0
          )}
        </div>
        <div className="d-flex align-items-center">
          <span className="ellipse figure--mint" />
          {modules.reduce(
            (total, module) =>
              module.moduleStatus === studiesConst.moduleStatus.accepted ||
              module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted ||
              module.moduleStatus === studiesConst.moduleStatus.rejected
                ? total + 1
                : total,
            0
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

ApplicantFieldMobile.propTypes = {
  name: PropTypes.string,
  modules: PropTypes.array,
  children: PropTypes.object,
};
