import {useForm} from 'react-hook-form';
import './CandidateForm.scss';
import classNames from '@/helpers/classNames';
import useCreateCandidate from '@/hooks/useCreateCandidate';
import useCandidate from '@/hooks/useCandidate';
import useUpdateCandidate from '@/hooks/useUpdateCandidate';
import dayjs from 'dayjs';
import LoadingQuestionnaireModule from '../LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import useItems from '@/hooks/useItems';
import {Toast, ToastContainer} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import routesConst from '@/constants/routesConst';
import PropTypes from 'prop-types';

export default function CandidateForm({candidateId}) {
  const navigate = useNavigate();
  const {candidate, isLoading: isLoadingCandidate} = useCandidate({candidateId});
  const {items: questionnaires, isLoading: isLoadingQuestionnaires} = useItems({
    identifiedBy: 'applicantQuestionnaires',
  });
  const {
    trigger: triggerCreate,
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    reset: resetCreateCandidate,
  } = useCreateCandidate();
  const {
    trigger: triggerUpdate,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    reset: resetUpdateCandidate,
  } = useUpdateCandidate(candidateId);
  const [isToastShowing, setIsToastShowing] = useState(false);
  const isSaveSuccess = isCreateSuccess || isUpdateSuccess;
  const isSaveError = isCreateError || isUpdateError;
  const isSaveLoading = !isSaveSuccess && !isSaveError;

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      country: 'MX',
      birthdate: null,
    },
    values: candidate,
  });

  const onSubmit = (data) => {
    const formattedData = JSON.stringify({
      ...data,
      birthdate: data.birthdate ? dayjs(data.birthdate).format('YYYY-MM-DD') : '',
    });
    setIsToastShowing(true);
    candidate ? triggerUpdate(formattedData) : triggerCreate(formattedData);
  };

  const handleAfterSave = () => {
    setIsToastShowing(false);
  };

  const onToastExit = () => {
    resetCreateCandidate();
    resetUpdateCandidate();
    isCreateSuccess && navigate(routesConst.adminHome);
  };

  useEffect(() => {
    if (isToastShowing && !isSaveLoading) {
      setTimeout(() => {
        handleAfterSave();
      }, 1500);
    }
  }, [isToastShowing, isSaveLoading]);

  if (candidateId && isLoadingCandidate) {
    return <LoadingQuestionnaireModule />;
  }

  if (candidateId && !isLoadingCandidate && !candidate) {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-center h3">
          No se encontró el candidato...
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="candidate-form">
      <div className="row candidate-form-grid">
        <div className="col-md-4 g-3">
          <label htmlFor="questionnaireId" className="form-label fw-semibold">
            Cuestionario <span className="text-danger">*</span>
          </label>
          <select
            id="questionnaireId"
            {...classNames(
              'form-control mx-input form-select',
              errors.questionnaireId && 'is-invalid'
            )}
            {...register('questionnaireId', {required: 'Por favor ingrese el cuestionario.'})}
          >
            {!isLoadingQuestionnaires &&
              questionnaires.map((questionnaire) => (
                <option value={questionnaire.id || ''} key={questionnaire.id || 'notAnOption'}>
                  {questionnaire.value}
                </option>
              ))}
          </select>
          {errors.questionnaireId && (
            <div className="invalid-feedback">{errors.questionnaireId.message}</div>
          )}
        </div>
        <div className="col-md-8" />
        <div className="col-md-5 g-3">
          <label htmlFor="phoneNumber" className="form-label fw-semibold">
            Teléfono
          </label>
          <input
            type="text"
            className="form-control mx-input"
            id="phoneNumber"
            {...register('phoneNumber')}
          />
        </div>
        <div className="col-md-7" />
        <div className="col-md-4 g-3">
          <label htmlFor="firstName" className="form-label fw-semibold">
            Primer Nombre <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            {...classNames('form-control mx-input', errors.firstName && 'is-invalid')}
            {...register('firstName', {required: 'Por favor ingrese el primer nombre.'})}
          />
          {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
        </div>
        <div className="col-md-4 g-3">
          <label htmlFor="surname" className="form-label fw-semibold">
            Primer Apellido <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="surname"
            {...classNames('form-control mx-input', errors.surname && 'is-invalid')}
            {...register('surname', {required: 'Por favor ingrese el primer apellido.'})}
          />
          {errors.surname && <div className="invalid-feedback">{errors.surname.message}</div>}
        </div>
        <div className="col-md-4 g-3">
          <label htmlFor="secondSurname" className="form-label fw-semibold">
            Segundo Apellido
          </label>
          <input
            type="text"
            className="form-control mx-input"
            id="secondSurname"
            {...register('secondSurname')}
          />
        </div>
        <div className="col-md-4 g-3">
          <label htmlFor="jobPosition" className="form-label fw-semibold">
            Puesto al que Aplica <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control mx-input"
            id="jobPosition"
            {...classNames('form-control mx-input', errors.jobPosition && 'is-invalid')}
            {...register('jobPosition', {required: 'Por favor ingrese el puesto al que aplica.'})}
          />
          {errors.jobPosition && (
            <div className="invalid-feedback">{errors.jobPosition.message}</div>
          )}
        </div>
        <div className="col-md-4 g-3">
          <label htmlFor="email" className="form-label fw-semibold">
            Correo Personal <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="email"
            {...classNames('form-control mx-input', errors.email && 'is-invalid')}
            {...register('email', {required: 'Por favor ingrese el correo personal.'})}
          />
          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
        </div>
        <div className="col-md-4 g-3">
          <label htmlFor="password" className="form-label fw-semibold">
            Contraseña {!candidate && <span className="text-danger">*</span>}
          </label>
          <input
            type="text"
            id="password"
            {...classNames('form-control mx-input', errors.password && 'is-invalid')}
            {...register('password', {
              required: candidate ? false : 'Por favor ingrese la contraseña.',
            })}
          />
          {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
        </div>
        <div className="col-12 g-3 d-flex justify-content-center">
          <button className="btn btn-warning save-candidate-button" type="submit">
            GUARDAR
          </button>
        </div>
      </div>
      <ToastContainer className="m-4" position="top-center">
        <Toast
          className={`text-bg-${(isSaveError && 'danger') || ''}${
            (isSaveSuccess && 'success') || ''
          }${(isSaveLoading && 'warning') || ''}`}
          show={isToastShowing}
          onExit={onToastExit}
        >
          <Toast.Body {...classNames(isSaveSuccess && 'd-flex')}>
            {isSaveLoading && (
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {isSaveError &&
              'Error al guardar, verifica los campos e intenta de nuevo en unos segundos...'}
            {isSaveLoading && 'Guardando, por favor no cierre el navegador o la pestaña...'}
            {isSaveSuccess && 'Candidato guardado con éxito.'}
            {isSaveSuccess && (
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                onClick={handleAfterSave}
                aria-label="Close"
              ></button>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {isToastShowing && !isCreateError && !isUpdateError && (
        <div className="modal-backdrop show"></div>
      )}
    </form>
  );
}

CandidateForm.propTypes = {
  candidateId: PropTypes.number,
};
