import useSWR from 'swr';
import {useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '../helpers/fetcherWithToken';
import {getUserSession} from '@/helpers/userHelper';
import {useState} from 'react';

const API_ROUTE = 'https://admin.ethicssocioeconomics.com/api/1.0/user';

function useUser(shouldFetch = true) {
  const {userSession} = useRouteLoaderData('root') || {};
  const [session, setSession] = useState(() => userSession);
  const {data, error, isLoading, mutate} = useSWR(
    () =>
      shouldFetch && session?.accessToken?.jwtToken
        ? [API_ROUTE, session.accessToken.jwtToken]
        : null,
    ([url, token]) => fetcherWithToken(url, token),
    {
      shouldRetryOnError: true,
      onErrorRetry: async (error, key, config, revalidate, {retryCount}) => {
        // Only retry up to 3 times.
        if (retryCount >= 3) return;

        const newSession = await getUserSession();
        setSession(newSession);
        revalidate();
      },
    }
  );

  return {
    user: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export default useUser;
