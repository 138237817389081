import {NavLink, useLocation} from 'react-router-dom';
import Header from '../Header/Header';
import routesConst from '../../constants/routesConst';
import {navStatus} from '../../helpers/headerHelper';
import classNames from '../../helpers/classNames';

export default function AdminHeader() {
  const location = useLocation();
  const home = routesConst.adminHome;
  const isNavigatingHome = location.pathname === home;

  return (
    <Header roleHome={home}>
      {!isNavigatingHome && (
        <NavLink id="back-to-candidates-btn" to={home} className={navStatus} end>
          Candidatos
        </NavLink>
      )}
      <NavLink
        to={routesConst.signOut}
        className={(navStatusProps) =>
          classNames('logout-button', navStatus(navStatusProps)).className
        }
      >
        <div className="logout-button-container">
          <div className="logout-icon d-inline-block">
            <i className="ethi ethi-cerrar-sesion d-inline-block"></i>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            Cerrar Sesión
          </div>
        </div>
      </NavLink>
    </Header>
  );
}
